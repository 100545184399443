#footer {
  background: url('../hammerite/img/bg/bg-footer.jpg') 0 0 no-repeat;
  padding-top: 100px;
  clear: both;
  .footer {
    background: url('../hammerite/img/bg/noise-lightblue.jpg');
    padding: 0;
    margin: 0;
    #stores {
      padding-bottom: 20px;
      background: url('../hammerite/img/bg/footer-indent.png') 0 100% repeat-x;
      .widget-title {
        color: #003e67;
        font-family:"facitweb",sans-serif;
        font-size: 1.6em;
        display: block;
        padding-bottom: 4px;
        text-align: center;
        .lt-ie9 & {
          font-family: $font-family !important;
          font-weight: bold;
        }
      }
      ul {
        height: 110px;
        overflow: hidden;
        padding: 0 0 0 10px;
        width: auto;
        margin: 0 auto;
        li {
          float: left;
          margin-right: 10px;
          width: 130px;
          height: 100px;
          background: #fff;
          box-shadow: 0 2px 2px #c4d5d9;
          text-align: center;
          line-height: 100px;
          img {
            max-width: 110px;
            max-height: 80px;
            vertical-align: middle;
          }
        }
      }
    }
    div.col {
      width: 310px;
      padding-left: 10px;
      float: left;
      padding: 25px 0;
      .widget-title {
        color: #003e67;
        font-family:"facitweb",sans-serif;
        font-size: 1.6em;
        margin: 0 0 15px;
        padding: 0;
        .lt-ie9 & {
          font-family: $font-family !important;
          font-weight: bold;
        }
      }
      ul li a {
        color: #373a3b;
        text-decoration: none;
        padding: 6px 0 6px 14px;
        display: block;
        background: url('../hammerite/img/globals/sprite.png') -933px -410px no-repeat;
        &:hover,
        &:visited:hover {
          color: #0375b0;
          text-decoration: underline;
          background-position: -975px -351px;
        }
      }
      form {
        input {
          width: 240px;
          border-radius: 3px;
          padding: 8px 10px;
          background: #fff;
          border: 1px solid #c0cccf;
          color: #333;
          font-family: $font-family;
          font-size: 1em;
          margin-bottom: 10px;
        }
        .btn {
          width: auto;
          padding: 0 20px 0 10px;
          background: url('../hammerite/img/globals/sprite.png') 100% -290px no-repeat;
          font-family: $font-family;
          font-size: 1em;
          font-weight: bold;
          color: #fff;
          height: 30px;
          border-radius: 3px;
          border: 1px solid #048fbd;
          &:hover {
            background-poisition: right -318px;
            box-shadow: 0 2px 2px #c4d5d9;
            cursor: pointer;
          }
        }
      }
    }
  }
  .vcard {
    color: #373a3b;
    line-height: 1.4em;
    padding: 20px 0 0;
  }

}

#claim {
    background: #fff;
    border-top: 1px solid #dce0e1;
	padding: 20px 0 40px 0;
	overflow: hidden;

	.nav {
		float: left;

		li {
			display: inline-block;
			margin: 0 10px;
		}
	}
	.logo {
		float: right;
	}
}

.social {
  display: flex;

  &__item {
    margin-right: 10px;

    &__link  {
      background: none !important;
      padding: 0 !important;
    }

    i {
      background: #373a3b;
      color: white;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
    }
  }
}

.cookie-menu {
  ul {
    display: flex;
  }

  &__item {
    margin: 0 10px;
  }
}

*:first-child+html .newsletter input { padding-top: 8px; height: 26px }
