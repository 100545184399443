#prodfinder {
  width: 260px;
  padding: 20px;
  float: right;
  margin-right: 10px;
  height: 208px;
  background: #ece8de;
  position: relative;
  h2 {
    color: #373a3b;
    text-shadow: 0 1px 0 #fff;
    margin-bottom: 4px;
  }
  p {
    color: #373a3b;
    text-shadow: 0 1px 0 #fff;
    margin-bottom: 4px;
    padding-bottom: 6px;
  }
  select {
    width: 230px;
    margin-bottom: 8px;
  }
  input {
    display: block;
    margin: 10px 0 0 0;
    border: 1px solid #02395e;
    background: url('../hammerite/img/globals/sprite.png') -490px -230px no-repeat;
    height: 30px;
    padding: 0 16px;
    font-size: 1em;
    color: #c1dbe3;
    float: left;
    clear: both;
    font-family: $font-family;
    border-radius: 5px;
    &:hover {
      background: url('../hammerite/img/globals/sprite.png') -490px -260px no-repeat;
      cursor: pointer;
      color: #fff;
    }
  }
  a#tooltip {
    display: block;
    height: 18px;
    width: 18px;
    background: url('../hammerite/img/globals/sprite.png') -71px -43px no-repeat;
    text-indent: -9999em;
    font-size: 0em;
    float: right;
  }
}