#articlewrapper {
  width: 960px;
  clear: both;
  padding: 0 10px 10px;
  border-top: 1px solid #e5ebf0;
  border-bottom: 1px solid #e5ebf0;
  article {
    width: 300px;
    float: left;
    margin: 20px 30px 0 0;
    position: relative;
  }
}

#singleprod article {
  width: 300px;
  float: left;
  margin: 20px 30px 0 0;
  position: relative;
}

#articlewrapper article.nomargin,
#singleprod article.nomargin {
  margin-right: 0;
}

#articlewrapper article h2,
#singleprod article h2 {
  font-size: 1.8em;
  margin-bottom: 0;

  a {
    text-decoration: none;
    color: #003e67;
  }
}

#articlewrapper article em,
#singleprod article em {
  font-size: 1.1em;
  font-weight: normal;
  font-style: italic;
  display: block;
  color: #8c8983;
}

#articlewrapper article figure,
#singleprod article figure {
  width: 300px;
  height: 169px;
  margin: 0;
  z-index: 8;
  position: relative;
  display: block;
  border: 0;

  img {
    display: block;
    width: 100%;
  }
}

#articlewrapper article div.title,
#singleprod article div.title {
  background: #fff;
  padding: 0 0 15px 0;
  width: 300px;
}

#articlewrapper article div,
#singleprod article div {
  background: url('../hammerite/img/bg/noise-blue.png');
  width: 260px;
  padding: 20px;
}

#articlewrapper article div.text {
	padding: 0;
}

#articlewrapper article div a,
#singleprod article div a {
  display: block;
  clear: both;
}

#articlewrapper article div p,
#singleprod article div p {
  padding-bottom: 10px;
}

#articlewrapper article div .bull,
#singleprod article div .bull {
  margin-top: 6px;
}

#articlewrapper article ul,
#singleprod article ul {
  position: absolute;
  top: 120px;
  left: 10px;
  overflow: hidden;
  display: block;
  height: 66px;
  width: 280px;
  z-index: 9;
}

#articlewrapper article ul li {
  float: left;
  display: block;
  width: 66px;
  height: 66px;
  margin-right: -20px;
  position: relative;
}

#singleprod {
  article {
    ul li {
      float: left;
      display: block;
      width: 66px;
      height: 66px;
      margin-right: -20px;
      position: relative;
    }
    margin: 18px 0 0;
    figure {
      height: 120px;
      z-index: 0;
    }
    ul { top: 70px; }
    div.title {
      h2 { float: left; }
      em {
        float: right;
        display: block;
        padding: 7px 6px 0 0;
      }
    }
  }
  width: 300px;
  float: left;
  margin-bottom: 20px;
}

#vidwrapper {
  width: 960px;
  clear: both;
  padding: 20px 10px 10px;
  border-top: 1px solid #e5ebf0;
  border-bottom: 1px solid #e5ebf0;
  overflow: hidden;
  height: 278px;
  position: relative;
  #vidcarousel {
    width: 3000px;
    height: 233px;
    position: absolute;
    top: 20;
    left: 10;
    div {
      width: 300px;
      float: left;
      margin: 0 30px 0 0;
      height: 233px;
    }
  }
  div.nomargin { margin-right: 0; }
  ul {
    display: block;
    height: 35px;
    width: 80px;
    margin-left: 442px;
    margin-top: 243px;
    li {
      float: left;
      margin-right: 5px;
      width: 35px;
      height: 35px;
      a {
        display: block;
        width: 35px;
        height: 35px;
        text-indent: -9999em;
        font-size: 0em;
        background: url('../hammerite/img/globals/sprite.png') -540px -611px no-repeat;
        &:hover,
        &:visited:hover {
          background-position: -500px -611px;
        }
      }
    }
    .next a {
      background: url('../hammerite/img/globals/sprite.png') -660px -611px no-repeat;
      &:hover,
      &:visited:hover {
        background-position: -700px -611px;
      }
    }
    .inactive {
      a {
        background: url('../hammerite/img/globals/sprite.png') -580px -611px no-repeat;
        cursor: default;
        &:hover,
        &:visited:hover {
          background-position: -580px -611px;
          cursor: default;
        }
      }
      &.next a {
        background: url('../hammerite/img/globals/sprite.png') -620px -611px no-repeat;
        cursor: default;
        &:hover,
        &:visited:hover {
          background-position: -620px -611px;
          cursor: default;
        }
      }
    }
  }
}

#columns {
  width: 960px;
  padding: 30px 10px;
  div {
    width: 280px;
    background: url('../hammerite/img/bg/sep-dotted-y.png') 100% 0 repeat-y;
    float: left;
    padding: 0 15px 0 20px;

    div {
      background: none;
    }

    &.middle {
      width: 290px;
      padding: 0 20px;
    }
    &.last {
      background: none;
      padding: 0 10px 0 35px;
      width: 270px;
    }
    &.middle {
      h2 { margin-left: 10px; }
      span {
        display: block;
        padding: 4px 0 10px 20px;
        font-style: italic;
        color: #898f92;
      }
      a.bull { margin-left: 20px; }
    }
  }
}

ul.usplist li {
  display: block;
  background: url('../hammerite/img/globals/sprite.png') -954px -375px no-repeat;
  padding: 4px 0 8px 22px;
  color: #373a3b;
}

p.testimonial {
  width: 250px;
  padding: 15px 20px 20px;
  background: #e8f0f4 url('../hammerite/img/bg/bg-testimonial.png') 0 100% no-repeat;
}

#carousel.gewinnspiel {
  margin: 0;
  height: auto;
  width: 670px;
  max-width: 100%;

  img {
    width: 100%;
  }

  & + #slider {
      display: none;
  }
}