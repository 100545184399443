.rating {
  display: block;
  width: 88px;
  height: 15px;
  float: left;
  margin-right: 10px;
  text-indent: -9999em;
  font-size: 0em;
  background: url('../hammerite/img/globals/sprite.png') no-repeat;
  &.stars5 { background-position: -250px -230px; }
  &.stars4-5 { background-position: -250px -250px; }
  &.stars4 { background-position: -250px -270px; }
  &.stars3-5 { background-position: -250px -290px; }
  &.stars3 { background-position: -250px -310px; }
  &.stars2-5 { background-position: -250px -330px; }
  &.stars2 { background-position: -250px -350px; }
  &.stars1-5 { background-position: -250px -370px; }
  &.stars1 { background-position: -250px -390px; }
  &.stars0-5 { background-position: -250px -410px; }
  &.stars0 { background-position: -250px -430px; }
}

.ratinginfo {
  color: #929ca1 !important;
  display: block;
  float: left;
  padding-top: 2px;
}