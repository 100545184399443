#slider {
  clear: both;
  width: 960px;
  height: 340px;
  overflow: hidden;
  position: relative;
  #sliderwrapper {
    width: 4800px;
    height: 340px;
    position: absolute;
    left: 0;
    top: 0;
  }
  #controls {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 100;
    li {
      float: left;
      margin-right: 6px;
      a {
        display: block;
        float: left;
        width: 14px;
        height: 14px;
        background: url('../hammerite/img/globals/sprite.png') -119px -45px no-repeat;
        font-size: 0em;
        text-indent: -9999em;
        &:hover,
        &:visited:hover {
          background-position: -153px -45px;
        }
      }
    }
    .current a {
      background: url('../hammerite/img/globals/sprite.png') -99px -45px no-repeat;
      &:hover,
      &:visited:hover {
        background-position: -99px -45px;
      }
    }
    .pause a {
      background: url('../hammerite/img/globals/sprite.png') -137px -45px no-repeat;
      &:hover,
      &:visited:hover {
        background-position: -137px -45px;
      }
    }
    .play a {
      background: url('../hammerite/img/globals/sprite.png') -171px -45px no-repeat;
      &:hover,
      &:visited:hover {
        background-position: -171px -45px;
      }
    }
  }
  #prev {
    display: block;
    height: 80px;
    width: 80px;
    text-indent: -9999em;
    font-size: 0em;
    background: url('../hammerite/img/globals/sprite.png') 0 -230px no-repeat;
    position: absolute;
    top: 130px;
    left: 0;
    z-index: 100;
    &:hover,
    &:visited:hover {
      background-position: -160px -230px;
      cursor: pointer;
    }
  }
  #next {
    display: block;
    height: 80px;
    width: 80px;
    text-indent: -9999em;
    font-size: 0em;
    background: url('../hammerite/img/globals/sprite.png') 0 -310px no-repeat;
    position: absolute;
    top: 130px;
    right: 0;
    z-index: 100;
    &:hover,
    &:visited:hover {
      background-position: -160px -310px;
      cursor: pointer;
    }
  }
  #prev.disabled {
    background: url('../hammerite/img/globals/sprite.png') -80px -230px no-repeat;
    cursor: default;
    &:hover,
    &:visited:hover {
      background-position: -80px -230px;
      cursor: default;
    }
  }
  #next.disabled {
    background: url('../hammerite/img/globals/sprite.png') -80px -310px no-repeat;
    cursor: default;
    &:hover,
    &:visited:hover {
      background-position: -80px -310px;
      cursor: default;
    }
  }
  section {
    figure {
      width: 604px;
      height: 340px;
      overflow: hidden;
      float: left;
    }
    width: 960px;
    height: 340px;
    float: left;
    div {
      float: right;
      width: 226px;
      height: 280px;
      background: #ece8de url('../hammerite/img/bg/noise-gold.png');
      padding: 30px 100px 30px 30px;
      h3,
      p {
        color: #373a3b;
        text-shadow: 0 1px 0 #ebe8e1;
        margin-bottom: 10px;
      }
    }
  }
}