* {
	margin: 0;
	padding: 0;
}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

header,nav,section,footer,figure,aside {
	display: block;
}

body { line-height: 1; }

@font-face {
    font-family: 'helvetica';
    src: url('fonts/helvetica.eot');
    src: url('fonts/helvetica.eot?#iefix') format('embedded-opentype'),
         url('fonts/helvetica.woff') format('woff'),
         url('fonts/helvetica.ttf') format('truetype'),
         url('fonts/helvetica.svg#StagLight') format('svg');
    font-weight: normal;
    font-style: normal;
}

h1, h2, h3, h4 {
  font-family: $font-web;
  color: #003e67;
  font-weight: normal;
  margin-bottom: 10px;
}

h1 { font-size: 3em; }
h2 { font-size: 2em; }
h3 { font-size: 1.6em; }
h4 {
  font-size: 1.2em;
  letter-spacing: normal;
}

ul { list-style: none; }

img { vertical-align: bottom; } 

a {
  color: #1186c3;
  text-decoration: underline;
}
a:hover,
a:visited:hover {
  color: #21cbf5;
  text-decoration: underline;
  cursor: pointer;
}

p { 
  line-height: 1.6em;
  padding: 0 0 15px 0;
  font-size: 1em;
  color: #373a3b;
}

blockquote,
q { quotes: none; }

:focus { outline: 0; }

ins { text-decoration: none; }
del { text-decoration: line-through; }

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Chrome */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type=search] { -webkit-appearance: textfield }
  header #search input { width: 300px; padding-left: 0 }
}