@media print {
	body { background: $white; }
	header,
	footer,
	#compare,
	#share { display: none; }
	
	.rating {
		display: block;
		width: 88px;
		height: 15px;
		float: left;
		margin-right: 10px;
		text-indent: -9999em;
		font-size: 0em;
		background: url('../hammerite/img/globals/sprite.png') no-repeat;
	}
	.ratinginfo { 
		color: #929ca1 !important;
		display: block;
		float: left;
		padding-top: 2px;
	}
	.rating.stars5 { background-position: -250px -230px; }
	.rating.stars4-5 { background-position: -250px -250px; }
	.rating.stars4 { background-position: -250px -270px; }
	.rating.stars3-5 { background-position: -250px -290px; }
	.rating.stars3 { background-position: -250px -310px; }
	.rating.stars2-5 { background-position: -250px -330px; }
	.rating.stars2 { background-position: -250px -350px; }
	.rating.stars1-5 { background-position: -250px -370px; }
	.rating.stars1 { background-position: -250px -390px; }
	.rating.stars0-5 { background-position: -250px -410px; }
	.rating.stars0 { background-position: -250px -430px; }
}