.tab-nav {
  background: #f6f4ee;
  padding: 4px 2px 0 !important;
  overflow: hidden;
  clear: both;
  background: #f6f4ee url('../hammerite/img/bg/bg-tabs.png') left bottom repeat-x;
  border-left: 1px solid #ece8de;
  border-top: 1px solid #ece8de;
  border-right: 1px solid #ece8de;
  li {
    float: left;
    padding: 0 !important;
    position: relative;
    a {
      display: block;
      float: left;
      background: #fff;
      border: 1px solid #f6f4ee;
      padding: 14px 18px;
      color: #9b9c9d;
      text-decoration: none;
      margin-right: 4px;
      border-left: 1px solid #ece8de;
      border-top: 1px solid #ece8de;
      border-right: 1px solid #ece8de;
      span {
        display: block;
        float: left;
        width: 23px;
        height: 23px;
        background: #f6f4ee;
        border-radius: 30px;
        border: 0;
      }
      &:hover,
      &:visited:hover {
        background: #fff;
        color: #333;
      }
    }
  }
  a.selected {
    background: #fff;
    color: #003e67;
    border-bottom: 1px solid #fff;
    text-decoration: none;
    border-top: 1px solid #003e67;
    &:hover,
    &:visited:hover {
      background: #fff;
      color: #003e67;
      border-bottom: 1px solid #fff;
      text-decoration: none;
      border-top: 1px solid #003e67;
    }
    span,
    &:hover span,
    &:visited:hover span {
      background: #003e67;
      color: #fff !important;
    }
  }
  li a span {
    display: block;
    color: #999 !important;
    font-size: 0.9em;
    font-weight: normal;
    position: absolute;
    top: 11px;
    right: 15px;
    line-height: 23px;
    text-align: center;
  }
  .amount a { padding-right: 42px; }
  li a:hover {
    border-top: 1px solid #1186c3;
    color: #1186c3 !important;
    span {
      background: #1186c3;
      color: #fff !important;
    }
  }
}

.tabs > div {
  padding: 20px 0;
  form { padding-bottom: 80px; }
}