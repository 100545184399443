header { z-index: 999 }

#topbar {
  height: 45px;
  background: url('../hammerite/img/bg/bg-topbar.png') 0 0 repeat-x;
  .wrapper {
    height: 45px;

	  .akzonobel {
		  float: right;
		  padding-top: 14px;
		  margin-left: 20px;
	  }
    ul {
      float: right;
      height: 45px;
      display: block;
      li {
        position: relative;
        float: left;
        padding-right: 1px;
        background: url('../hammerite/img/globals/sprite.png') 100% 11px no-repeat;
        a {
          display: block;
          float: left;
          padding: 0 14px;
          line-height: 42px;
          color: #8bafca;
          text-decoration: none;
          &:hover,
          &:visited:hover {
            color: #e1d7c0;
            text-decoration: none;
            border-bottom: 1px solid #e1d7c0;
            padding-bottom: 2px;
          }
        }
      }
      .parent a,
      .lang a {
        padding-right: 40px;
        background: url('../hammerite/img/globals/sprite.png') 100% -35px no-repeat;
      }
      .parent .selected {
        padding-bottom: 0;
        border-bottom: 5px solid #fff;
        color: #003e67;
        background: url('../hammerite/img/globals/sprite.png') 100% -546px no-repeat;
        background-color: #fff;
        height: 39px;
        &:hover,
        &:visited:hover {
          padding-bottom: 0;
          border-bottom: 5px solid #fff;
          color: #003e67;
          background: url('../hammerite/img/globals/sprite.png') 100% -546px no-repeat;
          background-color: #fff;
          height: 39px;
        }
      }
      .lang .selected {
        padding-bottom: 0;
        border-bottom: 5px solid #fff;
        color: #003e67;
        background: url('../hammerite/img/globals/sprite.png') 100% -546px no-repeat;
        background-color: #fff;
        height: 39px;
        &:hover,
        &:visited:hover {
          padding-bottom: 0;
          border-bottom: 5px solid #fff;
          color: #003e67;
          background: url('../hammerite/img/globals/sprite.png') 100% -546px no-repeat;
          background-color: #fff;
          height: 39px;
        }
      }
      .sub-menu {
        display: none;
        position: absolute;
        width: 200px;
        box-shadow: 0 4px 4px #002c4d;
        top: 44px;
        left: 0;
        z-index: 999;
        background: #fff;
        height: auto;
        border-top: 1px solid #e5e9ec;
        li {
          border-bottom: 1px solid #e5e9ec;
          padding: 0;
          margin: 0;
          height: auto;
          position: relative;
          a {
            width: 172px;
            padding: 0 14px;
            line-height: 32px;
            background-image: none;
            color: #003e67;
            &:hover,
            &:visited:hover {
              border-bottom: 1px solid #1186c3;
              color: #1186c3;
            }
          }
          &.de,
          &.ee,
          &.lt,
          &.lv,
          &.ru,
          &.uk,
          &.nl,
          &.fr {
            a{
              background: 14px 11px no-repeat;
              padding-left: 40px;
            }
          }
          &.de a {
            background-image: url('../hammerite/img/flags/de.png');
          }
	        &.ee a {
            background-image: url('../hammerite/img/flags/ee.png');
          }
          &.lt a {
            background-image: url('../hammerite/img/flags/ly.png');
          }
          &.lv a {
            background-image: url('../hammerite/img/flags/lv.png');
          }
          &.ru a {
            background-image: url('../hammerite/img/flags/ru.png');
          }
          &.uk a {
            background-image: url('../hammerite/img/flags/uk.png');
          }
          &.nl a {
            background-image: url('../hammerite/img/flags/nl.png');
          }
          &.fr a {
            background-image: url('../hammerite/img/flags/fr.png');
          }
        }
      }
    }
  }
}

header #topbar .wrapper ul {
  .lang {
    img {
      float: left;
      margin: 15px 10px 0 0;
    }
    .sub-menu li img { margin: 10px 10px 0 0; }
  }
  .shoppinglist {
    background: none;
    a {
      padding-right: 34px;
      span {
        display: block;
        background: url('../hammerite/img/globals/sprite.png') 0 -42px no-repeat;
        width: 23px;
        height: 23px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        line-height: 24px;
        position: absolute;
        top: 10px;
        right: 0;
      }
      &:hover span {
        background: url('../hammerite/img/globals/sprite.png') 0 -72px no-repeat;
        color: #373a3b;
      }
    }
  }
}

#topbar .wrapper ul .shoppinglist a:visited:hover span {
  background: url('../hammerite/img/globals/sprite.png') 0 -72px no-repeat;
  color: #373a3b;
}

header #topbar .wrapper ul .current a {
  &:hover,
  &:visited:hover {
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #fff;
    padding-bottom: 2px;
  }
}

header {
  .logo {
    display: block;
    position: absolute;
    top: -20px;
    left: 10px;
    width: 164px;
    height: 169px;
  }
  #search {
    float: right;
    position: relative;
    margin: 34px 0;
    z-index: 1;
    input {
      width: 252px;
      color: #8da5b9;
      font-family: Arial;
      font-size: 1.1em;
      background: url('../hammerite/img/globals/sprite.png') 0 0 no-repeat;
      height: 32px;
      border: 1px solid #6686a0;
      padding-left: 10px;
      padding-right: 38px;
    }
    .btn {
      width: 32px;
      height: 32px;
      background: url('../hammerite/img/globals/sprite.png') -26px -37px no-repeat;
      position: absolute;
      top: 1px;
      right: 1px;
      text-indent: -9999em;
      font-size: 0em;
      border: 0;
      padding: 0;
    }
    .btnShow {
      height: 28px;
      background: url('../hammerite/img/globals/sprite.png') 100% -600px no-repeat;
      position: absolute;
      top: 3px;
      right: 3px;
      font-weight: bold;
      color: #fff;
      width: auto;
      padding: 0 26px 2px 10px;
      border: 0;
      border-radius: 3px;
      &:hover {
        background-position: right -628px;
        cursor: pointer;
      }
    }
  }
}

.lt-ie8 header section form.focus .search-input { height: 22px !important; padding-top: 68px }
.lt-ie8 header section form.no-focus .search-input { height: 22px !important; padding-top: 6px }
