#popup_container {
	@include border-radius(5px);
	font-family: $font-family;
	font-size: 12px;
	min-width: 300px; /* Dialog will be no smaller than this */
	max-width: 600px; /* Dialog will wrap after this width */
	background: #FFF;
	border: solid 5px #999;
	color: #000;
	.error {
	  border: 0;
    padding: 0;
  }
}

#popup_container {
	@include border-radius(5px);
	display: none;
	padding: 15px;
	z-index: 100000;
	position: absolute;
	width: 500px;
	background: #FF9;
	border: 1px solid #E1E162;
	opacity: 1.0;		
	-moz-opacity: 1.0; 
	filter: alpha(opacity=100); 
	p { padding: 0; }
}

#popup_title {
	font-size: 17px;
	font-weight: bold;
	text-align: center;
	line-height: 1.75em;
	color: #666;
	cursor: default;
	padding: 0em;
	margin: 0em;
}

#popup_panel {
	text-align: center;
	margin: 1em 0em 0em 1em;
}

#popup_prompt {
	margin: .5em 0em;
}
a.close-note,
a.close-note:visited,
a.close-popup,
a.close-popup:visited {
	height: 11px;
	overflow: hidden;
	float: right;
}