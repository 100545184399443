#carousel {
  width: 660px;
  float: left;
  height: 240px;
  overflow: hidden;
  margin: 8px 0 10px 10px;
  position: relative;
  #carouselwrapper {
    width: 1980px;
    height: 240px;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
  }
  section {
    width: 660px;
    height: 240px;
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    >div {
      width: 620px;
      padding: 20px 20px 0;
      height: 220px;
      figure {
        float: right;
        margin: 0 20px;
      }
      p { padding-top: 10px; }
    }
  }
  ul {
    padding: 5px 1px 5px 6px;
    background: #ece8de;
    border-radius: 30px;
    float: left;
    position: absolute;
    top: 14px;
    right: 30px;
    li {
      float: left;
      margin-right: 4px;
      a {
        display: block;
        font-size: 0em;
        text-indent: -9999em;
        width: 10px;
        height: 10px;
        background: url('../hammerite/img/globals/sprite.png') -204px -47px no-repeat;
      }
      &.current a { background-position: -192px -47px; }
    }
  }
}

#single #carousel {
  height: 300px;
  section,
  #carouselwrapper {
    height: 300px;
  }
  section div { height: 280px; }
}