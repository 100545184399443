body {
  background: url('../hammerite/img/bg/body.jpg') 50% 0 no-repeat;
  font: normal 12px $font-family;
  width: 100%;
  color: #373a3b;
}

.inline {
  li,
  dd,
  dt { display: inline; }
}

.hidden { display: none; }

a {
  &.cta {
    @include border-radius(5px);
    display: block;
    border: 1px solid #02395e;
    background: url('../hammerite/img/globals/sprite.png') 100% -230px no-repeat;
    height: 30px;
    padding: 0 26px 0 16px;
    line-height: 30px;
    font-size: 1em;
    color: #c1dbe3;
    float: left;
    text-decoration: none;
    &:hover, &:visited:hover {
      background: url('../hammerite/img/globals/sprite.png') 100% -260px no-repeat;
      color: #fff;
      box-shadow: 0 1px 2px #8c9ba2;
      cursor: pointer;
    }
  }
  &.bull {
    display: block;
    float: left;
    padding: 4px 0 4px 14px;
    background: url('../hammerite/img/globals/sprite.png') -975px -353px no-repeat;
  }
}

.btnOrange {
  @include border-radius(4px);
  display: block;
  float: left;
  padding: 0 26px 0 16px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  text-shadow: 0 1px 0 #f15c03;
  background: url('../hammerite/img/globals/sprite.png') 100% -656px no-repeat;
  border: 1px solid #f15c03;
  &:hover {
    background: url('../hammerite/img/globals/sprite.png') 100% -688px no-repeat;
    cursor: pointer;
    box-shadow: 0 1px 2px #bbb;
    color: #fff !important;
  }
}

a.btnCompare {
  display: block;
  float: left;
  padding: 0 20px 0;
  height: 32px;
  line-height: 32px;
  text-decoration: none;
  background: none;
  color: #86a8c3;
  border: 2px solid #e9eef2;
  border-radius: 4px;
  margin-top: 10px;
  &:hover {
    color: #86a8c3;
  }
  i {
      margin-right: 4px;
  }
}

ul.list {
  padding: 5px 0 15px 0;
  li {
    font-size: 1em;
    padding: 4px 0 4px 16px;
    color: #373a3b;
    background: url('../hammerite/img/globals/sprite.png') -937px -395px no-repeat;
  }
}

body.page #contentxl ul {
	padding: 5px 0 15px 0;
	margin-left: 30px;
	margin-bottom: 1em;
	background: none;

	li {
		margin-bottom: 5px;
		list-style-type: disc;
	}
	ul {
		padding-bottom: 0;
		margin-left: 15px;

		li {
			background: none;
			padding-left: 0;
			list-style-type: none;

			&:before {
				content: ' - ';
				margin-right: 5px;
			}
		}
	}
}

#contentwrapper {
  background: url('../hammerite/img/bg/bg-content.png') center 0 no-repeat;
  z-index: 1;
  .wrapper {
    background: #fff;
    border-top: 4px solid #ece8de;
  }
}

.alignright {
    float: right;
    margin: 0 0 1em 15px;
}
.alignleft {
    float: left;
    margin: 0 15px 1em 0;
}
