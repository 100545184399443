#thepromos {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 800;
	background: rgba(#1e1e20, 0.75);
	display: block;
	width: 100%;
}

/*
*html #thepromos {
	display: none;
}
*/

#promos {
	width: 960px;
	margin: 0 auto;
	display: block;
	padding: 20px 0;
	overflow: hidden;
	height: 1%;
	color: #fff;
	position: relative;
}

.promo {
	background: white;
	color: #ccc;
	width: 698px;
	display: block;
	height: 60px;
	margin: 0 auto;
	padding: 15px;
	position: relative;
	p {
		padding: 0;
		width: 230px;
		line-height: 16px;
		a,
		a:visited { text-decoration: underline; }
	}
	h2 {
		font-size: 18px;
		padding-bottom: 0;
		margin-bottom: 0;
		color: #033b66;
	}
	a.cta {
		position: absolute;
		top: 30px;
		right: 15px;	
	}
}

.promo-hover {
	cursor: pointer;
	background-position: 0 -86px;
	span { border-color: #f6821a; }
	h2 {
		color: #f6821a;
		text-decoration: underline;
	}
}

#promos .second { margin: 0 25px; }

a#close-promos,
a#close-promos:visited {
	display: block;
	overflow: hidden;
	height: 13px;
	width: 13px;
	margin: 20px 20px 0 0;
	background: url('../hammerite/img/globals/sprite.png') left -1281px no-repeat;
	position: absolute;
	right: 10px;
	top: 10px;
	text-indent: -9999px;
}

a#close-promos:hover,
a#close-promos:visited:hover {
	background-position: left -1298px;
}

.banner-promo {
	width: 670px;
	height: 85px;
	margin: 15px auto;
	background: url('../hammerite/img/backgrounds/banner.png') 0 0 no-repeat;
	position: relative;
	dl {
		position: absolute;
		left: 20px;
		top: 20px;
		color: #fff;
		width: 350px;
	}
	dt {
		font-weight: 700;
		padding-bottom: 5px;
	}
	dd { line-height: 140% }
	a,
	a:visited {
		text-decoration: underline;
		color: #fff;
	}
}

.knight {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 999;
	display: block;

	@media only screen and (max-width: 1780px) {
		display: none;
	}

	#site-header & {
		position: absolute;
		height: 143px;
		left: 164px;
		bottom: 61px;
		z-index: 1;
		display: none;

		@media only screen and (max-width: 1780px) {
			display: block;
		}

		img {
			max-height: 100%;
		}
	}
}
