/* The clearfix mixin is necessary for the floats */
@mixin clearfix {
    *zoom: 1;
    &:before, &:after { display: block; height: 0 !important; line-height: 0; overflow: hidden; visibility: hidden; content:"."; }
    &:after { clear: both; }
}

.clear {
	@include clearfix;
}

.wrapper {
	display: block;
	width: 980px;
	margin: 0 auto;
	clear: both;
	position: relative;
}