#faq {
  width: 410px;
  padding: 0 20px 20px 0;
  float: left;
  dl {
    padding-bottom: 15px;
    dt {
      padding: 10px 0 11px 0;
      background: url('../hammerite/img/bg/sep-dotted-x.png') 0 100% repeat-x;
      a {
        display: block;
        background: url('../hammerite/img/globals/sprite.png') -646px -543px no-repeat;
        padding: 0 10px 0 15px;
        &.fold { background-position: -653px -517px; }
      }
    }
    dd {
      background: #f6f4ee;
      padding: 10px 15px;
      color: #373a3b;
      line-height: 1.5em;
      display: none;
    }
    a {
      text-decoration: none;
      &:hover,
      &:visited:hover {
        text-decoration: underline;
      }
    }
  }
}

#faqlist {
  padding: 20px 0;
  dl { padding-bottom: 20px; }
  dt { padding-bottom: 5px; }
  a.more-faq { display: inline-block; }
  dd {
    border-bottom: 1px solid #e5ebf0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: none;
  }
}

.more-faq {
  background: url('../hammerite/img/globals/sprite.png') left -461px no-repeat;
  display: block;
  margin-top: 10px;
  padding-left: 15px;
  width: 400px;
}