.slide-dualtech {
  display: block !important;
  .impr-formula {
    height: 69px;
    background: url('../hammerite/img/globals/sprite-dualtech.png') left -681px no-repeat;
    padding: 0 0 0 17px;
    position: absolute;
    bottom: 0;
    left: 120px;
    span {
      font-family: $font-web;
      color: #fff;
      font-style: italic;
      font-weight: normal;
      font-size: 1em;
      display: block;
      height: 54px;
      background: url('../hammerite/img/globals/sprite-dualtech.png') right -752px no-repeat;
      padding: 11px 24px 0 4px;
      text-transform: uppercase;
      text-align: right;
      margin-top: 13px;
      text-shadow: 0 0 1px #d37012;
      .lt-ie9 & {
        font-family: $font-family !important;
      }
    }
  }
  figure {
    position: absolute;
    right: 40px;
    top: 20px;
    .badge {
      position: absolute;
      top: 0;
      right: -20px;
    }
    div { width: auto; }
  }
  h1 {
    margin-bottom: 0;
    text-transform: uppercase;
    float: left;
  }
  em {
    display: block;
    float: left;
    height: 28px;
    background: url('../hammerite/img/globals/sprite-dualtech.png') 0 -810px no-repeat;
    padding-left: 12px;
    margin-top: 11px;
    span {
      display: block;
      height: 28px;
      background: url('../hammerite/img/globals/sprite-dualtech.png') 100% -840px no-repeat;
      padding: 0 18px 0 2px;
      font-family: $font-web;
      font-size: 1em;
      text-transform: uppercase;
      color: #fff;
      font-style: italic;
      text-shadow: 0 0 1px #d37012;
      line-height: 24px;
      .lt-ie9 & {
        font-family: $font-family !important;
      }
    }
  }
  p {
    width: 360px;
    clear: both;
  }
  .bull-link {
    background: url('../hammerite/img/globals/sprite.png') -974px -348px no-repeat;
    color: #1186C3;
    display: block;
    padding: 10px 0 6px 14px;
    text-decoration: none;
    clear: both;
    float: left;
    &:hover {
      color: #21cbf5;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

#dualtech-banner {
  width: 240px;
  height: 148px;
  padding: 10px 0 0 0;
  background-position: left -875px;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 30px;
  .packshot {
    position: absolute;
    left: 10px;
    top: 0px;
  }
  a {
    @include border-radius(4px);
    display: block;
    position: absolute;
    left: 10px;
    bottom: 10px;
    background: url('../hammerite/img/globals/sprite-dualtech.png') left -1033px;
    height: 32px;
    padding: 0 12px;
    line-height: 32px;
    border: 1px solid #1867a5;
    color: #fff;
    font-weight: bold;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    &:hover {
      background: url('../hammerite/img/globals/sprite-dualtech.png') left -1065px;
      cursor: pointer;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.5);
    }
  }
  em {
    color: #003E67;
    font-family: $font-web !important;
    font-size: 1.6em;
    font-weight: normal;
    margin: 0 0 0 110px;
    text-transform: uppercase;
    .lt-ie9 & {
      font-family: $font-family !important;
    }
  }
  .impr-formula {
    height: 69px;
    background: url('../hammerite/img/globals/sprite-dualtech.png') left -681px no-repeat;
    padding-left: 17px;
    position: absolute;
    bottom: 60px;
    left: 70px;
    span {
      font-family: $font-web;
      color: #fff;
      font-style: italic;
      font-weight: normal;
      font-size: 1.2em;
      display: block;
      height: 54px;
      background: url('../hammerite/img/globals/sprite-dualtech.png') right -752px no-repeat;
      padding: 8px 24px 0 8px;
      text-transform: uppercase;
      text-align: right;
      margin-top: 13px;
      text-shadow: 0 0 1px #d37012;
      .lt-ie9 & {
        font-family: $font-family !important;
      }
    }
  }
}

li.item-dualtech,
li.gewinnspiel {
    span {
        display: block;
        height: 22px;
        font-size: 11px;
        font-weight: 400;
        font-style: italic;
        position: absolute;
        top: -5px;
        font-family: arial;
        text-shadow: none;
        color: white;
        line-height: 21px;
        text-align: center;
    }
}

li.item-dualtech {
    span {
        background: {
            image: url('../hammerite/img/globals/sprite-dualtech.png');
            position: right top;
            repeat: no-repeat;
        }
        right: -7px;
        width: 49px;
    }
}

li.gewinnspiel {
    span {
        background: {
            image: url('../hammerite/img/gewinnspiel-menu.png');
            size: cover;
            repeat: no-repeat;
        }
        width: auto;
        right: 0;
        padding: 0 10px;
    }
}

.page-template-page-dualtech-php,
.page-template-page-dualtech-gewinnspiel-php {
  #content {
    padding: 20px 30px;
    width: 920px;
    h1 { text-transform: uppercase; }
  }
  #graph {
    float: left;
    position: absolute;
    right: 0;
    top: -25px;
    z-index: 1;
    #graph-wrapper {
      position: relative;
      padding-right: 30px;
        &.no-padding {
            padding: 0;
        }
      .badge {
        position: absolute;
        top: 0;
        right: 40px;
      }
      .conv-paint {
        height: 107px;
        background: url('../hammerite/img/globals/sprite-dualtech.png') 100% -23px no-repeat;
        padding-right: 41px;
        position: absolute;
        top: -4px;
        left: 20px;
        span {
          font-family: $font-web;
          color: #fff;
          font-style: italic;
          font-weight: normal;
          font-size: 1.2em;
          display: block;
          height: 54px;
          background: url('../hammerite/img/globals/sprite-dualtech.png') 0 -130px no-repeat;
          padding: 15px 8px 0 28px;
          text-transform: uppercase;
          text-align: right;
          text-shadow: 0 0 1px #d37012;
          .lt-ie9 & {
            font-family: $font-family !important;
          }
        }
      }
      .impr-formula {
        height: 107px;
        background: url('../hammerite/img/globals/sprite-dualtech.png') left -199px no-repeat;
        padding-left: 42px;
        position: absolute;
        bottom: 80px;
        left: 247px;
        span {
          font-family: $font-web;
          color: #fff;
          font-style: italic;
          font-weight: normal;
          font-size: 1.2em;
          display: block;
          height: 54px;
          background: url('../hammerite/img/globals/sprite-dualtech.png') right -308px no-repeat;
          padding: 15px 28px 0 8px;
          text-transform: uppercase;
          text-align: right;
          margin-top: 39px;
          text-shadow: 0 0 1px #d37012;
          .lt-ie9 & {
            font-family: $font-family !important;
          }
        }
      }
    }
  }
  .dualtech-text-intro {
    width: 510px;
    font-size: 1.1em;
    padding: 0 0 30px 0;
  }
  #packs {
    @include border-radius(2px);
    background: url('../hammerite/img/bg/noise-gold-light.png');
    margin-top: 40px;
    font-size: 1.1em;
    border: 0;
    position: relative;
    padding: 60px 40px 45px 340px;
    width: 540px;
    figure {
      position: absolute;
      left: 20px;
      top: -24px;
      border: 0;
    }
  }
  #usps {
    padding: 30px 0 31px 20px;
    background: url('../hammerite/img/bg/dotted-blue.png') left bottom repeat-x;
    li {
      width: 300px;
      float: left;
      figure {
        float: left;
        margin-right: 10px;
      }
      h3 {
        text-transform: uppercase;
        font-size: 1.6em;
        margin: 10px 0 0;
      }
      p {
        padding: 0;
        font-size: 1.1em;
      }
    }
  }
  #cols {
    padding: 20px 0;
    h2 {
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    .col {
      float: left;
      width: 282px;
      margin-right: 37px;
      &.last {
        margin-right: 0;
      }
      figure {
        position: relative;
        width: 272px;
        height: 162px;
        overflow: hidden;
        padding: 4px;
        border: 1px solid #eee;
      }
      #improved-formula {
        .logo {
          position: absolute;
          top: 50px;
          right: 10px;
        }
        em {
          display: block;
          position: absolute;
          top: 4px;
          padding: 10px 0 0 44px;
          right: 35px;
          background: url('../hammerite/img/globals/sprite-dualtech.png') 0 -1164px no-repeat;
          height: 50px;
          color: #fff;
          font-family: $font-web;
          text-transform: uppercase;
          font-size: 1.1em;
          .lt-ie9 & {
            font-family: $font-family !important;
          }

        }
      }
    }
  }
  #cols .col {
    figure span {
      display: block;
      width: 280px;
      text-indent: -9999em;
      height: 170px;
      background: url('../hammerite/img/globals/sprite-dualtech.png') 0 -380px no-repeat;
      position: absolute;
      top: 0;
      left: 0;
    }
    div {
      background: url('../hammerite/img/bg/noise-lightblue.jpg');
      padding: 10px 20px 0 20px;
      font-size: 1.1em;
    }
  }
  #closure {
    padding: 20px 100px 20px 140px;
    font-size: 1.1em;
    figure {
      float: left;
      margin-right: 30px;
    }
    p {
      padding: 18px 0 0;
    }
  }
}

#footer {
  .slide-dualtech {
    position: relative;
  }
  .dualtech-label {
    position: absolute;
    top: 12px;
    left: 100px;
  }
  .bull-link {
    display: block;
    margin-bottom: 2em;
  }

  p {
    width: 100%;
    clear: both;
  }
  figure {
    clear: both;
    position: relative;
    right: auto;
    top: auto;

    .badge {
      left: 120px;
    }
  }
}

.gewinnspiel-link {
  img {
    width: 100%;
  }
}

.enter-raffle {
    padding: 60px 0;
    font-family: $font-web !important;
    color: #194382;

    img {
        max-width: 100%;
    }

    p {
        color: #194382;
    }

    &__title {
        font: {
            size: 60px;
            weight: 900;
            family: $font-web !important;
        }
        text-transform: uppercase;
    }

    &__description {
        font-size: 24px;
        max-width: 60%;
    }

    span.orange {
        color: #d07327 !important;
    }

    span.lightblue {
        color: #3f80ad !important;
    }

    &__form {
        background: #efeee5;
        margin-top: 100px;
        padding: 40px;
        text-align: center;

        &__title {
            font: {
                size: 30px;
                weight: 700;
            }
            text-transform: uppercase;
            background: none !important;
        }

        form {
            text-align: left;
        }

        .gform_fields {
            > li:not(:first-of-type) {
                margin-left: 60px;
            }

            > li:not(:first-of-type):not(.gfield_html) {
                font-size: 16px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .gfield_label {
                    width: 200px;
                }

                select {
                    appearance: none;
                }

                input,
                select {
                    border: {
                        color: #0e1c45 !important;
                        width: 1px;
                        style: solid;
                        radius: 8px;
                    }
                    background: none;
                    padding: 6px;
                    font-size: 16px;
                }
            }
        }

        input[type="image"] {
            border: none !important;
            width: 150px !important;
            margin-left: 60px;
        }

        .gfield_html {
            font-size: 20px;
        }

        .terms {
            margin-top: 60px;
            .gfield_label {
                display: none;
            }

            input {
                label {
                    color: #194382;
                }
            }
        }

        select {
            width: 228px;
        }
    }

    .main-question {
        text-align: center;
        width: 60%;
        margin: 0 auto;

        .gfield_label {
            font: {
                size: 40px;
                weight: 400 !important;
            }
            margin-top: 40px;
            display: block;
            text-transform: uppercase;
        }

        .gfield_radio {
            text-align: left;
            margin: 20px 0 0 60px;

            li {
                padding: 0 !important;
                margin-bottom: 20px;
            }

            label {
                font-size: 20px !important;
                color: #0e1c45 !important;
            }
        }

        input {
            width: auto !important;
        }
    }

    .hauptgewinn {
      width: 100%;
      text-align: center;
    }

    .gfield_checkbox {
        li {
            padding: 0 !important;
        }
    }

    .gform_footer {
        margin-top: 60px;
    }

    .mandatory {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
    }

    .validation_message {
        display: block;
        width: 100%;
        color: #ff0000;
    }
}
.page-template-page-dualtech-gewinnspiel-php {
    .dualtech-text-intro {
        width: 440px;
    }
    #packs {
        margin-top: 100px;
    }
    .validation_error {
        text-align: center;
        color: #ff0000;
    }

    .gfield_checkbox li, .gfield_radio li {
        position: relative;
    }

    .gform_wrapper form {
        position: relative;
    }

    .gform_wrapper .gfield_radio li label,
    .gform_wrapper .gfield_checkbox li label {
        margin-left: 40px;
    }

    .gform_wrapper ul li.gfield.optional .gfield_checkbox label::after,
    .gform_wrapper ul li.gfield.optional .gfield_radio label::after {
        content: "";
        opacity: 1;
    }

    .gform_wrapper .gfield_radio li input[type="radio"],
    .gform_wrapper .gfield_checkbox li input[type="checkbox"] {
        display: none;
    }

    ul.gfield_checkbox li[class^="gchoice"] label:before,
    ul.gfield_checkbox li[class^="gchoice"] label:after,
    ul.gfield_radio li[class^="gchoice"] label:before,
    ul.gfield_radio li[class^="gchoice"] label:after {
        bottom: 0;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
    }

    ul.gfield_checkbox li[class^="gchoice"] label:after,
    ul.gfield_radio li[class^="gchoice"] label:after {
        content: "";
        border: solid 1px #0e1c45;
        border-radius: 8px;
        display: inline-block;
        height: 24px;
        left: 0;
        position: absolute;
        top: 0;
        transition: left .25s, background-color .13s;
        width: 24px;
    }

    ul.gfield_checkbox input[type="checkbox"]:checked + label:before,
    ul.gfield_radio input[type="radio"]:checked + label:before {
        content: 'X';
        color: #0e1c45;
        left: 7px; /*.75px*/
        top: -5px;
        z-index: 9;
        border-radius: 8px;
        height: 24px;
        width: 24px;
        font-size: 24px;
    }
}