/*!
Theme Name: Hammerite Right-to-Left
Description: RTL theme for Hammerite websites
Author: Concept7 & Uprise
Template: hammerite
*/

//---- Core
@import "../hammerite/css/core/settings.scss";
@import "../hammerite/css/core/mixins.scss";
@import "../hammerite/css/core/base.scss";
@import "../hammerite/css/core/general.scss";
@import "../hammerite/css/core/grid.scss";

//---- Modules
@import "../hammerite/css/modules/header.scss";
@import "../hammerite/css/modules/navigation.scss";
@import "../hammerite/css/modules/ratings.scss";
@import "../hammerite/css/modules/reviews.scss";
@import "../hammerite/css/modules/carousel.scss";
@import "../hammerite/css/modules/colors.scss";
@import "../hammerite/css/modules/slider.scss";
@import "../hammerite/css/modules/share.scss";
@import "../hammerite/css/modules/tables.scss";
@import "../hammerite/css/modules/tabs.scss";
@import "../hammerite/css/modules/footer.scss";
@import "../hammerite/css/modules/compare.scss";
@import "../hammerite/css/modules/productfinder.scss";
@import "../hammerite/css/modules/notifications.scss";
@import "../hammerite/css/modules/forms.scss";
@import "../hammerite/css/modules/popups.scss";
@import "../hammerite/css/modules/promos.scss";
@import "../hammerite/css/modules/cookie-consent.scss";

@import "../hammerite/css/modules/page-general.scss";
@import "../hammerite/css/modules/page-home.scss";
@import "../hammerite/css/modules/page-category.scss";
@import "../hammerite/css/modules/page-detail.scss";
@import "../hammerite/css/modules/page-faqs.scss";
@import "../hammerite/css/modules/page-howtos.scss";
@import "../hammerite/css/modules/page-contact.scss";
@import "../hammerite/css/modules/page-404.scss";
@import "../hammerite/css/modules/page-search.scss";
@import "../hammerite/css/modules/page-shoppinglist.scss";

@import "../hammerite/css/modules/dualtech.scss";

@import "../hammerite/css/modules/MOBILE.scss";

//---- Print
@import "../hammerite/css/core/print.scss";

//---- Right to Left
@import "css/modules/r-l.scss";