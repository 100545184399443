.review {
  padding: 0 0 10px !important;
  margin-bottom: 20px;
  border-bottom: 1px solid #ece8de;
  h2 {
    float: left;
    font-family: Arial;
    font-size: 1.8em;
    margin: 0 15px 4px 0;
  }
  .rating { margin-top: 5px; }
  em {
    display: block;
    color: #969794;
    clear: both;
    font-weight: normal;
    font-style: normal;
  }
  p { padding-top: 10px; }
  ul {
    float: left;
    width: 330px;
    padding-bottom: 10px;
  }
  .cons { float: right; }
  .pros .title,
  .cons .title {
    padding: 0;
    background: none;
  }
  ul li {
    color: #373a3b;
    padding: 6px 10px 6px 20px;
    h3 {
      font-family: $font-family;
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  .pros li, 
  .cons li { background: url('../hammerite/img/globals/sprite.png') -507px -734px no-repeat; }
  .cons li { background-position: -490px -754px; }
  .meta {
    clear: both;
    padding: 10px;
    background: #f6f4ee;
    border: 1px solid #ece8de;
    color: #373a3b;
    span {
      float: right;
      color: #969794 !important;
    }
    em {
      float: left;
      font-style: normal;
      font-weight: normal;
      margin-right: 20px;
    }
    a {
      display: block;
      float: left;
      margin-right: 10px;
      padding-left: 24px;
      height: 16px;
    }
    .yes { background: url('../hammerite/img/globals/sprite.png') -243px -43px no-repeat; }
    .no { background: url('../hammerite/img/globals/sprite.png') -353px -45px no-repeat; }
  }
  .helpful-yes {
    .yes {
      font-weight: bold;
      color: #333;
      text-decoration: none;
      cursor: default;
    }
    .no {
      color: #ccc;
      text-decoration: none;
      cursor: default;
      background: url('../hammerite/img/globals/sprite.png') -353px -75px no-repeat;
    }
  }
  .helpful-no {
    .no {
      font-weight: bold;
      color: #333;
      text-decoration: none;
      cursor: default;
    }
    .yes {
      color: #ccc;
      text-decoration: none;
      cursor: default;
      background: url('../hammerite/img/globals/sprite.png') -243px -73px no-repeat;
    }
  }
  a.flag {
    display: block;
    float: left;
    padding: 10px 0 0 11px;
  }
}

.response {
  padding: 15px 20px 0 !important;
  background: url('../hammerite/img/bg/noise-lightblue.jpg');
  border: 1px solid #d3dee4;
  margin-bottom: 10px;
}