/* Popup */
.popup { padding: 15px 20px 25px 20px; background: url('../hammerite/img/bg/popup.png') right bottom no-repeat }
.popup p { padding: 0; color: #fff !important; text-shadow: 0 1px 0 #000 !important; margin: 0 !important; padding: 0 !important }
#prodfinder .popup { position: absolute; width: 222px; bottom: 108px; right: 18px; display: none }

/* c7 Popup */
#c7boxwrapper { width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: url('../hammerite/img/bg/c7box.png'); display: none; z-index: 999 }
#c7boxabsolute { width: 938px; margin: 80px auto 0 auto; position: relative; z-index: 999;  } 
#c7box { width: 870px; border: 0; background: #fff; box-shadow: 0 1px 6px #42627b; height: 500px; padding: 20px; overflow: auto; z-index: 999; position: relative }
#c7boxwrapper #close { display: block; float: right; height: 20px; line-height: 20px; padding-right: 30px; color: #999; background: url('../hammerite/img/globals/sprite.png') right -920px no-repeat; text-decoration: none; margin-bottom: 20px }
#c7boxwrapper #close:hover,
#c7box #close:visited:hover { background: url('../hammerite/img/globals/sprite.png') right -940px no-repeat; color: #08a5d9; text-decoration: underline }
#c7boxabsolute .product { float: right }
#c7boxabsolute .product figure { width: 120px; height: 140px; border: 1px solid #ece8de; float: left; margin-right: 0; padding: 3px; background: #f6f4ee }
#c7boxabsolute .product span { clear: both; display: block; color: #003e67; padding: 10px 0 0 0; font-weight: bold }
#c7boxabsolute .product span.rating { padding: 0; margin: 10px 0 0 0 }
#c7boxabsolute .product figure { width: 200px; height: 200px }
#c7boxabsolute .product figure span { display: block; width: 200px; height: 175px; background: #fff; text-align: center; padding: 25px 0 0 0; margin: 0 }
#c7box .frm-contact, body.plain-page {  }
#c7box .frm-contact div { clear: both; border-bottom: 1px solid #eee; padding-top: 12px }
#c7box .frm-contact { border-top: 1px solid #eee }
#c7box .frm-contact em { clear: both; color: #666; display: block }
#c7box .frm-contact .clicktorate em { width: auto; clear: none; float: left; padding: 2px 0 0 16px }
#c7box .frm-contact .reviewinfo { clear: none; padding-bottom: 10px }
#c7box .frm-contact .reviewinfo ul { list-style: disc; padding-left: 20px }
#c7box .frm-contact .reviewinfo ul li { padding-top: 4px }
#c7box .frm-contact .radios { float: left }
#c7box .frm-contact .radios li .radio { width: auto; margin: 5px 10px 0 0; float: left }
#c7box .frm-contact .radios li label { width: 300px; font-weight: normal }
#c7box .frm-contact .radios li { padding-bottom: 4px; overflow: hidden }
#c7box .frm-contact h3 { margin: 20px 0 0 0 }
#c7box .frm-contact button { padding-bottom: 4px; margin: 20px 20px 20px 150px; line-height: 0em }
#c7box .frm-left { float: left; width: 600px }
#c7boxabsolute .frm-right { width: 280px; position: absolute; top: 20px; right: 30px; z-index: 999 }
#c7box .pros-cons em { padding-left: 0 }
#c7box .pros-cons ul { width: 300px; float: left; margin: 0 20px 0 0; padding: 10px 0 0 0 }
#c7box .pros-cons ul li { padding-bottom: 6px; overflow: hidden }
#c7box .pros-cons ul li a { display: block; clear: both }

/* Form popups */
.plain-page { background: none; }
#c7box .frm-contact div,#c7box .frm-contact .gfield{clear:both;border-bottom:1px solid #eee;padding:12px 0;overflow:hidden}
#c7box .frm-contact .ginput_container,#c7box .frm-contact .gfield_description{margin:0;padding:0;clear:none;border:0}
#c7box .frm-contact .gform_heading,#c7box .frm-contact .gform_body,#c7box .frm-contact .gform_footer{border:0}
#c7box .left_label .gfield_description{margin:5px 0 0 150px;color:#666}
.gfield_description ul{list-style:disc;margin-left:20px}
.gfield_description ul li{padding-top:4px}
#c7box li.pros input,#c7box li.cons input{margin-bottom:3px}
#c7box .frm-contact li.pros,#c7box .frm-contact li.cons{clear:none;width:360px;float:left;margin:0 20px 0 0;padding:10px 0 0 0;border:0}
#c7box .frm-contact li.pros label,#c7box .frm-contact li.cons label{float:none;display:block}
#c7box .frm-contact li.pros input,#c7box .frm-contact li.cons input { margin-right: 5px; }
#c7box .frm-contact em{clear:both;color:#666;display:block}
#c7box .frm-contact .clicktorate em{width:auto;clear:none;float:left;padding:2px 0 0 16px}
#c7box .frm-contact .reviewinfo{clear:none;padding-bottom:10px}
#c7box .frm-contact .reviewinfo ul{list-style:disc;padding-left:20px}
#c7box .frm-contact .reviewinfo ul li{padding-top:4px}
.frm-contact label{font-weight:bold;padding:5px 0;cursor:pointer}
.frm-contact .gfield_radio{margin:1px}
.frm-contact .gfield_radio li{float:left;margin:0 15px 1em 0}
.frm-contact .gfield_radio input{float:none;width:auto;height:auto;border:0;margin-right:5px}
.frm-contact .gfield_radio label{float:none;font-weight:normal}
#c7box .frm-contact .radios{float:left}
#c7box .frm-contact .radios li .radio{width:auto;margin:5px 10px 0 0;float:left}
#c7box .frm-contact .radios li label{width:300px;font-weight:normal}
#c7box .frm-contact .radios li{padding-bottom:4px;overflow:hidden}
#c7box .frm-contact button{padding-bottom:4px;margin:20px 20px 20px 150px;line-height:0}
#c7box .frm-left{float:left;width:600px}
#c7boxabsolute .frm-right{width:280px;position:absolute;top:20px;right:30px;z-index:999}
#c7box .pros-cons em{padding-left:0}
#c7box .pros-cons ul{width:300px;float:left;margin:0 20px 0 0;padding:10px 0 0 0}
#c7box .pros-cons ul li{padding-bottom:6px;overflow:hidden}
#c7box .pros-cons ul li a{display:block;clear:both}
