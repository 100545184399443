address {
  &.contact-list {
    font-style: normal;
    padding-bottom: 20px;
    line-height: 18px;
  }
  .telephone { display: block; }
}

.frm-contact {
  label,
  form input {
    float: left;
  }
}

.frm-finder {
  input,
  button {
    float: left;
  }
}

.frm-contact {
  p {
    padding: 0 0 20px 150px;
  }
  label {
    width: 150px;
    padding-top: 5px;
    color: #373a3b;
  }
  input, textarea {
    border: 1px solid #c2c2c2;
    width: 220px;
    padding: 5px;
    font-family: Arial;
    font-size: 1em;
    color: #373a3b;
  }
}

.frm-finder input {
  border: 1px solid #c2c2c2;
  width: 220px;
  padding: 5px;
  font-family: Arial;
  font-size: 1em;
  color: #373a3b;
}

.frm-contact {
  input.long { width: 400px; }
  textarea {
    width: 400px;
    height: 100px;
    overflow: auto;
  }
  em {
    display: block;
    padding: 5px 0 0 150px;
    color: #959595;
    font-style: normal;
  }
  div {
    padding-bottom: 12px;
  }
  button {
    margin-left: 150px;
    border: 1px solid #02395e;
    background: url('../hammerite/img/globals/sprite.png') 100% -230px no-repeat;
    height: 30px;
    padding: 0 26px 0 16px;
    line-height: 30px;
    font-size: 1em;
    color: #c1dbe3;
    border-radius: 5px;
    text-decoration: none;
  }
}

.frm-finder button {
  margin-left: 150px;
  border: 1px solid #02395e;
  background: url('../hammerite/img/globals/sprite.png') 100% -230px no-repeat;
  height: 30px;
  padding: 0 26px 0 16px;
  line-height: 30px;
  font-size: 1em;
  color: #c1dbe3;
  border-radius: 5px;
  text-decoration: none;
}

.frm-contact button:hover,
.frm-finder button:hover {
  background: url('../hammerite/img/globals/sprite.png') 100% -260px no-repeat;
  color: #fff;
  box-shadow: 0 1px 2px #8c9ba2;
  cursor: pointer;
}

#content .gform_wrapper {
  ul li { padding-top: 10px; }
  input {
    width: 218px;
    border: 1px solid #C2C2C2;
    padding: 5px;
    margin: 5px 0;
  }
  textarea {
    border: 1px solid #C2C2C2;
    padding: 5px;
    margin: 5px 0;
  }
}

#contentwrapper #content #contentxl .gform_wrapper .button {
  width: 230px;
  margin-bottom: 15px;
  border: 1px solid #02395e;
  background: url('../hammerite/img/globals/sprite.png') 100% -230px no-repeat;
  height: 30px;
  padding: 0 26px 0 16px;
  line-height: 30px;
  font-size: 1em;
  color: #c1dbe3;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    background: url('../hammerite/img/globals/sprite.png') 100% -260px no-repeat;
    color: #fff;
    box-shadow: 0 1px 2px #8c9ba2;
    cursor: pointer;
  }
}