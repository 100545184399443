#compare-table {
  th {
    width: 242px;
    text-align: left;
    background: #fff;
    border-bottom: 1px solid #e3e3e3;
    vertical-align: top;
  }
  td {
    border-bottom: 1px solid #e3e3e3;
    vertical-align: top;
  }
  th {
    &.first { width: 244px; }
    span.no-product { background: #fafafa; }
  }
  td { padding: 11px 5px; }
  .td-title {
    font-weight: bold;
    padding: 11px 10px;
    background: url('../hammerite/img/globals/sprite.png') 0 -450px no-repeat;
  }
  td {
    &.pdf, &.word, &.zip, &.time {
      background: url('../hammerite/img/globals/sprite.png') -284px -473px no-repeat;
      padding: 11px 0 11px 30px;
    }
    &.word { background-position: -284px -528px; }
    &.zip { background-position: -284px -584px; }
    &.time { background-position: -342px -430px; }
  }
  .striped { background: #f0eeee; }
  th span {
    display: block;
    overflow: hidden;
    width: 230px;
    height: 128px;
    text-align: center;
    margin-left: 5px;
    border: 1px solid #e3e3e3;
  }
}

.cookie-types-table {
  tr {
    td:first-of-type {
      white-space: nowrap;
    }
  }

  td {
    padding: 10px;
  }
}