/* The clearfix mixin is necessary for the floats */
@mixin clearfix {
    *zoom: 1;
    &:before, &:after { display: block; height: 0 !important; line-height: 0; overflow: hidden; visibility: hidden; content:"."; }
    &:after { clear: both; }
}

.clear {
	@include clearfix;
}


ol li {
  padding-left: 0;
  padding-right: 20px;
  margin-left: 0;
  margin-right: 40px;
}

a.cta {
  float: right;
  background: url("img/globals/buttons_r-l.png") 0 -64px no-repeat;
  padding: 0 16px 0 26px;
  &:hover {
    background: url("img/globals/buttons_r-l.png") 0 -94px no-repeat;
  }
}

header {
  #topbar .wrapper {
    .akzonobel {
      float: left;
      margin-right: 10px;
    }
    ul {
      float: left;
      li {
        background: url("img/globals/sprite_r-l.png") 0 -208px no-repeat;
        float: right;
      }
      .lang a {
        background: url('img/globals/sprite_r-l.png') 10px -140px no-repeat;
        padding-left: 35px;
        padding-right: 14px;
      }
      .lang .selected:hover,
      .lang .selected:visited:hover,
      .lang .selected {
        background: #ffffff url('img/globals/sprite_r-l.png') 10px -403px no-repeat;
      }
      .shoppinglist a {
        padding-left: 34px;
        padding-right: 14px;
        span {
          left: 5px;
          right: auto;
        }
      }
      .sub-menu {
        right: 1px;
        a {
          background: none;
          padding-left: 14px;
        }
        li {
          &.ee,
          &.lt,
          &.lv,
          &.ru,
          &.uk,
          &.nl,
          &.fr {
            a {
              background-position: 170px 11px;
              padding-left: 14px;
              padding-right: 40px;
              width: 146px;
            }
          }
        }
      }
    }
  }

  .wrapper .menu-trigger {
	@media only screen and (max-width: 767px) {
	  float: right;
	  padding: 7px 36px 7px 0;
	  background-position: 51px -157px;
    }  
  }
  #search {
    float: left;
    input {
      padding-left: 38px;
      padding-right: 0;
      text-align: right; 
    }
    .btn {
      left: 1px;
      top: 0px;
      right: auto;
    }
    .btnShow {
      top: 2px;
      left: 2px;
      right: auto;
      padding: 0 10px 2px 26px;
      background: url('img/globals/buttons_r-l.png') 0 -180px no-repeat;
      &:hover {
        background: url('img/globals/buttons_r-l.png') 0 -208px no-repeat;
      }
    }
  }
  nav ul {
    padding-left: 0;
    padding-right: 180px;
    li { float: right; }
    .parent > a,
    .parent > a:hover {
      background: url('img/globals/sprite_r-l.png') 15px -130px no-repeat;
      padding-left: 36px;
      padding-right: 24px;
    }
    .parent .sub-menu a {  
      padding-left: 14px;
      padding-right: 14px;
    }
  }
  nav .sub-menu {
    left: auto;
    right: 0;
  }
}

header .wrapper {
  position: relative;
  a.logo {
    left: auto;
    right: 10px;
    @media only screen and (max-width: 767px) {
	  right: auto;
	  left: 0;
	}
  }
}
  
.home header .wrapper a.logo {
  @media only screen and (max-width: 767px) {
	right: auto;
	left: 0;
  }
}

#carousel {
	float: right;
	margin: 8px 10px 10px 0;
	#carouselwrapper {
		left: auto;
		right: 0;
	}
	section {
		float: right;
		left: auto;
		right: 0;
	}
	.slide-dualtech .bull-link {
		float: right;
		background: none;
		padding-left: 0;
 }
}

#prodfinder {
  float: left;
  input { float: right; }
  input[type=submit] {
	@media only screen and (max-width: 767px) {
	  float: right;
	  padding: 0 0 0 35px;
	  background: url("img/globals/buttons_r-l.png") 0 -236px;
	  &:hover {
		background: url("img/globals/buttons_r-l.png") 0 -274px;	  
	  }
    }
  }
}

#slider section {
  figure { float: right; }
  div {
    float: left;
    padding: 30px 30px 30px 100px;
  } 
}

#breadcrumbs {
  @include clearfix;
  padding: 10px 20px 10px 10px;
  @media only screen and (max-width: 767px) {
	padding: 0;
  }
  span {
  	float: right;
	@media only screen and (max-width: 767px) {
	  float: none;
    } 
  }
  li,
  span span,
  > span > span {
    margin-right: 0;
    @media only screen and (max-width: 767px) {
	  margin: 0 auto;
    }
    a {
      background: url('img/globals/sprite_r-l.png') 0 -258px no-repeat;
      margin-left: 14px;
      margin-right: 0;
      padding-left: 14px;
      padding-right: 0;
      @media only screen and (max-width: 767px) {
		background: none;
		float: right;
      }
    }
  }
}

#sidebarxl {
  float: right;
  margin: 10px 0 0 20px;
  #submenu li a { padding: 10px 10px 10px 30px; }
}

#contentxl {
  float: right;
  figure.right {
    float: left;
    margin: 0 20px 20px 0;
  }
}

#content #refine {
  float: right;
  section a {
    padding: 10px 30px 10px 10px;
    background: url("img/globals/sprite_r-l.png") 94% -289px no-repeat;
    &:hover {
      background: url("img/globals/sprite_r-l.png") 94% -328px no-repeat;
    }
    &.fold,
    &.fold:hover {
      background: url("img/globals/sprite_r-l.png") 96% -485px no-repeat;
    }
  }
  section ul li {
    input {
      float: right;
      margin: 2px 0 0 8px;
    }
    label { float: right; }
    em { float: left; }
  }
}

#colors ul li {
  float: right;
}

a.close {
  float: left;
}

#content #prodwrapper {
  float: right;
  .btnOrange,
  .btnCompare {
    float: right;
  }
  .btnCompare {
    margin-left: 0;
    margin-right: 10px;
  }
  dl.features dd span {
    float: right;
  }
}

.product {
  .btnOrange {
    padding: 0 10px 0 20px;
  }
  .amount {
    a.plus,
    input,
    a.minus,
    .delete {
        float: right;
    }
  }
  figure {
    float: right;
    margin-left: 20px;
    margin-right: 0;
  }
  div { float: right; }
}

#frm-shoppinglist p .delete {
    float: right;
}

#share .email a,
#share .print a {
  padding-left: 10px;
  padding-right: 24px;
}

#share .email a {  
  background: url('img/globals/sprite_r-l.png') 100% -27px no-repeat;
}

#share .print a {  
  background: url('img/globals/sprite_r-l.png') 100% 8px no-repeat;
}

.wp-pagenavi {
  float: left;
  a,
  span {
    float: right;
  }
}

.tab-nav {
  li {
    float: right;
    a span {
      right: auto;
      left: 15px;
    }
  }
  .amount a {
    padding-left: 42px;
    padding-right: 20px;
  }
}

#procs li {
  background-position: 0 0;
  float: right;
  padding: 0 0 0 20px;
  margin-left: 20px;
  margin-right: 0;
  @media only screen and (max-width: 767px) {
	padding-right: 15px;
	padding-left: 0 !important;
	margin-left: 0 !important;	
  }
}

#content #ctablock em,
#content #ctablock a {
  float: right;
}
#content #ctablock span { float: left; }

.btnOrange {
  background: url("img/globals/buttons_r-l.png") 0 0 no-repeat;
  padding: 0 16px 0 26px;
  &:hover {
    background: url("img/globals/buttons_r-l.png") 0 -32px no-repeat;
  }
}

#share .email form {
  input {
    float: right;
    margin-left: 5px;
    margin-right: 0;
  }
  .btnOrange { float: right; }
  div a {
    right: auto;
    left: -20px;
  }
}

#contentwrapper #content #contentxl .gform_wrapper .button {
  padding: 0 16px 0 26px;
  background: url("img/globals/buttons_r-l.png") 0 -64px no-repeat;
  &:hover {
    background: url("img/globals/buttons_r-l.png") 0 -94px no-repeat;
  }
}

ul.list li {
  background: url('img/globals/sprite_r-l.png') 100% -94px no-repeat;
  padding: 4px 16px 4px 0;
}
#contentwrapper #content #contentxl ul.list li {
  padding: 2px 16px 4px 0;
}

.tabs .mobile-tab-nav > li > a {
	@media only screen and (max-width: 767px) {
		padding-left: 0;
		padding-right: 14px;
    }
}

#frm-storefinder {
	@include clearfix;
	margin-bottom: 25px;
	input {
		float: none;
		@media only screen and (max-width: 767px) {
			margin-right: 0;
    	}
	}
	button {
		background: url("img/globals/buttons_r-l.png") 0 -64px no-repeat;
		padding: 0 16px 0 26px;
		margin: 0;
		float: right;
    }
}

dl.features dd,
dl.features dt {
  float: right;
}

a.bull {
  background: url('img/globals/sprite_r-l.png') 100% -64px no-repeat;
  float: right;
  padding: 4px 14px 4px 0;
}

footer #mobile-footer {
	ul li { float: right; }
	li a {
		border: 0;
	}
	li.first,
	li.last {
	  padding-left: 0;
	  padding-right: 0;
	}
}

#compare {
  z-index: 9999;
  .wrapper div .delete {
      float: right;
  }
}

.promo a.cta {
  right: auto;
  left: 15px;
  @media only screen and (max-width: 767px) {
    left: 0;
    right: auto;
  }
}

a#close-promos,
a#close-promos:visited {
  right: auto;
  left: 10px;
  margin: 20px 0 0 20px;
  @media only screen and (max-width: 767px) {
    margin: 3px 0 0 13px;
  }
}

.tabs .mobile-tab-nav > li > a {
  background: white url('img/globals/sprite_r-l.png') 8px -405px no-repeat;
}
.tabs .mobile-tab-nav > li > a.selected {
  background: white url('img/globals/sprite_r-l.png') 8px -368px no-repeat;
}

#related {
  margin-bottom: 20px !important;
}

#breadcrumbs > span > span a:before {
  @media only screen and (max-width: 767px) {
    content: '<';
    float: right;
  }
}

nav #search input[type=search] {
  @media only screen and (max-width: 767px) {
    padding-right: 10px;
    padding-left: 27px;
    left: 18px;
  }
}

nav #search .btn {
	right: auto;
	left: 25px;
}

body > .outer-scroll > nav {
	@media only screen and (max-width: 767px) {
		background-image: url('img/bg/mobile-bg-menu.png');
    }
}

#carousel .slide-dualtech figure .badge,
.page-template-page-dualtech-php #graph #graph-wrapper .badge {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: "FlipH";
}

.page-template-page-dualtech-php #graph,
#carousel .slide-dualtech h1,
#carousel .slide-dualtech em {
	float: right;
}

#carousel .slide-dualtech {
	em {
		margin-right: 10px;
	}
	figure {
		float: left;
		right: auto;
		left: 40px;
		.badge {
			right: auto;
			left: -20px;
		}
	}
}

.page-template-page-dualtech-php {
	#graph {
		right: auto;
		left: 30px;
	}
	#packs {
		padding: 60px 340px 45px 40px;
		figure {
			left: 0;
			right: 20px;
		}
	}
	#closure figure {
		float: right;
		margin-left: 30px;
		margin-right: 0;
	}
	#usps li figure {
  	float: right;
  	margin-right: 0;
  	margin-left: 10px;
	}
	#cols .col #improved-formula em {
  	padding-right: 5px;
	}
}

.error404 #contentwrapper #search .btn,
.error404 #contentwrapper #search .btnShow {
  background: url("img/globals/buttons_r-l.png") 0 -64px no-repeat;
}

#claim {
  .logo {
    float: left;
  }
}