#compare {
  background: url('../hammerite/img/bg/bg-compare.png') 0 0 repeat-x;
  padding: 30px 0 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .wrapper {
    div {
      width: 270px;
      margin-right: 20px;
      float: left;
      height: 88px;
      position: relative;
      figure {
        width: 68px;
        height: 80px;
        border: 1px solid #ece8de;
        float: left;
        margin-right: 20px;
        padding: 3px;
        background: #f6f4ee;
        img { height: 80px; }
      }
      a {
        display: block;
        padding: 0 0 6px;
        font-weight: bold;
      }
      span { display: block; }
      &.empty {
        border: 1px solid #ece8de;
        background: #f6f4ee;
        span {
          display: block;
          text-align: center;
          font-style: italic;
          padding-top: 36px;
          color: #969794;
        }
      }
      .rating {
        width: 160px;
      }
      .delete {
        margin: 10px 0 0;
        padding: 4px 10px 4px 18px;
        background: url('../hammerite/img/globals/sprite.png') no-repeat -490px -755px transparent;
      }
    }
    .cta {
      position: absolute;
      top: 30px;
      right: 0;
      height: 28px;
      &.inactive {
        background: url('../hammerite/img/globals/sprite.png') 100% -1100px no-repeat;
        border: 1px solid #bbb;
        height: 28px;
        color: #fff;
        font-style: italic;
        box-shadow: 0 !important;
        &:hover, &:visited:hover {
          background: url('../hammerite/img/globals/sprite.png') 100% -1100px no-repeat;
          border: 1px solid #bbb;
          height: 28px;
          color: #fff;
          font-style: italic;
          box-shadow: 0 !important;
        }
      }
    }
  }
}