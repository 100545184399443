.steps {
  width: 430px;
  float: left;
  padding: 20px 0 0 !important;
}

.step {
  padding: 15px 10px 0 49px;
  border-top: 1px solid #ece8de;
  position: relative;
  display: block;
  overflow: hidden;
  clear: both;
  .text {
    display: block;
    margin-bottom: 1em;
  }
  em {
    @include border-radius(100px);
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-weight: bold;
    color: #fff;
    font-size: 1.2em;
    text-align: center;
    background: #013f68;
    border: 0;
    float: left;
    position: absolute;
    left: 0;
    top: 8px;
  }
  &.caution em {
    background: #ece8de url('../hammerite/img/globals/sprite.png') -955px -852px no-repeat;
    text-indent: -9999em;
    font-size: 0em;
  }
  &.tip em {
    background: #ece8de url('../hammerite/img/globals/sprite.png') -914px -882px no-repeat;
    text-indent: -9999em;
    font-size: 0em;
  }
}

.split { 
  float: left;
  display: block;
  width: 430px;
  padding-right: 30px;
  figure { 
    border: 1px solid #e1e1e1;
    display: inline-block; 
    margin-bottom: 20px;
    span { 
      width: 300px; 
      height: 169px;
      display: block; 
      overflow: hidden;
      margin: 5px;
    }
  }
}

.delete {
  background: url('../hammerite/img/globals/sprite.png') -486px -755px no-repeat;
  display: block;
  padding: 4px 10px 4px 24px;
  float: left;
  text-decoration: none;
  color: #f54c4c;
  margin: 4px 0 0 10px;
  &:hover,
  &:visited:hover {
    text-decoration: underline;
    color: #cc0000;
  }
}

.tooltip {
  display: block;
  height: 18px;
  width: 18px;
  background: url('../hammerite/img/globals/sprite.png') -71px -43px no-repeat;
  text-indent: -9999em;
  font-size: 0em;
  float: left;
}

p.clear {
  clear: both;
  padding: 10px 0;
}

a.clear {
  clear: both;
  margin-top: 20px;
}

#content span {
  color: #373a3b;
}

.nobg { background: none; }

.noborder { border: 0; }

.req { color: #029fd3; }

.note {
  display: block;
  padding: 10px 15px 10px 44px;
  background: #fcebb5 url('../hammerite/img/globals/sprite.png') -950px -849px no-repeat;
  clear: both;
  border: 1px solid #f5da96;
  color: #5c5031 !important;
  margin-bottom: 20px;
}

.usplist.float {
  width: 340px;
  float: left;
}

a.icn-delete {
  display: block;
  float: right;
  width: 13px;
  height: 13px;
  font-size: 0em;
  margin-left: 6px;
  text-indent: -999em;
  background: url('../hammerite/img/globals/sprite.png') -888px -358px no-repeat;
}

p.light {
  color: #999;
  font-style: italic;
}

#breadcrumbs {
  border-bottom: 1px solid #e5ebf0;
  padding: 10px 10px 10px 20px;
  li,
  span span {
    font-size: 0.95em;
    margin-right: 12px;
    font-weight: bold;
    color: #666;
    a {
      color: #666;
      display: block;
      float: left;
      text-decoration: none;
      padding-right: 14px;
      background: url('../hammerite/img/globals/sprite.png') 100% -507px no-repeat;
      font-weight: normal;
    }
  }
  li {
    float: left;
    font-size: 0.9em;
  }
  span span a {
    margin-right: 14px;
  }
}

.paging {
  ul { clear: both; }
  li {
    float: left;
    margin-right: 6px;
    a {
      display: block;
      float: left;
      padding: 6px 10px;
      border-radius: 3px;
      background: #f0f4f5;
      border: 1px solid #f0f4f5;
      text-decoration: none;
      &:hover,
      &:visited:hover {
        border: 1px solid #0396cd;
        text-decoration: none;
        color: #1186c3;
        background: #fff;
      }
    }
  }
  .inactive a {
    color: #bbb;
    &:hover,
    &:visited:hover {
      cursor: default;
      text-decoration: none;
      border: 1px solid #f0f4f5;
      text-decoration: none;
      color: #bbb;
      background: #f0f4f5;
    }
  }
  .current a {
    background: url('../hammerite/img/globals/sprite.png') -490px -318px no-repeat;
    border: 1px solid #0396cd;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    &:hover,
    &:visited:hover {
      background-position: -490px -318px;
      border: 1px solid #0396cd;
      font-weight: bold;
      text-decoration: none;
      color: #fff;
    }
  }
}

.extra {
  padding: 20px 160px 5px 20px;
  background: #f6f4ee;
  position: relative;
  .delete {
    margin: 0;
    position: absolute;
    top: 36px;
    right: 20px;
    padding-top: 5px;
  }
  em {
    display: block;
    padding-bottom: 6px;
    font-weight: bold;
    font-style: normal;
  }
  ul {
    padding-bottom: 15px;
    li {
      padding: 0 8px 0 0;
      background: url('../hammerite/img/bg/sep-dotted-y.png') right 1px repeat-y;
      float: left;
      margin-right: 8px;
      a { margin-top: 1px; }
    }
  }
}

#ops {
  border: 1px solid #ece8de;
  padding: 0;
  margin-bottom: 20px;
  background: #f6f4ee;
  li {
    float: left;
    padding-right: 1px;
    background: url('../hammerite/img/bg/sep-dotted-y.png') top right repeat-y;
    a {
      display: block;
      float: left;
      padding: 15px 20px 15px 46px;
      background: url('../hammerite/img/globals/sprite.png') -438px -830px no-repeat;
      text-decoration: none;
    }
  }
  .email a { background: url('../hammerite/img/globals/sprite.png') -453px -787px no-repeat; }
  li a {
    &:hover, &:visited:hover {
      background-color: #fff;
      text-decoration: underline;
    }
  }
}

aside.inzet {
  background: url('../hammerite/img/bg/noise-lightblue.jpg');
  padding: 15px;
  width: 200px;
  float: right;
  margin: 20px 0 20px 20px;
}

.error {
  border: 1px solid #f30;
  padding: 10px;
  margin-bottom: 10px;
  label { width: 140px; }
  em {
    padding: 5px 0 0 140px;
    font-weight: bold;
    color: #f30;
  }
}

.img-confirm {
  border: 1px solid #e1e1e1;
  display: inline-block;
  margin-bottom: 10px;
  span {
    width: 150px;
    height: 90px;
    overflow: hidden;
    display: block;
    margin: 5px;
  }
}