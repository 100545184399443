#sidebarxl {
	width: 240px;
	float: left;
	margin: 10px 20px 0 0;
	padding: 0;
}

#contentxl {
	width: 680px;
	padding: 0;
	margin: 10px 0 0 0;
	float: left;
	h1 {
		margin-bottom: 0;
	}
}

.productimg {
	width: 232px;
	border: 1px solid #ece8de;
	background: #f6f4ee;
	height: 252px;
	padding: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}

#contentxl {
	figure {
		border: 1px solid #e1e1e1;
		display: inline-block;
		margin-bottom: 20px;
		span {
			width: 300px;
			height: 169px;
			display: block;
			overflow: hidden;
			margin: 5px;
		}
		&.right {
			float: right;
			margin: 0 0 20px 20px;
		}
	}
	hgroup h1 {
		margin-bottom: 10px;
	}

	&.howto ul {
		margin: 0 0 1em 30px;
		list-style: disc;
	}
	&.howto ol {
		margin: 0 0 1em 30px;
		list-style: decimal;
	}
}

hgroup h1 {
	margin-bottom: 10px;
}

hgroup h2,
#contentxl hgroup h2 {
	font-family: $font-family;
	font-size: 1.6em;
	font-weight: normal;
	color: #373a3b;
	letter-spacing: 0em;
}

#contentxl {
	h3 {
		font-family: $font-family;
		letter-spacing: 0;
		margin: 30px 0 10px 0;
		color: #373a3b;
		font-weight: bold;
		font-size: 1.3em;
	}
	//article { clear: both; }
}

#procs {
	border-bottom: 1px solid #ece8de;
	padding: 0 0 20px 0;
	margin-bottom: 20px;
	li {
		padding: 0 20px 0 0;
		margin-right: 20px;
		font-weight: bold;
		color: #003e67;
		float: left;
		background: url('../hammerite/img/bg/sep-dotted-y.png') 100% 0 repeat-y;
		em {
			font-weight: normal;
			font-style: normal;
			display: block;
			color: #9b9c9d;
			padding-top: 4px;
		}
	}
}

table.features {
	color: #373a3b;
	tr {
		border-bottom: 1px dotted #ededed;

		td:first-of-type {
			width: 320px;
		}

		td:nth-of-type(2) {
			font-weight: 700;
		}
	}
	td {
		padding: 10px 0;

		span {
			display: block;
			padding-right: 30px;
			float: left;
			line-height: 18px;

			em {
				float: right;
				margin: 0 0 0 6px;
				padding: 0;
			}
		}
	}
}

#download {
	border-top: 1px solid #ece8de;
	padding: 10px 0;
	clear: both;
	li a {
		display: block;
		padding: 6px 10px 6px 24px;
	}
}

.pdf a {
	background: url('../hammerite/img/globals/sprite.png') -964px -725px no-repeat;
}

#content #ctablock {
	padding: 9px;
	background: #f6f4ee;
	border: 1px solid #ece8de;
	clear: both;
	margin-bottom: 20px;
	em {
		display: block;
		height: 27px;
		width: 27px;
		border-radius: 30px;
		background: #ece8de;
		float: left;
		text-align: center;
		color: #333;
		line-height: 27px;
		margin: 3px 15px 0 15px;
		font-weight: normal;
		font-style: normal;
	}
	.btnOrange {
		padding-top: 0;
	}
	a {
		display: block;
		float: left;
		padding-top: 9px;
	}
	span {
		float: right;
		font-style: italic;
		color: #969794;
		display: block;
		padding: 9px 6px 0 0;
	}
}

#related {
	padding: 15px 20px 20px;
	background: #f6f4ee;
	border: 1px solid #ece8de;
	clear: both;
	h3 {
		color: #373a3b;
	}
	figure {
		float: left;
		width: 178px;
		height: 178px;
		background: #fff;
		border: 1px solid #ece8de;
		margin-right: 10px;
		padding: 10px 0;
		text-align: center;
	}
}

#contentxl #askexpert {
	float: left;
	width: 250px;
	position: relative;
	figure {
		float: right;
		position: relative;
		width: 105px;
		height: 145px;
		padding: 5px;
		border: 0;
		overflow: hidden;
		span {
			position: absolute;
			top: 0;
			left: 0;
			width: 115px;
			height: 151px;
			background: url('../hammerite/img/globals/sprite.png') -375px -569px no-repeat;
			text-indent: -9999em;
			font-size: 0;
		}
	}
}