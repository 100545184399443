.frm-finder {
  button { margin: 0 }
  input { 
    padding: 7px 5px;
    margin-right: 10px;
  }
}

body {
  &.page {
    #contentxl,
    #content {
      .gform_wrapper {
        .gform_fields {
          .gfield {
            list-style-type: none;
          }
        }
      }
    }
  }
}
