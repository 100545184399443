// =========== Mixins 

@mixin animation ($name: true, $duration: true, $timing_function: true, $delay: false, $iteration_count: false, $direction: false) {
    @if $name { -webkit-animation-name: $name; -moz-animation-name: $name; -ms-animation-name: $name; }
    @if $duration { -webkit-animation-duration: $duration; -moz-animation-duration: $duration; -ms-animation-duration: $duration; }
    @if $timing_function { -webkit-animation-timing-function: $timing_function; -moz-animation-timing-function: $timing_function;  -ms-animation-timing-function: $timing_function; }
    @if $delay { -webkit-animation-delay: $delay; -moz-animation-delay: $delay; -ms-animation-delay: $delay; }
    @if $iteration_count { -webkit-animation-iteration-count: $iteration_count; -moz-animation-iteration-count: $iteration_count; -ms-animation-iteration-count: $iteration_count; }
    @if $direction { -webkit-animation-direction: $direction; -moz-animation-direction: $direction; -ms-animation-direction: $direction; }
}

@mixin background-clip($clip) {
    -webkit-background-clip: $clip;
    -moz-background-clip: $clip;
    -o-background-clip: $clip;
    background-clip: $clip;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -o-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin box-sizing($box) {
    -webkit-box-sizing: $box;
    -moz-box-sizing: $box;
    -ms-box-sizing: $box;
    -o-box-sizing: $box;
    box-sizing: $box;
}

@mixin filter($filter) {
    -webkit-filter: $filter;
    -moz-filter: $filter;
    -o-filter: $filter;
    filter: $filter;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin columns($count, $gap) {
    -webkit-column-count: $count;
    -webkit-column-gap : $gap;
    -moz-column-count: $count;
    -moz-column-gap: $gap;
    -o-column-count: $count;
    -o-column-gap: $gap;
    column-count: $count;
    column-gap: $gap;
}

@mixin columns-rule($size, $style, $color) {
    -webkit-column-rule-width: $size;
    -webkit-column-rule-style: $style;
    -webkit-column-rule-color: $color;
    -moz-column-rule-width: $size;
    -moz-column-rule-style: $style;
    -moz-column-rule-color: $color;
    -o-column-rule-width: $size;
    -o-column-rule-style: $style;
    -o-column-rule-color: $color;
    column-rule-width: $size;
    column-rule-style: $style;
    column-rule-color: $color;
}

@mixin opacity($opacity) {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})";
	opacity: $opacity;
}

@mixin gradient($side, $start, $end) {
    background-color: $end;
    background-image: -webkit-linear-gradient($side, $start, $end); 
    background-image: -moz-linear-gradient($side, $start, $end); 
    background-image: -ms-linear-gradient($side, $start, $end);
    background-image: -o-linear-gradient($side, $start, $end);
    background-image: linear-gradient($side, $start, $end);
}

@mixin gradient-ie($start, $end) {
    *zoom: 1;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start}', endColorstr='#{$end}');
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start}', endColorstr='#{$end}');
}