ol {
  padding: 5px 0 1em 0;
  border-top: 1px solid #ece8de;
  border-bottom: 1px solid #ece8de;
  margin-bottom: 20px;
  li {
    padding-left: 20px;
    padding: 15px 0 0 20px;
    margin-left: 40px;
    p {
      font-size: 0.75em;
      font-family: Arial;
      color: #373a3b;
    }
  }
}