#content {
  width: 940px;
  padding: 10px 20px 20px 20px;
  position: relative;
  .intro { padding: 0 0 20px 0; }
  #prodwrapper {
    float: left;
    width: 480px;
    padding: 0 20px 20px 20px;
  }
  #refined {
    padding: 12px;
    background: url('../hammerite/img/bg/noise-lightblue.jpg');
    border-radius: 3px;
    border: 0;
    margin-bottom: 20px;
    li {
      float: left;
      margin-left: 6px;
      padding-left: 6px;
      border-left: 1px solid #ccc;
      color: #373a3b;
    }
    .first {
      font-weight: bold;
      color: #003e67;
      border: 0;
      margin: 0;
      padding: 0;
    }
    a {
      display: block;
      float: left;
      width: 13px;
      height: 13px;
      font-size: 0em;
      margin-left: 6px;
      text-indent: -999em;
      background: url('../hammerite/img/globals/sprite.png') -903px -358px no-repeat;
      &:hover {
        background-position: -888px -358px;
      }
    }
    span { float: left; }
  }
  #sidebar {
    float: left;
    width: 200px;
    background: url('../hammerite/img/bg/sep-dotted-y.png') top left repeat-y;
    padding: 0 0 20px 20px;
    p.testimonial {
      width: 160px;
    }
    span.testimonial {
      display: block;
      padding: 4px 0 10px 20px;
      font-style: italic;
      color: #898f92;
    }
  }
}

.product {
  padding-bottom: 20px;
  border-bottom: 1px solid #ece8de;
  margin-bottom: 20px;
  clear: both;
  div {
    float: left;
    width: 310px;
    p {
      clear: both;
      padding: 10px 0;
    }
  }
  &__left {
    width: 120px !important;
    float: left;
    margin-right: 20px;
    padding: 3px;

    figure {
      height: 140px;
      text-align: center;
      width: auto;
      float: none;
      margin: 0;
      padding: 0;
    }
  }
  figure {
    width: 120px;
    height: 140px;
    float: left;
    margin-right: 20px;
    padding: 3px;
    text-align: center;
  }
  h2 {
    font-family: $font-family;
    letter-spacing: 0;
    padding: 0;
    margin: 0 0 4px 0;
    font-size: 1.6em;
    a {
      text-decoration: none;
      &:hover { text-decoration: underline; }
    }
  }
  .amount {
    float: right;
    background: #f6f4ee;
    padding: 15px;
    width: auto;
    border: 1px solid #ece8de;
    border-radius: 4px;
    margin-top: 40px;
    input {
      width: 30px;
      border-radius: 2px;
      padding: 5px;
      font-size: 1.2em;
      color: #333;
      margin-right: 10px;
      font-family: $font-family;
      border: 1px solid #d4d1ca;
      float: left;
    }
    a {
      &.plus {
        display: block;
        float: left;
        height: 31px;
        width: 30px;
        text-indent: -9999em;
        background: url('../hammerite/img/globals/sprite.png') -489px -346px no-repeat;
        margin-right: 10px;
        &:hover,
        &:visited:hover {
          background-position: -519px -346px;
        }
      }
      &.minus {
        display: block;
        float: left;
        height: 31px;
        width: 30px;
        text-indent: -9999em;
        background: url('../hammerite/img/globals/sprite.png') -549px -346px no-repeat;
        margin-right: 10px;
        &:hover,
        &:visited:hover {
          background-position: -579px -346px;
        }
      }
    }
  }
}

.product {
  .btnOrange { padding: 0 20px 0 10px; }
  a.btnCompare { padding: 0 10px; }
}

#content #refine {
  width: 200px;
  background: url('../hammerite/img/bg/noise-lightblue.jpg');
  border-radius: 3px;
  border: 0;
  float: left;
  h2 {
    font-family: $font-family;
    font-size: 1.2em;
    font-weight: bold;
    display: block;
    padding: 10px 10px 10px 15px;
    border-bottom: 1px solid #dce0e1;
    margin: 0;
  }
  section {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #dce0e1;
    a {
      padding: 10px 10px 10px 30px;
      display: block;
      clear: both;
      color: #373a3b;
      font-weight: bold;
      text-decoration: none;
      background: url('../hammerite/img/globals/sprite.png') -909px -448px no-repeat;
      position: relative;
      &:hover,
      &:visited:hover {
        color: #1186c3;
        text-decoration: underline;
        background-position: -898px -468px;
      }
      &.fold {
        background: url('../hammerite/img/globals/sprite.png') -658px -449px no-repeat;
        &:hover,
        &:visited:hover {
          background-position: -648px -479px;
          color: #1186c3;
          text-decoration: underline;
        }
      }
    }
    ul li {
      padding: 0 10px 10px;
      overflow: hidden;
      a {
        padding: 0;
        background: none;
        font-weight: 400;
        &:hover {
          padding: 0;
          background: none;
          font-weight: 400;
        }
      }
      label {
        color: #373a3b;
        padding-top: 2px;
        display: block;
        float: left;
        width: 130px;
      }
      input {
        float: left;
        margin: 2px 8px 0 0;
      }
      em {
        display: block;
        float: right;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: #d3dee4;
        border-radius: 30px;
        border: 0;
        color: #4e7183;
        font-style: normal;
        font-weight: normal;
        font-size: 0.9em;
        box-shadow: 0 1px 0 #fff;
      }
    }
  }
  .color span {
    display: block;
    font-size: 0em;
    text-indent: -9999em;
    width: 18px;
    height: 19px;
    background: url('../hammerite/img/globals/sprite.png') -926px -355px no-repeat;
    float: left;
    margin-right: 6px;
  }
  .tooltip {
    position: absolute;
    right: 10px;
    top: 8px;
  }
  .showmore a {
    background: none;
    margin: 0;
    padding: 0;
    color: #1186c3;
    text-decoration: underline;
    font-weight: normal;
    padding: 4px 0;
    &:hover {
      background: none;
      text-decoration: underline;
      color: #21cbf5;
    }
  }
  .noborder { border-bottom: 0; }
}