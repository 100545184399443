#share {
  li {
    border-bottom: 1px solid #f6f4ee;
    position: relative;
    a {
      display: block;
      padding: 9px 10px 9px 0;
      text-decoration: none;
      &:hover,
      &:visited:hover {
        text-decoration: underline;
      }
    }
  }
  .print a {
    background: url('../hammerite/img/globals/sprite.png') -906px -799px no-repeat;
    padding-left: 24px;
  }
  .email {
    a {
      background: url('../hammerite/img/globals/sprite.png') -890px -828px no-repeat;
      padding-left: 24px;
    }
    form {
      display: none;
      width: 220px;
      background: url('../hammerite/img/bg/popup.png') 0 0;
      padding: 10px 10px 18px 10px;
      position: absolute;
      color: #fff;
      bottom: -14px;
      left: 0px;
      label {
        display: block;
        padding-bottom: 10px;
      }
      input {
        padding: 5px;
        border: 1px solid #fff;
        width: 160px;
        float: left;
        margin-right: 10px;
      }
      .btnOrange {
        width: auto;
        border: 0;
        height: 26px;
        padding: 5px 6px 8px;
        float: left;
        background: url('../hammerite/img/globals/sprite.png') no-repeat scroll -700px -656px transparent;
        font-family: $font-family;
        font-size: 1.1em;
        margin: 0;
        line-height: 18px;
        &:hover {
          text-decoration: underline;
          box-shadow: none;
          color: #fff;
        }
      }
      div {
        position: relative;
        a {
          display: block;
          width: 25px;
          height: 25px;
          text-indent: -9999em;
          font-size: 0em;
          position: absolute;
          right: -20px;
          top: -20px;
          padding: 0;
          margin: 0;
          background: url('../hammerite/img/globals/sprite.png') no-repeat scroll -791px -366px;
          &:hover, 
          &:visited:hover {
            background: url('../hammerite/img/globals/sprite.png') no-repeat scroll -821px -366px;
          }
        }
      }
    }
  }
}