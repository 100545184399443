/*!
Theme Name: Hammerite Right-to-Left
Description: RTL theme for Hammerite websites
Author: Concept7 & Uprise
Template: hammerite
*/
* {
  margin: 0;
  padding: 0; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

header, nav, section, footer, figure, aside {
  display: block; }

body {
  line-height: 1; }

@font-face {
  font-family: 'helvetica';
  src: url("fonts/helvetica.eot");
  src: url("fonts/helvetica.eot?#iefix") format("embedded-opentype"), url("fonts/helvetica.woff") format("woff"), url("fonts/helvetica.ttf") format("truetype"), url("fonts/helvetica.svg#StagLight") format("svg");
  font-weight: normal;
  font-style: normal; }

h1, h2, h3, h4 {
  font-family: "helvetica", Arial, sans-serif;
  color: #003e67;
  font-weight: normal;
  margin-bottom: 10px; }

h1 {
  font-size: 3em; }

h2 {
  font-size: 2em; }

h3 {
  font-size: 1.6em; }

h4 {
  font-size: 1.2em;
  letter-spacing: normal; }

ul {
  list-style: none; }

img {
  vertical-align: bottom; }

a {
  color: #1186c3;
  text-decoration: underline; }

a:hover,
a:visited:hover {
  color: #21cbf5;
  text-decoration: underline;
  cursor: pointer; }

p {
  line-height: 1.6em;
  padding: 0 0 15px 0;
  font-size: 1em;
  color: #373a3b; }

blockquote,
q {
  quotes: none; }

:focus {
  outline: 0; }

ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Chrome */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type=search] {
    -webkit-appearance: textfield; }
  header #search input {
    width: 300px;
    padding-left: 0; } }

body {
  background: url("../hammerite/img/bg/body.jpg") 50% 0 no-repeat;
  font: normal 12px Arial, sans-serif;
  width: 100%;
  color: #373a3b; }

.inline li,
.inline dd,
.inline dt {
  display: inline; }

.hidden {
  display: none; }

a.cta {
  border-radius: 5px;
  display: block;
  border: 1px solid #02395e;
  background: url("../hammerite/img/globals/sprite.png") 100% -230px no-repeat;
  height: 30px;
  padding: 0 26px 0 16px;
  line-height: 30px;
  font-size: 1em;
  color: #c1dbe3;
  float: left;
  text-decoration: none; }
  a.cta:hover, a.cta:visited:hover {
    background: url("../hammerite/img/globals/sprite.png") 100% -260px no-repeat;
    color: #fff;
    box-shadow: 0 1px 2px #8c9ba2;
    cursor: pointer; }

a.bull {
  display: block;
  float: left;
  padding: 4px 0 4px 14px;
  background: url("../hammerite/img/globals/sprite.png") -975px -353px no-repeat; }

.btnOrange {
  border-radius: 4px;
  display: block;
  float: left;
  padding: 0 26px 0 16px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  text-shadow: 0 1px 0 #f15c03;
  background: url("../hammerite/img/globals/sprite.png") 100% -656px no-repeat;
  border: 1px solid #f15c03; }
  .btnOrange:hover {
    background: url("../hammerite/img/globals/sprite.png") 100% -688px no-repeat;
    cursor: pointer;
    box-shadow: 0 1px 2px #bbb;
    color: #fff !important; }

a.btnCompare {
  display: block;
  float: left;
  padding: 0 20px 0;
  height: 32px;
  line-height: 32px;
  text-decoration: none;
  background: none;
  color: #86a8c3;
  border: 2px solid #e9eef2;
  border-radius: 4px;
  margin-top: 10px; }
  a.btnCompare:hover {
    color: #86a8c3; }
  a.btnCompare i {
    margin-right: 4px; }

ul.list {
  padding: 5px 0 15px 0; }
  ul.list li {
    font-size: 1em;
    padding: 4px 0 4px 16px;
    color: #373a3b;
    background: url("../hammerite/img/globals/sprite.png") -937px -395px no-repeat; }

body.page #contentxl ul {
  padding: 5px 0 15px 0;
  margin-left: 30px;
  margin-bottom: 1em;
  background: none; }
  body.page #contentxl ul li {
    margin-bottom: 5px;
    list-style-type: disc; }
  body.page #contentxl ul ul {
    padding-bottom: 0;
    margin-left: 15px; }
    body.page #contentxl ul ul li {
      background: none;
      padding-left: 0;
      list-style-type: none; }
      body.page #contentxl ul ul li:before {
        content: ' - ';
        margin-right: 5px; }

#contentwrapper {
  background: url("../hammerite/img/bg/bg-content.png") center 0 no-repeat;
  z-index: 1; }
  #contentwrapper .wrapper {
    background: #fff;
    border-top: 4px solid #ece8de; }

.alignright {
  float: right;
  margin: 0 0 1em 15px; }

.alignleft {
  float: left;
  margin: 0 15px 1em 0; }

/* The clearfix mixin is necessary for the floats */
.clear {
  *zoom: 1; }
  .clear:before, .clear:after {
    display: block;
    height: 0 !important;
    line-height: 0;
    overflow: hidden;
    visibility: hidden;
    content: "."; }
  .clear:after {
    clear: both; }

.wrapper {
  display: block;
  width: 980px;
  margin: 0 auto;
  clear: both;
  position: relative; }

header {
  z-index: 999; }

#topbar {
  height: 45px;
  background: url("../hammerite/img/bg/bg-topbar.png") 0 0 repeat-x; }
  #topbar .wrapper {
    height: 45px; }
    #topbar .wrapper .akzonobel {
      float: right;
      padding-top: 14px;
      margin-left: 20px; }
    #topbar .wrapper ul {
      float: right;
      height: 45px;
      display: block; }
      #topbar .wrapper ul li {
        position: relative;
        float: left;
        padding-right: 1px;
        background: url("../hammerite/img/globals/sprite.png") 100% 11px no-repeat; }
        #topbar .wrapper ul li a {
          display: block;
          float: left;
          padding: 0 14px;
          line-height: 42px;
          color: #8bafca;
          text-decoration: none; }
          #topbar .wrapper ul li a:hover, #topbar .wrapper ul li a:visited:hover {
            color: #e1d7c0;
            text-decoration: none;
            border-bottom: 1px solid #e1d7c0;
            padding-bottom: 2px; }
      #topbar .wrapper ul .parent a,
      #topbar .wrapper ul .lang a {
        padding-right: 40px;
        background: url("../hammerite/img/globals/sprite.png") 100% -35px no-repeat; }
      #topbar .wrapper ul .parent .selected {
        padding-bottom: 0;
        border-bottom: 5px solid #fff;
        color: #003e67;
        background: url("../hammerite/img/globals/sprite.png") 100% -546px no-repeat;
        background-color: #fff;
        height: 39px; }
        #topbar .wrapper ul .parent .selected:hover, #topbar .wrapper ul .parent .selected:visited:hover {
          padding-bottom: 0;
          border-bottom: 5px solid #fff;
          color: #003e67;
          background: url("../hammerite/img/globals/sprite.png") 100% -546px no-repeat;
          background-color: #fff;
          height: 39px; }
      #topbar .wrapper ul .lang .selected {
        padding-bottom: 0;
        border-bottom: 5px solid #fff;
        color: #003e67;
        background: url("../hammerite/img/globals/sprite.png") 100% -546px no-repeat;
        background-color: #fff;
        height: 39px; }
        #topbar .wrapper ul .lang .selected:hover, #topbar .wrapper ul .lang .selected:visited:hover {
          padding-bottom: 0;
          border-bottom: 5px solid #fff;
          color: #003e67;
          background: url("../hammerite/img/globals/sprite.png") 100% -546px no-repeat;
          background-color: #fff;
          height: 39px; }
      #topbar .wrapper ul .sub-menu {
        display: none;
        position: absolute;
        width: 200px;
        box-shadow: 0 4px 4px #002c4d;
        top: 44px;
        left: 0;
        z-index: 999;
        background: #fff;
        height: auto;
        border-top: 1px solid #e5e9ec; }
        #topbar .wrapper ul .sub-menu li {
          border-bottom: 1px solid #e5e9ec;
          padding: 0;
          margin: 0;
          height: auto;
          position: relative; }
          #topbar .wrapper ul .sub-menu li a {
            width: 172px;
            padding: 0 14px;
            line-height: 32px;
            background-image: none;
            color: #003e67; }
            #topbar .wrapper ul .sub-menu li a:hover, #topbar .wrapper ul .sub-menu li a:visited:hover {
              border-bottom: 1px solid #1186c3;
              color: #1186c3; }
          #topbar .wrapper ul .sub-menu li.de a, #topbar .wrapper ul .sub-menu li.ee a, #topbar .wrapper ul .sub-menu li.lt a, #topbar .wrapper ul .sub-menu li.lv a, #topbar .wrapper ul .sub-menu li.ru a, #topbar .wrapper ul .sub-menu li.uk a, #topbar .wrapper ul .sub-menu li.nl a, #topbar .wrapper ul .sub-menu li.fr a {
            background: 14px 11px no-repeat;
            padding-left: 40px; }
          #topbar .wrapper ul .sub-menu li.de a {
            background-image: url("../hammerite/img/flags/de.png"); }
          #topbar .wrapper ul .sub-menu li.ee a {
            background-image: url("../hammerite/img/flags/ee.png"); }
          #topbar .wrapper ul .sub-menu li.lt a {
            background-image: url("../hammerite/img/flags/ly.png"); }
          #topbar .wrapper ul .sub-menu li.lv a {
            background-image: url("../hammerite/img/flags/lv.png"); }
          #topbar .wrapper ul .sub-menu li.ru a {
            background-image: url("../hammerite/img/flags/ru.png"); }
          #topbar .wrapper ul .sub-menu li.uk a {
            background-image: url("../hammerite/img/flags/uk.png"); }
          #topbar .wrapper ul .sub-menu li.nl a {
            background-image: url("../hammerite/img/flags/nl.png"); }
          #topbar .wrapper ul .sub-menu li.fr a {
            background-image: url("../hammerite/img/flags/fr.png"); }

header #topbar .wrapper ul .lang img {
  float: left;
  margin: 15px 10px 0 0; }

header #topbar .wrapper ul .lang .sub-menu li img {
  margin: 10px 10px 0 0; }

header #topbar .wrapper ul .shoppinglist {
  background: none; }
  header #topbar .wrapper ul .shoppinglist a {
    padding-right: 34px; }
    header #topbar .wrapper ul .shoppinglist a span {
      display: block;
      background: url("../hammerite/img/globals/sprite.png") 0 -42px no-repeat;
      width: 23px;
      height: 23px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      line-height: 24px;
      position: absolute;
      top: 10px;
      right: 0; }
    header #topbar .wrapper ul .shoppinglist a:hover span {
      background: url("../hammerite/img/globals/sprite.png") 0 -72px no-repeat;
      color: #373a3b; }

#topbar .wrapper ul .shoppinglist a:visited:hover span {
  background: url("../hammerite/img/globals/sprite.png") 0 -72px no-repeat;
  color: #373a3b; }

header #topbar .wrapper ul .current a:hover, header #topbar .wrapper ul .current a:visited:hover {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
  padding-bottom: 2px; }

header .logo {
  display: block;
  position: absolute;
  top: -20px;
  left: 10px;
  width: 164px;
  height: 169px; }

header #search {
  float: right;
  position: relative;
  margin: 34px 0;
  z-index: 1; }
  header #search input {
    width: 252px;
    color: #8da5b9;
    font-family: Arial;
    font-size: 1.1em;
    background: url("../hammerite/img/globals/sprite.png") 0 0 no-repeat;
    height: 32px;
    border: 1px solid #6686a0;
    padding-left: 10px;
    padding-right: 38px; }
  header #search .btn {
    width: 32px;
    height: 32px;
    background: url("../hammerite/img/globals/sprite.png") -26px -37px no-repeat;
    position: absolute;
    top: 1px;
    right: 1px;
    text-indent: -9999em;
    font-size: 0em;
    border: 0;
    padding: 0; }
  header #search .btnShow {
    height: 28px;
    background: url("../hammerite/img/globals/sprite.png") 100% -600px no-repeat;
    position: absolute;
    top: 3px;
    right: 3px;
    font-weight: bold;
    color: #fff;
    width: auto;
    padding: 0 26px 2px 10px;
    border: 0;
    border-radius: 3px; }
    header #search .btnShow:hover {
      background-position: right -628px;
      cursor: pointer; }

.lt-ie8 header section form.focus .search-input {
  height: 22px !important;
  padding-top: 68px; }

.lt-ie8 header section form.no-focus .search-input {
  height: 22px !important;
  padding-top: 6px; }

header nav {
  clear: both;
  z-index: 2; }
  header nav ul {
    width: 800px;
    padding-left: 180px;
    height: 60px;
    background: url("../hammerite/img/globals/sprite.png") 0 -100px no-repeat;
    clear: both; }
    header nav ul li {
      float: left;
      height: 60px;
      padding-right: 1px;
      background: url("../hammerite/img/globals/sprite.png") 100% -162px no-repeat;
      position: relative;
      z-index: 99; }
      header nav ul li a {
        color: #c1dbe3;
        float: left;
        display: block;
        padding: 22px 24px 0 24px;
        height: 38px;
        font-size: 1.1em;
        letter-spacing: 0.02em;
        text-decoration: none;
        text-transform: uppercase;
        text-shadow: 0 1px 1px #000;
        font-family: "helvetica", Arial, sans-serif;
        font-weight: normal; }
        header nav ul li a:hover, header nav ul li a:visited:hover {
          color: #fff;
          text-decoration: none;
          border-bottom: 3px solid #ece8de;
          height: 35px; }
        .pl_pl header nav ul li a {
          font-size: 1em;
          padding-left: 20px;
          padding-right: 20px; }
        .de_de header nav ul li a {
          padding-left: 21px;
          padding-right: 21px; }
        .lt-ie9 header nav ul li a {
          font-family: Arial, sans-serif !important;
          font-weight: bold; }
    header nav ul .first a {
      padding-right: 25px;
      background: url("../hammerite/img/globals/sprite.png") -979px -162px no-repeat; }
    header nav ul .current-menu-item a {
      color: #fff;
      text-shadow: 0 1px 10px #fff; }
    header nav ul .parent a {
      background: url("../hammerite/img/globals/sprite.png") 100% -26px no-repeat;
      padding-right: 36px; }
    header nav ul li a:hover, header nav ul li a:visited:hover {
      background: #01203e; }
    header nav ul .parent a:hover, header nav ul .parent a:visited:hover {
      background: #01203e url("../hammerite/img/globals/sprite.png") 100% -26px no-repeat; }
    header nav ul .first a:hover, header nav ul .first a:visited:hover {
      background: #01203e url("../hammerite/img/globals/sprite.png") -979px -162px no-repeat; }
    header nav ul .hovered {
      background-color: #01203e !important;
      border-bottom: 3px solid #01203e !important; }
  header nav .sub-menu {
    display: none;
    width: 260px;
    position: absolute;
    top: 60px;
    left: 0;
    background: #01203e;
    z-index: 999;
    margin: 0;
    padding: 0;
    height: auto; }
    header nav .sub-menu li {
      margin: 0;
      padding: 0;
      height: auto;
      background-image: none; }
      header nav .sub-menu li a {
        font-family: Arial, sans-serif;
        font-size: 1em;
        font-weight: normal;
        text-transform: none;
        color: #c1dbe3;
        padding: 14px;
        border-bottom: 1px solid #002d4e;
        width: 232px;
        text-shadow: none;
        background-image: none;
        line-height: 1em;
        height: auto; }
        header nav .sub-menu li a:hover {
          font-family: Arial, sans-serif;
          font-size: 1em;
          font-weight: normal;
          text-transform: none;
          color: #c1dbe3;
          padding: 14px;
          border-bottom: 1px solid #002d4e;
          width: 232px;
          text-shadow: none;
          background-image: none;
          line-height: 1em;
          height: auto;
          color: #fff;
          background: #002d4e !important;
          border-bottom: 1px solid #07375a !important; }

#submenu {
  background: url("../hammerite/img/bg/noise-lightblue.jpg");
  padding: 0;
  border-radius: 4px;
  border: 0; }
  #submenu li a {
    line-height: 1.4em;
    padding: 10px 30px 10px 10px;
    display: block;
    clear: both;
    color: #373a3b;
    text-decoration: none;
    border-bottom: 1px solid #dce0e1;
    border-top: 1px solid #fff; }
    #submenu li a:hover, #submenu li a:visited:hover {
      background: white url("../hammerite/img/globals/sprite.png") 100% -1044px no-repeat;
      color: #1186c3;
      text-decoration: underline; }
  #submenu .first a {
    border-top: 0;
    border-radius: 4px; }
  #submenu .last a {
    border-bottom: 0; }
  #submenu .current a {
    font-weight: bold;
    background: url("../hammerite/img/globals/sprite.png") 100% -964px no-repeat;
    text-decoration: none;
    color: #373a3b; }
    #submenu .current a:hover, #submenu .current a:visited:hover {
      font-weight: bold;
      background: url("../hammerite/img/globals/sprite.png") 100% -964px no-repeat;
      text-decoration: none;
      color: #373a3b; }

.rating {
  display: block;
  width: 88px;
  height: 15px;
  float: left;
  margin-right: 10px;
  text-indent: -9999em;
  font-size: 0em;
  background: url("../hammerite/img/globals/sprite.png") no-repeat; }
  .rating.stars5 {
    background-position: -250px -230px; }
  .rating.stars4-5 {
    background-position: -250px -250px; }
  .rating.stars4 {
    background-position: -250px -270px; }
  .rating.stars3-5 {
    background-position: -250px -290px; }
  .rating.stars3 {
    background-position: -250px -310px; }
  .rating.stars2-5 {
    background-position: -250px -330px; }
  .rating.stars2 {
    background-position: -250px -350px; }
  .rating.stars1-5 {
    background-position: -250px -370px; }
  .rating.stars1 {
    background-position: -250px -390px; }
  .rating.stars0-5 {
    background-position: -250px -410px; }
  .rating.stars0 {
    background-position: -250px -430px; }

.ratinginfo {
  color: #929ca1 !important;
  display: block;
  float: left;
  padding-top: 2px; }

.review {
  padding: 0 0 10px !important;
  margin-bottom: 20px;
  border-bottom: 1px solid #ece8de; }
  .review h2 {
    float: left;
    font-family: Arial;
    font-size: 1.8em;
    margin: 0 15px 4px 0; }
  .review .rating {
    margin-top: 5px; }
  .review em {
    display: block;
    color: #969794;
    clear: both;
    font-weight: normal;
    font-style: normal; }
  .review p {
    padding-top: 10px; }
  .review ul {
    float: left;
    width: 330px;
    padding-bottom: 10px; }
  .review .cons {
    float: right; }
  .review .pros .title,
  .review .cons .title {
    padding: 0;
    background: none; }
  .review ul li {
    color: #373a3b;
    padding: 6px 10px 6px 20px; }
    .review ul li h3 {
      font-family: Arial, sans-serif;
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 5px; }
  .review .pros li,
  .review .cons li {
    background: url("../hammerite/img/globals/sprite.png") -507px -734px no-repeat; }
  .review .cons li {
    background-position: -490px -754px; }
  .review .meta {
    clear: both;
    padding: 10px;
    background: #f6f4ee;
    border: 1px solid #ece8de;
    color: #373a3b; }
    .review .meta span {
      float: right;
      color: #969794 !important; }
    .review .meta em {
      float: left;
      font-style: normal;
      font-weight: normal;
      margin-right: 20px; }
    .review .meta a {
      display: block;
      float: left;
      margin-right: 10px;
      padding-left: 24px;
      height: 16px; }
    .review .meta .yes {
      background: url("../hammerite/img/globals/sprite.png") -243px -43px no-repeat; }
    .review .meta .no {
      background: url("../hammerite/img/globals/sprite.png") -353px -45px no-repeat; }
  .review .helpful-yes .yes {
    font-weight: bold;
    color: #333;
    text-decoration: none;
    cursor: default; }
  .review .helpful-yes .no {
    color: #ccc;
    text-decoration: none;
    cursor: default;
    background: url("../hammerite/img/globals/sprite.png") -353px -75px no-repeat; }
  .review .helpful-no .no {
    font-weight: bold;
    color: #333;
    text-decoration: none;
    cursor: default; }
  .review .helpful-no .yes {
    color: #ccc;
    text-decoration: none;
    cursor: default;
    background: url("../hammerite/img/globals/sprite.png") -243px -73px no-repeat; }
  .review a.flag {
    display: block;
    float: left;
    padding: 10px 0 0 11px; }

.response {
  padding: 15px 20px 0 !important;
  background: url("../hammerite/img/bg/noise-lightblue.jpg");
  border: 1px solid #d3dee4;
  margin-bottom: 10px; }

#carousel {
  width: 660px;
  float: left;
  height: 240px;
  overflow: hidden;
  margin: 8px 0 10px 10px;
  position: relative; }
  #carousel #carouselwrapper {
    width: 1980px;
    height: 240px;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0; }
  #carousel section {
    width: 660px;
    height: 240px;
    float: left;
    position: absolute;
    top: 0;
    left: 0; }
    #carousel section > div {
      width: 620px;
      padding: 20px 20px 0;
      height: 220px; }
      #carousel section > div figure {
        float: right;
        margin: 0 20px; }
      #carousel section > div p {
        padding-top: 10px; }
  #carousel ul {
    padding: 5px 1px 5px 6px;
    background: #ece8de;
    border-radius: 30px;
    float: left;
    position: absolute;
    top: 14px;
    right: 30px; }
    #carousel ul li {
      float: left;
      margin-right: 4px; }
      #carousel ul li a {
        display: block;
        font-size: 0em;
        text-indent: -9999em;
        width: 10px;
        height: 10px;
        background: url("../hammerite/img/globals/sprite.png") -204px -47px no-repeat; }
      #carousel ul li.current a {
        background-position: -192px -47px; }

#single #carousel {
  height: 300px; }
  #single #carousel section,
  #single #carousel #carouselwrapper {
    height: 300px; }
  #single #carousel section div {
    height: 280px; }

#colors ul li {
  float: left;
  width: 64px;
  height: 63px;
  position: relative;
  margin-right: -15px; }
  #colors ul li span.holder {
    display: block;
    padding: 0;
    width: 64px;
    height: 63px;
    background: url("../hammerite/img/globals/sprite.png") -397px -161px no-repeat;
    font-size: 0;
    text-indent: -9999em;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }
  #colors ul li span.colorxl {
    display: block;
    padding: 0;
    width: 52px;
    height: 53px;
    position: absolute;
    left: 6px;
    top: 4px;
    z-index: 1;
    font-size: 0em;
    text-indent: -9999em; }

#colors em {
  font-weight: bold;
  font-style: normal;
  color: #003e67;
  display: block;
  padding: 10px 0 4px 0; }

#colors span {
  color: #999;
  display: block;
  padding: 6px 0 20px 0; }

#colors.gloss ul li span.holder {
  background: url("../hammerite/img/globals/sprite.png") -331px -161px no-repeat; }

#colors.forged ul li span.holder {
  background: url("../hammerite/img/globals/sprite.png") -463px -161px no-repeat; }

#colors.hammered ul li span.holder {
  background: url("../hammerite/img/globals/sprite.png") -529px -161px no-repeat; }

.black {
  background-color: #000 !important; }

.blue {
  background-color: #0c81c0 !important; }

.copper {
  background-color: #d1a353 !important; }

.dark-green {
  background-color: #055a0f !important; }

.yellow {
  background-color: #fab301 !important; }

.white {
  background-color: #fffff5 !important; }

.brown {
  background-color: #51392f !important; }

.silver {
  background-color: #b9b5b4 !important; }

.green {
  background-color: #007741 !important; }

.red {
  background-color: #d1030f !important; }

.gold {
  background-color: #cfb07a !important; }

.grey,
.gray {
  background-color: #6f6764 !important; }

.top-one {
  z-index: 9; }

.top-two {
  z-index: 8; }

.top-three {
  z-index: 7; }

.top-four {
  z-index: 6; }

.top-five {
  z-index: 5; }

.top-six {
  z-index: 4; }

.top-seven {
  z-index: 3; }

.top-eight {
  z-index: 2; }

.top-nine {
  z-index: 1; }

.srfc {
  display: block;
  width: 66px;
  height: 66px;
  text-indent: -9999em;
  font-size: 0em; }
  .srfc.rust {
    background: url("../hammerite/img/globals/sprite.png") 0 -160px no-repeat; }
  .srfc.alu {
    background: url("../hammerite/img/globals/sprite.png") -66px -160px no-repeat; }
  .srfc.copper {
    background: transparent url("../hammerite/img/globals/sprite.png") -132px -160px no-repeat !important; }
  .srfc.metal {
    background: url("../hammerite/img/globals/sprite.png") -198px -160px no-repeat; }
  .srfc.brass {
    background: url("../hammerite/img/globals/sprite.png") -264px -160px no-repeat; }

#slider {
  clear: both;
  width: 960px;
  height: 340px;
  overflow: hidden;
  position: relative; }
  #slider #sliderwrapper {
    width: 4800px;
    height: 340px;
    position: absolute;
    left: 0;
    top: 0; }
  #slider #controls {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 100; }
    #slider #controls li {
      float: left;
      margin-right: 6px; }
      #slider #controls li a {
        display: block;
        float: left;
        width: 14px;
        height: 14px;
        background: url("../hammerite/img/globals/sprite.png") -119px -45px no-repeat;
        font-size: 0em;
        text-indent: -9999em; }
        #slider #controls li a:hover, #slider #controls li a:visited:hover {
          background-position: -153px -45px; }
    #slider #controls .current a {
      background: url("../hammerite/img/globals/sprite.png") -99px -45px no-repeat; }
      #slider #controls .current a:hover, #slider #controls .current a:visited:hover {
        background-position: -99px -45px; }
    #slider #controls .pause a {
      background: url("../hammerite/img/globals/sprite.png") -137px -45px no-repeat; }
      #slider #controls .pause a:hover, #slider #controls .pause a:visited:hover {
        background-position: -137px -45px; }
    #slider #controls .play a {
      background: url("../hammerite/img/globals/sprite.png") -171px -45px no-repeat; }
      #slider #controls .play a:hover, #slider #controls .play a:visited:hover {
        background-position: -171px -45px; }
  #slider #prev {
    display: block;
    height: 80px;
    width: 80px;
    text-indent: -9999em;
    font-size: 0em;
    background: url("../hammerite/img/globals/sprite.png") 0 -230px no-repeat;
    position: absolute;
    top: 130px;
    left: 0;
    z-index: 100; }
    #slider #prev:hover, #slider #prev:visited:hover {
      background-position: -160px -230px;
      cursor: pointer; }
  #slider #next {
    display: block;
    height: 80px;
    width: 80px;
    text-indent: -9999em;
    font-size: 0em;
    background: url("../hammerite/img/globals/sprite.png") 0 -310px no-repeat;
    position: absolute;
    top: 130px;
    right: 0;
    z-index: 100; }
    #slider #next:hover, #slider #next:visited:hover {
      background-position: -160px -310px;
      cursor: pointer; }
  #slider #prev.disabled {
    background: url("../hammerite/img/globals/sprite.png") -80px -230px no-repeat;
    cursor: default; }
    #slider #prev.disabled:hover, #slider #prev.disabled:visited:hover {
      background-position: -80px -230px;
      cursor: default; }
  #slider #next.disabled {
    background: url("../hammerite/img/globals/sprite.png") -80px -310px no-repeat;
    cursor: default; }
    #slider #next.disabled:hover, #slider #next.disabled:visited:hover {
      background-position: -80px -310px;
      cursor: default; }
  #slider section {
    width: 960px;
    height: 340px;
    float: left; }
    #slider section figure {
      width: 604px;
      height: 340px;
      overflow: hidden;
      float: left; }
    #slider section div {
      float: right;
      width: 226px;
      height: 280px;
      background: #ece8de url("../hammerite/img/bg/noise-gold.png");
      padding: 30px 100px 30px 30px; }
      #slider section div h3,
      #slider section div p {
        color: #373a3b;
        text-shadow: 0 1px 0 #ebe8e1;
        margin-bottom: 10px; }

#share li {
  border-bottom: 1px solid #f6f4ee;
  position: relative; }
  #share li a {
    display: block;
    padding: 9px 10px 9px 0;
    text-decoration: none; }
    #share li a:hover, #share li a:visited:hover {
      text-decoration: underline; }

#share .print a {
  background: url("../hammerite/img/globals/sprite.png") -906px -799px no-repeat;
  padding-left: 24px; }

#share .email a {
  background: url("../hammerite/img/globals/sprite.png") -890px -828px no-repeat;
  padding-left: 24px; }

#share .email form {
  display: none;
  width: 220px;
  background: url("../hammerite/img/bg/popup.png") 0 0;
  padding: 10px 10px 18px 10px;
  position: absolute;
  color: #fff;
  bottom: -14px;
  left: 0px; }
  #share .email form label {
    display: block;
    padding-bottom: 10px; }
  #share .email form input {
    padding: 5px;
    border: 1px solid #fff;
    width: 160px;
    float: left;
    margin-right: 10px; }
  #share .email form .btnOrange {
    width: auto;
    border: 0;
    height: 26px;
    padding: 5px 6px 8px;
    float: left;
    background: url("../hammerite/img/globals/sprite.png") no-repeat scroll -700px -656px transparent;
    font-family: Arial, sans-serif;
    font-size: 1.1em;
    margin: 0;
    line-height: 18px; }
    #share .email form .btnOrange:hover {
      text-decoration: underline;
      box-shadow: none;
      color: #fff; }
  #share .email form div {
    position: relative; }
    #share .email form div a {
      display: block;
      width: 25px;
      height: 25px;
      text-indent: -9999em;
      font-size: 0em;
      position: absolute;
      right: -20px;
      top: -20px;
      padding: 0;
      margin: 0;
      background: url("../hammerite/img/globals/sprite.png") no-repeat scroll -791px -366px; }
      #share .email form div a:hover, #share .email form div a:visited:hover {
        background: url("../hammerite/img/globals/sprite.png") no-repeat scroll -821px -366px; }

#compare-table th {
  width: 242px;
  text-align: left;
  background: #fff;
  border-bottom: 1px solid #e3e3e3;
  vertical-align: top; }

#compare-table td {
  border-bottom: 1px solid #e3e3e3;
  vertical-align: top; }

#compare-table th.first {
  width: 244px; }

#compare-table th span.no-product {
  background: #fafafa; }

#compare-table td {
  padding: 11px 5px; }

#compare-table .td-title {
  font-weight: bold;
  padding: 11px 10px;
  background: url("../hammerite/img/globals/sprite.png") 0 -450px no-repeat; }

#compare-table td.pdf, #compare-table td.word, #compare-table td.zip, #compare-table td.time {
  background: url("../hammerite/img/globals/sprite.png") -284px -473px no-repeat;
  padding: 11px 0 11px 30px; }

#compare-table td.word {
  background-position: -284px -528px; }

#compare-table td.zip {
  background-position: -284px -584px; }

#compare-table td.time {
  background-position: -342px -430px; }

#compare-table .striped {
  background: #f0eeee; }

#compare-table th span {
  display: block;
  overflow: hidden;
  width: 230px;
  height: 128px;
  text-align: center;
  margin-left: 5px;
  border: 1px solid #e3e3e3; }

.cookie-types-table tr td:first-of-type {
  white-space: nowrap; }

.cookie-types-table td {
  padding: 10px; }

.tab-nav {
  background: #f6f4ee;
  padding: 4px 2px 0 !important;
  overflow: hidden;
  clear: both;
  background: #f6f4ee url("../hammerite/img/bg/bg-tabs.png") left bottom repeat-x;
  border-left: 1px solid #ece8de;
  border-top: 1px solid #ece8de;
  border-right: 1px solid #ece8de; }
  .tab-nav li {
    float: left;
    padding: 0 !important;
    position: relative; }
    .tab-nav li a {
      display: block;
      float: left;
      background: #fff;
      border: 1px solid #f6f4ee;
      padding: 14px 18px;
      color: #9b9c9d;
      text-decoration: none;
      margin-right: 4px;
      border-left: 1px solid #ece8de;
      border-top: 1px solid #ece8de;
      border-right: 1px solid #ece8de; }
      .tab-nav li a span {
        display: block;
        float: left;
        width: 23px;
        height: 23px;
        background: #f6f4ee;
        border-radius: 30px;
        border: 0; }
      .tab-nav li a:hover, .tab-nav li a:visited:hover {
        background: #fff;
        color: #333; }
  .tab-nav a.selected {
    background: #fff;
    color: #003e67;
    border-bottom: 1px solid #fff;
    text-decoration: none;
    border-top: 1px solid #003e67; }
    .tab-nav a.selected:hover, .tab-nav a.selected:visited:hover {
      background: #fff;
      color: #003e67;
      border-bottom: 1px solid #fff;
      text-decoration: none;
      border-top: 1px solid #003e67; }
    .tab-nav a.selected span,
    .tab-nav a.selected:hover span,
    .tab-nav a.selected:visited:hover span {
      background: #003e67;
      color: #fff !important; }
  .tab-nav li a span {
    display: block;
    color: #999 !important;
    font-size: 0.9em;
    font-weight: normal;
    position: absolute;
    top: 11px;
    right: 15px;
    line-height: 23px;
    text-align: center; }
  .tab-nav .amount a {
    padding-right: 42px; }
  .tab-nav li a:hover {
    border-top: 1px solid #1186c3;
    color: #1186c3 !important; }
    .tab-nav li a:hover span {
      background: #1186c3;
      color: #fff !important; }

.tabs > div {
  padding: 20px 0; }
  .tabs > div form {
    padding-bottom: 80px; }

#footer {
  background: url("../hammerite/img/bg/bg-footer.jpg") 0 0 no-repeat;
  padding-top: 100px;
  clear: both; }
  #footer .footer {
    background: url("../hammerite/img/bg/noise-lightblue.jpg");
    padding: 0;
    margin: 0; }
    #footer .footer #stores {
      padding-bottom: 20px;
      background: url("../hammerite/img/bg/footer-indent.png") 0 100% repeat-x; }
      #footer .footer #stores .widget-title {
        color: #003e67;
        font-family: "facitweb",sans-serif;
        font-size: 1.6em;
        display: block;
        padding-bottom: 4px;
        text-align: center; }
        .lt-ie9 #footer .footer #stores .widget-title {
          font-family: Arial, sans-serif !important;
          font-weight: bold; }
      #footer .footer #stores ul {
        height: 110px;
        overflow: hidden;
        padding: 0 0 0 10px;
        width: auto;
        margin: 0 auto; }
        #footer .footer #stores ul li {
          float: left;
          margin-right: 10px;
          width: 130px;
          height: 100px;
          background: #fff;
          box-shadow: 0 2px 2px #c4d5d9;
          text-align: center;
          line-height: 100px; }
          #footer .footer #stores ul li img {
            max-width: 110px;
            max-height: 80px;
            vertical-align: middle; }
    #footer .footer div.col {
      width: 310px;
      padding-left: 10px;
      float: left;
      padding: 25px 0; }
      #footer .footer div.col .widget-title {
        color: #003e67;
        font-family: "facitweb",sans-serif;
        font-size: 1.6em;
        margin: 0 0 15px;
        padding: 0; }
        .lt-ie9 #footer .footer div.col .widget-title {
          font-family: Arial, sans-serif !important;
          font-weight: bold; }
      #footer .footer div.col ul li a {
        color: #373a3b;
        text-decoration: none;
        padding: 6px 0 6px 14px;
        display: block;
        background: url("../hammerite/img/globals/sprite.png") -933px -410px no-repeat; }
        #footer .footer div.col ul li a:hover, #footer .footer div.col ul li a:visited:hover {
          color: #0375b0;
          text-decoration: underline;
          background-position: -975px -351px; }
      #footer .footer div.col form input {
        width: 240px;
        border-radius: 3px;
        padding: 8px 10px;
        background: #fff;
        border: 1px solid #c0cccf;
        color: #333;
        font-family: Arial, sans-serif;
        font-size: 1em;
        margin-bottom: 10px; }
      #footer .footer div.col form .btn {
        width: auto;
        padding: 0 20px 0 10px;
        background: url("../hammerite/img/globals/sprite.png") 100% -290px no-repeat;
        font-family: Arial, sans-serif;
        font-size: 1em;
        font-weight: bold;
        color: #fff;
        height: 30px;
        border-radius: 3px;
        border: 1px solid #048fbd; }
        #footer .footer div.col form .btn:hover {
          background-poisition: right -318px;
          box-shadow: 0 2px 2px #c4d5d9;
          cursor: pointer; }
  #footer .vcard {
    color: #373a3b;
    line-height: 1.4em;
    padding: 20px 0 0; }

#claim {
  background: #fff;
  border-top: 1px solid #dce0e1;
  padding: 20px 0 40px 0;
  overflow: hidden; }
  #claim .nav {
    float: left; }
    #claim .nav li {
      display: inline-block;
      margin: 0 10px; }
  #claim .logo {
    float: right; }

.social {
  display: -ms-flexbox;
  display: flex; }
  .social__item {
    margin-right: 10px; }
    .social__item__link {
      background: none !important;
      padding: 0 !important; }
    .social__item i {
      background: #373a3b;
      color: white;
      height: 30px;
      width: 30px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center;
      border-radius: 4px; }

.cookie-menu ul {
  display: -ms-flexbox;
  display: flex; }

.cookie-menu__item {
  margin: 0 10px; }

*:first-child + html .newsletter input {
  padding-top: 8px;
  height: 26px; }

#compare {
  background: url("../hammerite/img/bg/bg-compare.png") 0 0 repeat-x;
  padding: 30px 0 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; }
  #compare .wrapper div {
    width: 270px;
    margin-right: 20px;
    float: left;
    height: 88px;
    position: relative; }
    #compare .wrapper div figure {
      width: 68px;
      height: 80px;
      border: 1px solid #ece8de;
      float: left;
      margin-right: 20px;
      padding: 3px;
      background: #f6f4ee; }
      #compare .wrapper div figure img {
        height: 80px; }
    #compare .wrapper div a {
      display: block;
      padding: 0 0 6px;
      font-weight: bold; }
    #compare .wrapper div span {
      display: block; }
    #compare .wrapper div.empty {
      border: 1px solid #ece8de;
      background: #f6f4ee; }
      #compare .wrapper div.empty span {
        display: block;
        text-align: center;
        font-style: italic;
        padding-top: 36px;
        color: #969794; }
    #compare .wrapper div .rating {
      width: 160px; }
    #compare .wrapper div .delete {
      margin: 10px 0 0;
      padding: 4px 10px 4px 18px;
      background: url("../hammerite/img/globals/sprite.png") no-repeat -490px -755px transparent; }
  #compare .wrapper .cta {
    position: absolute;
    top: 30px;
    right: 0;
    height: 28px; }
    #compare .wrapper .cta.inactive {
      background: url("../hammerite/img/globals/sprite.png") 100% -1100px no-repeat;
      border: 1px solid #bbb;
      height: 28px;
      color: #fff;
      font-style: italic;
      box-shadow: 0 !important; }
      #compare .wrapper .cta.inactive:hover, #compare .wrapper .cta.inactive:visited:hover {
        background: url("../hammerite/img/globals/sprite.png") 100% -1100px no-repeat;
        border: 1px solid #bbb;
        height: 28px;
        color: #fff;
        font-style: italic;
        box-shadow: 0 !important; }

#prodfinder {
  width: 260px;
  padding: 20px;
  float: right;
  margin-right: 10px;
  height: 208px;
  background: #ece8de;
  position: relative; }
  #prodfinder h2 {
    color: #373a3b;
    text-shadow: 0 1px 0 #fff;
    margin-bottom: 4px; }
  #prodfinder p {
    color: #373a3b;
    text-shadow: 0 1px 0 #fff;
    margin-bottom: 4px;
    padding-bottom: 6px; }
  #prodfinder select {
    width: 230px;
    margin-bottom: 8px; }
  #prodfinder input {
    display: block;
    margin: 10px 0 0 0;
    border: 1px solid #02395e;
    background: url("../hammerite/img/globals/sprite.png") -490px -230px no-repeat;
    height: 30px;
    padding: 0 16px;
    font-size: 1em;
    color: #c1dbe3;
    float: left;
    clear: both;
    font-family: Arial, sans-serif;
    border-radius: 5px; }
    #prodfinder input:hover {
      background: url("../hammerite/img/globals/sprite.png") -490px -260px no-repeat;
      cursor: pointer;
      color: #fff; }
  #prodfinder a#tooltip {
    display: block;
    height: 18px;
    width: 18px;
    background: url("../hammerite/img/globals/sprite.png") -71px -43px no-repeat;
    text-indent: -9999em;
    font-size: 0em;
    float: right; }

#popup_container {
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  min-width: 300px;
  /* Dialog will be no smaller than this */
  max-width: 600px;
  /* Dialog will wrap after this width */
  background: #FFF;
  border: solid 5px #999;
  color: #000; }
  #popup_container .error {
    border: 0;
    padding: 0; }

#popup_container {
  border-radius: 5px;
  display: none;
  padding: 15px;
  z-index: 100000;
  position: absolute;
  width: 500px;
  background: #FF9;
  border: 1px solid #E1E162;
  opacity: 1.0;
  -moz-opacity: 1.0;
  filter: alpha(opacity=100); }
  #popup_container p {
    padding: 0; }

#popup_title {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  line-height: 1.75em;
  color: #666;
  cursor: default;
  padding: 0em;
  margin: 0em; }

#popup_panel {
  text-align: center;
  margin: 1em 0em 0em 1em; }

#popup_prompt {
  margin: .5em 0em; }

a.close-note,
a.close-note:visited,
a.close-popup,
a.close-popup:visited {
  height: 11px;
  overflow: hidden;
  float: right; }

.frm-finder button {
  margin: 0; }

.frm-finder input {
  padding: 7px 5px;
  margin-right: 10px; }

body.page #contentxl .gform_wrapper .gform_fields .gfield,
body.page #content .gform_wrapper .gform_fields .gfield {
  list-style-type: none; }

/* Popup */
.popup {
  padding: 15px 20px 25px 20px;
  background: url("../hammerite/img/bg/popup.png") right bottom no-repeat; }

.popup p {
  padding: 0;
  color: #fff !important;
  text-shadow: 0 1px 0 #000 !important;
  margin: 0 !important;
  padding: 0 !important; }

#prodfinder .popup {
  position: absolute;
  width: 222px;
  bottom: 108px;
  right: 18px;
  display: none; }

/* c7 Popup */
#c7boxwrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: url("../hammerite/img/bg/c7box.png");
  display: none;
  z-index: 999; }

#c7boxabsolute {
  width: 938px;
  margin: 80px auto 0 auto;
  position: relative;
  z-index: 999; }

#c7box {
  width: 870px;
  border: 0;
  background: #fff;
  box-shadow: 0 1px 6px #42627b;
  height: 500px;
  padding: 20px;
  overflow: auto;
  z-index: 999;
  position: relative; }

#c7boxwrapper #close {
  display: block;
  float: right;
  height: 20px;
  line-height: 20px;
  padding-right: 30px;
  color: #999;
  background: url("../hammerite/img/globals/sprite.png") right -920px no-repeat;
  text-decoration: none;
  margin-bottom: 20px; }

#c7boxwrapper #close:hover,
#c7box #close:visited:hover {
  background: url("../hammerite/img/globals/sprite.png") right -940px no-repeat;
  color: #08a5d9;
  text-decoration: underline; }

#c7boxabsolute .product {
  float: right; }

#c7boxabsolute .product figure {
  width: 120px;
  height: 140px;
  border: 1px solid #ece8de;
  float: left;
  margin-right: 0;
  padding: 3px;
  background: #f6f4ee; }

#c7boxabsolute .product span {
  clear: both;
  display: block;
  color: #003e67;
  padding: 10px 0 0 0;
  font-weight: bold; }

#c7boxabsolute .product span.rating {
  padding: 0;
  margin: 10px 0 0 0; }

#c7boxabsolute .product figure {
  width: 200px;
  height: 200px; }

#c7boxabsolute .product figure span {
  display: block;
  width: 200px;
  height: 175px;
  background: #fff;
  text-align: center;
  padding: 25px 0 0 0;
  margin: 0; }

#c7box .frm-contact div {
  clear: both;
  border-bottom: 1px solid #eee;
  padding-top: 12px; }

#c7box .frm-contact {
  border-top: 1px solid #eee; }

#c7box .frm-contact em {
  clear: both;
  color: #666;
  display: block; }

#c7box .frm-contact .clicktorate em {
  width: auto;
  clear: none;
  float: left;
  padding: 2px 0 0 16px; }

#c7box .frm-contact .reviewinfo {
  clear: none;
  padding-bottom: 10px; }

#c7box .frm-contact .reviewinfo ul {
  list-style: disc;
  padding-left: 20px; }

#c7box .frm-contact .reviewinfo ul li {
  padding-top: 4px; }

#c7box .frm-contact .radios {
  float: left; }

#c7box .frm-contact .radios li .radio {
  width: auto;
  margin: 5px 10px 0 0;
  float: left; }

#c7box .frm-contact .radios li label {
  width: 300px;
  font-weight: normal; }

#c7box .frm-contact .radios li {
  padding-bottom: 4px;
  overflow: hidden; }

#c7box .frm-contact h3 {
  margin: 20px 0 0 0; }

#c7box .frm-contact button {
  padding-bottom: 4px;
  margin: 20px 20px 20px 150px;
  line-height: 0em; }

#c7box .frm-left {
  float: left;
  width: 600px; }

#c7boxabsolute .frm-right {
  width: 280px;
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 999; }

#c7box .pros-cons em {
  padding-left: 0; }

#c7box .pros-cons ul {
  width: 300px;
  float: left;
  margin: 0 20px 0 0;
  padding: 10px 0 0 0; }

#c7box .pros-cons ul li {
  padding-bottom: 6px;
  overflow: hidden; }

#c7box .pros-cons ul li a {
  display: block;
  clear: both; }

/* Form popups */
.plain-page {
  background: none; }

#c7box .frm-contact div, #c7box .frm-contact .gfield {
  clear: both;
  border-bottom: 1px solid #eee;
  padding: 12px 0;
  overflow: hidden; }

#c7box .frm-contact .ginput_container, #c7box .frm-contact .gfield_description {
  margin: 0;
  padding: 0;
  clear: none;
  border: 0; }

#c7box .frm-contact .gform_heading, #c7box .frm-contact .gform_body, #c7box .frm-contact .gform_footer {
  border: 0; }

#c7box .left_label .gfield_description {
  margin: 5px 0 0 150px;
  color: #666; }

.gfield_description ul {
  list-style: disc;
  margin-left: 20px; }

.gfield_description ul li {
  padding-top: 4px; }

#c7box li.pros input, #c7box li.cons input {
  margin-bottom: 3px; }

#c7box .frm-contact li.pros, #c7box .frm-contact li.cons {
  clear: none;
  width: 360px;
  float: left;
  margin: 0 20px 0 0;
  padding: 10px 0 0 0;
  border: 0; }

#c7box .frm-contact li.pros label, #c7box .frm-contact li.cons label {
  float: none;
  display: block; }

#c7box .frm-contact li.pros input, #c7box .frm-contact li.cons input {
  margin-right: 5px; }

#c7box .frm-contact em {
  clear: both;
  color: #666;
  display: block; }

#c7box .frm-contact .clicktorate em {
  width: auto;
  clear: none;
  float: left;
  padding: 2px 0 0 16px; }

#c7box .frm-contact .reviewinfo {
  clear: none;
  padding-bottom: 10px; }

#c7box .frm-contact .reviewinfo ul {
  list-style: disc;
  padding-left: 20px; }

#c7box .frm-contact .reviewinfo ul li {
  padding-top: 4px; }

.frm-contact label {
  font-weight: bold;
  padding: 5px 0;
  cursor: pointer; }

.frm-contact .gfield_radio {
  margin: 1px; }

.frm-contact .gfield_radio li {
  float: left;
  margin: 0 15px 1em 0; }

.frm-contact .gfield_radio input {
  float: none;
  width: auto;
  height: auto;
  border: 0;
  margin-right: 5px; }

.frm-contact .gfield_radio label {
  float: none;
  font-weight: normal; }

#c7box .frm-contact .radios {
  float: left; }

#c7box .frm-contact .radios li .radio {
  width: auto;
  margin: 5px 10px 0 0;
  float: left; }

#c7box .frm-contact .radios li label {
  width: 300px;
  font-weight: normal; }

#c7box .frm-contact .radios li {
  padding-bottom: 4px;
  overflow: hidden; }

#c7box .frm-contact button {
  padding-bottom: 4px;
  margin: 20px 20px 20px 150px;
  line-height: 0; }

#c7box .frm-left {
  float: left;
  width: 600px; }

#c7boxabsolute .frm-right {
  width: 280px;
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 999; }

#c7box .pros-cons em {
  padding-left: 0; }

#c7box .pros-cons ul {
  width: 300px;
  float: left;
  margin: 0 20px 0 0;
  padding: 10px 0 0 0; }

#c7box .pros-cons ul li {
  padding-bottom: 6px;
  overflow: hidden; }

#c7box .pros-cons ul li a {
  display: block;
  clear: both; }

#thepromos {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 800;
  background: rgba(30, 30, 32, 0.75);
  display: block;
  width: 100%; }

/*
*html #thepromos {
	display: none;
}
*/
#promos {
  width: 960px;
  margin: 0 auto;
  display: block;
  padding: 20px 0;
  overflow: hidden;
  height: 1%;
  color: #fff;
  position: relative; }

.promo {
  background: white;
  color: #ccc;
  width: 698px;
  display: block;
  height: 60px;
  margin: 0 auto;
  padding: 15px;
  position: relative; }
  .promo p {
    padding: 0;
    width: 230px;
    line-height: 16px; }
    .promo p a,
    .promo p a:visited {
      text-decoration: underline; }
  .promo h2 {
    font-size: 18px;
    padding-bottom: 0;
    margin-bottom: 0;
    color: #033b66; }
  .promo a.cta {
    position: absolute;
    top: 30px;
    right: 15px; }

.promo-hover {
  cursor: pointer;
  background-position: 0 -86px; }
  .promo-hover span {
    border-color: #f6821a; }
  .promo-hover h2 {
    color: #f6821a;
    text-decoration: underline; }

#promos .second {
  margin: 0 25px; }

a#close-promos,
a#close-promos:visited {
  display: block;
  overflow: hidden;
  height: 13px;
  width: 13px;
  margin: 20px 20px 0 0;
  background: url("../hammerite/img/globals/sprite.png") left -1281px no-repeat;
  position: absolute;
  right: 10px;
  top: 10px;
  text-indent: -9999px; }

a#close-promos:hover,
a#close-promos:visited:hover {
  background-position: left -1298px; }

.banner-promo {
  width: 670px;
  height: 85px;
  margin: 15px auto;
  background: url("../hammerite/img/backgrounds/banner.png") 0 0 no-repeat;
  position: relative; }
  .banner-promo dl {
    position: absolute;
    left: 20px;
    top: 20px;
    color: #fff;
    width: 350px; }
  .banner-promo dt {
    font-weight: 700;
    padding-bottom: 5px; }
  .banner-promo dd {
    line-height: 140%; }
  .banner-promo a,
  .banner-promo a:visited {
    text-decoration: underline;
    color: #fff; }

.knight {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  display: block; }
  @media only screen and (max-width: 1780px) {
    .knight {
      display: none; } }
  #site-header .knight {
    position: absolute;
    height: 143px;
    left: 164px;
    bottom: 61px;
    z-index: 1;
    display: none; }
    @media only screen and (max-width: 1780px) {
      #site-header .knight {
        display: block; } }
    #site-header .knight img {
      max-height: 100%; }

.radish-cookie-bar {
  background-color: rgba(0, 0, 0, 0.59) !important;
  height: 100vh;
  width: 100vw;
  font-family: 'Open sans', sans-serif; }
  .radish-cookie-bar .wrap {
    background: #fff;
    color: #555;
    margin: 50px auto 0;
    width: auto;
    max-width: 400px;
    display: block;
    max-height: 100vh;
    height: auto;
    overflow-y: auto;
    padding: 24px 36px !important;
    box-sizing: border-box; }
  .radish-cookie-bar .rcc-before-title-logo {
    text-align: center;
    margin-bottom: 1.6rem; }
    .radish-cookie-bar .rcc-before-title-logo img {
      max-width: 100%;
      height: auto; }
  .radish-cookie-bar .cookie-bar-header {
    text-align: center;
    margin-bottom: 24px; }
    .radish-cookie-bar .cookie-bar-header .cookie-bar-title {
      padding: 0;
      font-size: 20px;
      color: #005192; }
  .radish-cookie-bar .cookie-bar-content p {
    font-size: 18px; }
  .radish-cookie-bar .cookie-bar-buttons {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .radish-cookie-bar .cookie-bar-buttons li {
      margin: 0 !important;
      -ms-flex: 0 1 100%;
          flex: 0 1 100%; }
  .radish-cookie-bar .button {
    display: block;
    text-align: center;
    padding: 14px 20px !important;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none; }
    .radish-cookie-bar .button:hover {
      opacity: 1 !important; }
  .radish-cookie-bar .button-primary {
    background-color: #005192 !important;
    color: #fff;
    border: none !important;
    margin-bottom: 10px !important; }
    .radish-cookie-bar .button-primary:hover {
      background-color: #004379 !important; }
  .radish-cookie-bar .button-secondary {
    border: 1px solid #005192 !important;
    background-color: transparent !important;
    color: #005192; }
    .radish-cookie-bar .button-secondary:hover {
      background-color: #005192 !important;
      color: #fff; }
  .radish-cookie-bar .cookie-bar-options li {
    position: relative;
    display: block;
    padding-left: 46px;
    cursor: pointer; }
    .radish-cookie-bar .cookie-bar-options li label {
      margin-left: 0 !important;
      margin-bottom: 4px;
      color: #002642;
      font-size: 18px;
      line-height: 25px;
      font-weight: 500; }
      .radish-cookie-bar .cookie-bar-options li label:before {
        display: block;
        color: #000;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-clip: padding-box;
        border: 1px solid #000;
        border-radius: 0;
        height: 30px;
        width: 30px; }
    .radish-cookie-bar .cookie-bar-options li .cookie-description p {
      color: #000;
      font-size: 14px;
      display: block; }
  .radish-cookie-bar .rcc-allow-cookie-type {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
    .radish-cookie-bar .rcc-allow-cookie-type ~ label:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27%23ffffff%27%20viewBox%3D%270%200%2032%2032%27%3E%3Cpath%20class%3D%27cls-1%27%20d%3D%27M21.64%2C10%2C13.54%2C20l-3.11-3.82A.9.9%2C0%2C0%2C0%2C9%2C17.29L12.84%2C22a.9.9%2C0%2C0%2C0%2C1.4%2C0L23%2C11.17A.9.9%2C0%2C1%2C0%2C21.64%2C10Z%27%2F%3E%3C%2Fsvg%3E");
      position: absolute;
      left: 0;
      top: 0; }
    .radish-cookie-bar .rcc-allow-cookie-type:disabled ~ label:before {
      background-color: #ccc;
      border: 1px solid #ccc; }
    .radish-cookie-bar .rcc-allow-cookie-type:checked:not(:disabled):not([readonly]) ~ label:before {
      background-size: 28px;
      background-color: #005192;
      border-color: #005192; }
  .radish-cookie-bar .cookie-bar-settings .cookie-bar-buttons li:first-of-type {
    -ms-flex-order: 1;
        order: 1; }
  .radish-cookie-bar .cookie-bar-settings .cookie-bar-buttons li:last-of-type {
    -ms-flex-order: 0;
        order: 0; }

.radish-cookie-settings-toggle {
  display: none; }

.steps {
  width: 430px;
  float: left;
  padding: 20px 0 0 !important; }

.step {
  padding: 15px 10px 0 49px;
  border-top: 1px solid #ece8de;
  position: relative;
  display: block;
  overflow: hidden;
  clear: both; }
  .step .text {
    display: block;
    margin-bottom: 1em; }
  .step em {
    border-radius: 100px;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-weight: bold;
    color: #fff;
    font-size: 1.2em;
    text-align: center;
    background: #013f68;
    border: 0;
    float: left;
    position: absolute;
    left: 0;
    top: 8px; }
  .step.caution em {
    background: #ece8de url("../hammerite/img/globals/sprite.png") -955px -852px no-repeat;
    text-indent: -9999em;
    font-size: 0em; }
  .step.tip em {
    background: #ece8de url("../hammerite/img/globals/sprite.png") -914px -882px no-repeat;
    text-indent: -9999em;
    font-size: 0em; }

.split {
  float: left;
  display: block;
  width: 430px;
  padding-right: 30px; }
  .split figure {
    border: 1px solid #e1e1e1;
    display: inline-block;
    margin-bottom: 20px; }
    .split figure span {
      width: 300px;
      height: 169px;
      display: block;
      overflow: hidden;
      margin: 5px; }

.delete {
  background: url("../hammerite/img/globals/sprite.png") -486px -755px no-repeat;
  display: block;
  padding: 4px 10px 4px 24px;
  float: left;
  text-decoration: none;
  color: #f54c4c;
  margin: 4px 0 0 10px; }
  .delete:hover, .delete:visited:hover {
    text-decoration: underline;
    color: #cc0000; }

.tooltip {
  display: block;
  height: 18px;
  width: 18px;
  background: url("../hammerite/img/globals/sprite.png") -71px -43px no-repeat;
  text-indent: -9999em;
  font-size: 0em;
  float: left; }

p.clear {
  clear: both;
  padding: 10px 0; }

a.clear {
  clear: both;
  margin-top: 20px; }

#content span {
  color: #373a3b; }

.nobg {
  background: none; }

.noborder {
  border: 0; }

.req {
  color: #029fd3; }

.note {
  display: block;
  padding: 10px 15px 10px 44px;
  background: #fcebb5 url("../hammerite/img/globals/sprite.png") -950px -849px no-repeat;
  clear: both;
  border: 1px solid #f5da96;
  color: #5c5031 !important;
  margin-bottom: 20px; }

.usplist.float {
  width: 340px;
  float: left; }

a.icn-delete {
  display: block;
  float: right;
  width: 13px;
  height: 13px;
  font-size: 0em;
  margin-left: 6px;
  text-indent: -999em;
  background: url("../hammerite/img/globals/sprite.png") -888px -358px no-repeat; }

p.light {
  color: #999;
  font-style: italic; }

#breadcrumbs {
  border-bottom: 1px solid #e5ebf0;
  padding: 10px 10px 10px 20px; }
  #breadcrumbs li,
  #breadcrumbs span span {
    font-size: 0.95em;
    margin-right: 12px;
    font-weight: bold;
    color: #666; }
    #breadcrumbs li a,
    #breadcrumbs span span a {
      color: #666;
      display: block;
      float: left;
      text-decoration: none;
      padding-right: 14px;
      background: url("../hammerite/img/globals/sprite.png") 100% -507px no-repeat;
      font-weight: normal; }
  #breadcrumbs li {
    float: left;
    font-size: 0.9em; }
  #breadcrumbs span span a {
    margin-right: 14px; }

.paging ul {
  clear: both; }

.paging li {
  float: left;
  margin-right: 6px; }
  .paging li a {
    display: block;
    float: left;
    padding: 6px 10px;
    border-radius: 3px;
    background: #f0f4f5;
    border: 1px solid #f0f4f5;
    text-decoration: none; }
    .paging li a:hover, .paging li a:visited:hover {
      border: 1px solid #0396cd;
      text-decoration: none;
      color: #1186c3;
      background: #fff; }

.paging .inactive a {
  color: #bbb; }
  .paging .inactive a:hover, .paging .inactive a:visited:hover {
    cursor: default;
    text-decoration: none;
    border: 1px solid #f0f4f5;
    text-decoration: none;
    color: #bbb;
    background: #f0f4f5; }

.paging .current a {
  background: url("../hammerite/img/globals/sprite.png") -490px -318px no-repeat;
  border: 1px solid #0396cd;
  font-weight: bold;
  text-decoration: none;
  color: #fff; }
  .paging .current a:hover, .paging .current a:visited:hover {
    background-position: -490px -318px;
    border: 1px solid #0396cd;
    font-weight: bold;
    text-decoration: none;
    color: #fff; }

.extra {
  padding: 20px 160px 5px 20px;
  background: #f6f4ee;
  position: relative; }
  .extra .delete {
    margin: 0;
    position: absolute;
    top: 36px;
    right: 20px;
    padding-top: 5px; }
  .extra em {
    display: block;
    padding-bottom: 6px;
    font-weight: bold;
    font-style: normal; }
  .extra ul {
    padding-bottom: 15px; }
    .extra ul li {
      padding: 0 8px 0 0;
      background: url("../hammerite/img/bg/sep-dotted-y.png") right 1px repeat-y;
      float: left;
      margin-right: 8px; }
      .extra ul li a {
        margin-top: 1px; }

#ops {
  border: 1px solid #ece8de;
  padding: 0;
  margin-bottom: 20px;
  background: #f6f4ee; }
  #ops li {
    float: left;
    padding-right: 1px;
    background: url("../hammerite/img/bg/sep-dotted-y.png") top right repeat-y; }
    #ops li a {
      display: block;
      float: left;
      padding: 15px 20px 15px 46px;
      background: url("../hammerite/img/globals/sprite.png") -438px -830px no-repeat;
      text-decoration: none; }
  #ops .email a {
    background: url("../hammerite/img/globals/sprite.png") -453px -787px no-repeat; }
  #ops li a:hover, #ops li a:visited:hover {
    background-color: #fff;
    text-decoration: underline; }

aside.inzet {
  background: url("../hammerite/img/bg/noise-lightblue.jpg");
  padding: 15px;
  width: 200px;
  float: right;
  margin: 20px 0 20px 20px; }

.error {
  border: 1px solid #f30;
  padding: 10px;
  margin-bottom: 10px; }
  .error label {
    width: 140px; }
  .error em {
    padding: 5px 0 0 140px;
    font-weight: bold;
    color: #f30; }

.img-confirm {
  border: 1px solid #e1e1e1;
  display: inline-block;
  margin-bottom: 10px; }
  .img-confirm span {
    width: 150px;
    height: 90px;
    overflow: hidden;
    display: block;
    margin: 5px; }

#articlewrapper {
  width: 960px;
  clear: both;
  padding: 0 10px 10px;
  border-top: 1px solid #e5ebf0;
  border-bottom: 1px solid #e5ebf0; }
  #articlewrapper article {
    width: 300px;
    float: left;
    margin: 20px 30px 0 0;
    position: relative; }

#singleprod article {
  width: 300px;
  float: left;
  margin: 20px 30px 0 0;
  position: relative; }

#articlewrapper article.nomargin,
#singleprod article.nomargin {
  margin-right: 0; }

#articlewrapper article h2,
#singleprod article h2 {
  font-size: 1.8em;
  margin-bottom: 0; }
  #articlewrapper article h2 a,
  #singleprod article h2 a {
    text-decoration: none;
    color: #003e67; }

#articlewrapper article em,
#singleprod article em {
  font-size: 1.1em;
  font-weight: normal;
  font-style: italic;
  display: block;
  color: #8c8983; }

#articlewrapper article figure,
#singleprod article figure {
  width: 300px;
  height: 169px;
  margin: 0;
  z-index: 8;
  position: relative;
  display: block;
  border: 0; }
  #articlewrapper article figure img,
  #singleprod article figure img {
    display: block;
    width: 100%; }

#articlewrapper article div.title,
#singleprod article div.title {
  background: #fff;
  padding: 0 0 15px 0;
  width: 300px; }

#articlewrapper article div,
#singleprod article div {
  background: url("../hammerite/img/bg/noise-blue.png");
  width: 260px;
  padding: 20px; }

#articlewrapper article div.text {
  padding: 0; }

#articlewrapper article div a,
#singleprod article div a {
  display: block;
  clear: both; }

#articlewrapper article div p,
#singleprod article div p {
  padding-bottom: 10px; }

#articlewrapper article div .bull,
#singleprod article div .bull {
  margin-top: 6px; }

#articlewrapper article ul,
#singleprod article ul {
  position: absolute;
  top: 120px;
  left: 10px;
  overflow: hidden;
  display: block;
  height: 66px;
  width: 280px;
  z-index: 9; }

#articlewrapper article ul li {
  float: left;
  display: block;
  width: 66px;
  height: 66px;
  margin-right: -20px;
  position: relative; }

#singleprod {
  width: 300px;
  float: left;
  margin-bottom: 20px; }
  #singleprod article {
    margin: 18px 0 0; }
    #singleprod article ul li {
      float: left;
      display: block;
      width: 66px;
      height: 66px;
      margin-right: -20px;
      position: relative; }
    #singleprod article figure {
      height: 120px;
      z-index: 0; }
    #singleprod article ul {
      top: 70px; }
    #singleprod article div.title h2 {
      float: left; }
    #singleprod article div.title em {
      float: right;
      display: block;
      padding: 7px 6px 0 0; }

#vidwrapper {
  width: 960px;
  clear: both;
  padding: 20px 10px 10px;
  border-top: 1px solid #e5ebf0;
  border-bottom: 1px solid #e5ebf0;
  overflow: hidden;
  height: 278px;
  position: relative; }
  #vidwrapper #vidcarousel {
    width: 3000px;
    height: 233px;
    position: absolute;
    top: 20;
    left: 10; }
    #vidwrapper #vidcarousel div {
      width: 300px;
      float: left;
      margin: 0 30px 0 0;
      height: 233px; }
  #vidwrapper div.nomargin {
    margin-right: 0; }
  #vidwrapper ul {
    display: block;
    height: 35px;
    width: 80px;
    margin-left: 442px;
    margin-top: 243px; }
    #vidwrapper ul li {
      float: left;
      margin-right: 5px;
      width: 35px;
      height: 35px; }
      #vidwrapper ul li a {
        display: block;
        width: 35px;
        height: 35px;
        text-indent: -9999em;
        font-size: 0em;
        background: url("../hammerite/img/globals/sprite.png") -540px -611px no-repeat; }
        #vidwrapper ul li a:hover, #vidwrapper ul li a:visited:hover {
          background-position: -500px -611px; }
    #vidwrapper ul .next a {
      background: url("../hammerite/img/globals/sprite.png") -660px -611px no-repeat; }
      #vidwrapper ul .next a:hover, #vidwrapper ul .next a:visited:hover {
        background-position: -700px -611px; }
    #vidwrapper ul .inactive a {
      background: url("../hammerite/img/globals/sprite.png") -580px -611px no-repeat;
      cursor: default; }
      #vidwrapper ul .inactive a:hover, #vidwrapper ul .inactive a:visited:hover {
        background-position: -580px -611px;
        cursor: default; }
    #vidwrapper ul .inactive.next a {
      background: url("../hammerite/img/globals/sprite.png") -620px -611px no-repeat;
      cursor: default; }
      #vidwrapper ul .inactive.next a:hover, #vidwrapper ul .inactive.next a:visited:hover {
        background-position: -620px -611px;
        cursor: default; }

#columns {
  width: 960px;
  padding: 30px 10px; }
  #columns div {
    width: 280px;
    background: url("../hammerite/img/bg/sep-dotted-y.png") 100% 0 repeat-y;
    float: left;
    padding: 0 15px 0 20px; }
    #columns div div {
      background: none; }
    #columns div.middle {
      width: 290px;
      padding: 0 20px; }
    #columns div.last {
      background: none;
      padding: 0 10px 0 35px;
      width: 270px; }
    #columns div.middle h2 {
      margin-left: 10px; }
    #columns div.middle span {
      display: block;
      padding: 4px 0 10px 20px;
      font-style: italic;
      color: #898f92; }
    #columns div.middle a.bull {
      margin-left: 20px; }

ul.usplist li {
  display: block;
  background: url("../hammerite/img/globals/sprite.png") -954px -375px no-repeat;
  padding: 4px 0 8px 22px;
  color: #373a3b; }

p.testimonial {
  width: 250px;
  padding: 15px 20px 20px;
  background: #e8f0f4 url("../hammerite/img/bg/bg-testimonial.png") 0 100% no-repeat; }

#carousel.gewinnspiel {
  margin: 0;
  height: auto;
  width: 670px;
  max-width: 100%; }
  #carousel.gewinnspiel img {
    width: 100%; }
  #carousel.gewinnspiel + #slider {
    display: none; }

#content {
  width: 940px;
  padding: 10px 20px 20px 20px;
  position: relative; }
  #content .intro {
    padding: 0 0 20px 0; }
  #content #prodwrapper {
    float: left;
    width: 480px;
    padding: 0 20px 20px 20px; }
  #content #refined {
    padding: 12px;
    background: url("../hammerite/img/bg/noise-lightblue.jpg");
    border-radius: 3px;
    border: 0;
    margin-bottom: 20px; }
    #content #refined li {
      float: left;
      margin-left: 6px;
      padding-left: 6px;
      border-left: 1px solid #ccc;
      color: #373a3b; }
    #content #refined .first {
      font-weight: bold;
      color: #003e67;
      border: 0;
      margin: 0;
      padding: 0; }
    #content #refined a {
      display: block;
      float: left;
      width: 13px;
      height: 13px;
      font-size: 0em;
      margin-left: 6px;
      text-indent: -999em;
      background: url("../hammerite/img/globals/sprite.png") -903px -358px no-repeat; }
      #content #refined a:hover {
        background-position: -888px -358px; }
    #content #refined span {
      float: left; }
  #content #sidebar {
    float: left;
    width: 200px;
    background: url("../hammerite/img/bg/sep-dotted-y.png") top left repeat-y;
    padding: 0 0 20px 20px; }
    #content #sidebar p.testimonial {
      width: 160px; }
    #content #sidebar span.testimonial {
      display: block;
      padding: 4px 0 10px 20px;
      font-style: italic;
      color: #898f92; }

.product {
  padding-bottom: 20px;
  border-bottom: 1px solid #ece8de;
  margin-bottom: 20px;
  clear: both; }
  .product div {
    float: left;
    width: 310px; }
    .product div p {
      clear: both;
      padding: 10px 0; }
  .product__left {
    width: 120px !important;
    float: left;
    margin-right: 20px;
    padding: 3px; }
    .product__left figure {
      height: 140px;
      text-align: center;
      width: auto;
      float: none;
      margin: 0;
      padding: 0; }
  .product figure {
    width: 120px;
    height: 140px;
    float: left;
    margin-right: 20px;
    padding: 3px;
    text-align: center; }
  .product h2 {
    font-family: Arial, sans-serif;
    letter-spacing: 0;
    padding: 0;
    margin: 0 0 4px 0;
    font-size: 1.6em; }
    .product h2 a {
      text-decoration: none; }
      .product h2 a:hover {
        text-decoration: underline; }
  .product .amount {
    float: right;
    background: #f6f4ee;
    padding: 15px;
    width: auto;
    border: 1px solid #ece8de;
    border-radius: 4px;
    margin-top: 40px; }
    .product .amount input {
      width: 30px;
      border-radius: 2px;
      padding: 5px;
      font-size: 1.2em;
      color: #333;
      margin-right: 10px;
      font-family: Arial, sans-serif;
      border: 1px solid #d4d1ca;
      float: left; }
    .product .amount a.plus {
      display: block;
      float: left;
      height: 31px;
      width: 30px;
      text-indent: -9999em;
      background: url("../hammerite/img/globals/sprite.png") -489px -346px no-repeat;
      margin-right: 10px; }
      .product .amount a.plus:hover, .product .amount a.plus:visited:hover {
        background-position: -519px -346px; }
    .product .amount a.minus {
      display: block;
      float: left;
      height: 31px;
      width: 30px;
      text-indent: -9999em;
      background: url("../hammerite/img/globals/sprite.png") -549px -346px no-repeat;
      margin-right: 10px; }
      .product .amount a.minus:hover, .product .amount a.minus:visited:hover {
        background-position: -579px -346px; }

.product .btnOrange {
  padding: 0 20px 0 10px; }

.product a.btnCompare {
  padding: 0 10px; }

#content #refine {
  width: 200px;
  background: url("../hammerite/img/bg/noise-lightblue.jpg");
  border-radius: 3px;
  border: 0;
  float: left; }
  #content #refine h2 {
    font-family: Arial, sans-serif;
    font-size: 1.2em;
    font-weight: bold;
    display: block;
    padding: 10px 10px 10px 15px;
    border-bottom: 1px solid #dce0e1;
    margin: 0; }
  #content #refine section {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #dce0e1; }
    #content #refine section a {
      padding: 10px 10px 10px 30px;
      display: block;
      clear: both;
      color: #373a3b;
      font-weight: bold;
      text-decoration: none;
      background: url("../hammerite/img/globals/sprite.png") -909px -448px no-repeat;
      position: relative; }
      #content #refine section a:hover, #content #refine section a:visited:hover {
        color: #1186c3;
        text-decoration: underline;
        background-position: -898px -468px; }
      #content #refine section a.fold {
        background: url("../hammerite/img/globals/sprite.png") -658px -449px no-repeat; }
        #content #refine section a.fold:hover, #content #refine section a.fold:visited:hover {
          background-position: -648px -479px;
          color: #1186c3;
          text-decoration: underline; }
    #content #refine section ul li {
      padding: 0 10px 10px;
      overflow: hidden; }
      #content #refine section ul li a {
        padding: 0;
        background: none;
        font-weight: 400; }
        #content #refine section ul li a:hover {
          padding: 0;
          background: none;
          font-weight: 400; }
      #content #refine section ul li label {
        color: #373a3b;
        padding-top: 2px;
        display: block;
        float: left;
        width: 130px; }
      #content #refine section ul li input {
        float: left;
        margin: 2px 8px 0 0; }
      #content #refine section ul li em {
        display: block;
        float: right;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: #d3dee4;
        border-radius: 30px;
        border: 0;
        color: #4e7183;
        font-style: normal;
        font-weight: normal;
        font-size: 0.9em;
        box-shadow: 0 1px 0 #fff; }
  #content #refine .color span {
    display: block;
    font-size: 0em;
    text-indent: -9999em;
    width: 18px;
    height: 19px;
    background: url("../hammerite/img/globals/sprite.png") -926px -355px no-repeat;
    float: left;
    margin-right: 6px; }
  #content #refine .tooltip {
    position: absolute;
    right: 10px;
    top: 8px; }
  #content #refine .showmore a {
    background: none;
    margin: 0;
    padding: 0;
    color: #1186c3;
    text-decoration: underline;
    font-weight: normal;
    padding: 4px 0; }
    #content #refine .showmore a:hover {
      background: none;
      text-decoration: underline;
      color: #21cbf5; }
  #content #refine .noborder {
    border-bottom: 0; }

#sidebarxl {
  width: 240px;
  float: left;
  margin: 10px 20px 0 0;
  padding: 0; }

#contentxl {
  width: 680px;
  padding: 0;
  margin: 10px 0 0 0;
  float: left; }
  #contentxl h1 {
    margin-bottom: 0; }

.productimg {
  width: 232px;
  border: 1px solid #ece8de;
  background: #f6f4ee;
  height: 252px;
  padding: 3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

#contentxl figure {
  border: 1px solid #e1e1e1;
  display: inline-block;
  margin-bottom: 20px; }
  #contentxl figure span {
    width: 300px;
    height: 169px;
    display: block;
    overflow: hidden;
    margin: 5px; }
  #contentxl figure.right {
    float: right;
    margin: 0 0 20px 20px; }

#contentxl hgroup h1 {
  margin-bottom: 10px; }

#contentxl.howto ul {
  margin: 0 0 1em 30px;
  list-style: disc; }

#contentxl.howto ol {
  margin: 0 0 1em 30px;
  list-style: decimal; }

hgroup h1 {
  margin-bottom: 10px; }

hgroup h2,
#contentxl hgroup h2 {
  font-family: Arial, sans-serif;
  font-size: 1.6em;
  font-weight: normal;
  color: #373a3b;
  letter-spacing: 0em; }

#contentxl h3 {
  font-family: Arial, sans-serif;
  letter-spacing: 0;
  margin: 30px 0 10px 0;
  color: #373a3b;
  font-weight: bold;
  font-size: 1.3em; }

#procs {
  border-bottom: 1px solid #ece8de;
  padding: 0 0 20px 0;
  margin-bottom: 20px; }
  #procs li {
    padding: 0 20px 0 0;
    margin-right: 20px;
    font-weight: bold;
    color: #003e67;
    float: left;
    background: url("../hammerite/img/bg/sep-dotted-y.png") 100% 0 repeat-y; }
    #procs li em {
      font-weight: normal;
      font-style: normal;
      display: block;
      color: #9b9c9d;
      padding-top: 4px; }

table.features {
  color: #373a3b; }
  table.features tr {
    border-bottom: 1px dotted #ededed; }
    table.features tr td:first-of-type {
      width: 320px; }
    table.features tr td:nth-of-type(2) {
      font-weight: 700; }
  table.features td {
    padding: 10px 0; }
    table.features td span {
      display: block;
      padding-right: 30px;
      float: left;
      line-height: 18px; }
      table.features td span em {
        float: right;
        margin: 0 0 0 6px;
        padding: 0; }

#download {
  border-top: 1px solid #ece8de;
  padding: 10px 0;
  clear: both; }
  #download li a {
    display: block;
    padding: 6px 10px 6px 24px; }

.pdf a {
  background: url("../hammerite/img/globals/sprite.png") -964px -725px no-repeat; }

#content #ctablock {
  padding: 9px;
  background: #f6f4ee;
  border: 1px solid #ece8de;
  clear: both;
  margin-bottom: 20px; }
  #content #ctablock em {
    display: block;
    height: 27px;
    width: 27px;
    border-radius: 30px;
    background: #ece8de;
    float: left;
    text-align: center;
    color: #333;
    line-height: 27px;
    margin: 3px 15px 0 15px;
    font-weight: normal;
    font-style: normal; }
  #content #ctablock .btnOrange {
    padding-top: 0; }
  #content #ctablock a {
    display: block;
    float: left;
    padding-top: 9px; }
  #content #ctablock span {
    float: right;
    font-style: italic;
    color: #969794;
    display: block;
    padding: 9px 6px 0 0; }

#related {
  padding: 15px 20px 20px;
  background: #f6f4ee;
  border: 1px solid #ece8de;
  clear: both; }
  #related h3 {
    color: #373a3b; }
  #related figure {
    float: left;
    width: 178px;
    height: 178px;
    background: #fff;
    border: 1px solid #ece8de;
    margin-right: 10px;
    padding: 10px 0;
    text-align: center; }

#contentxl #askexpert {
  float: left;
  width: 250px;
  position: relative; }
  #contentxl #askexpert figure {
    float: right;
    position: relative;
    width: 105px;
    height: 145px;
    padding: 5px;
    border: 0;
    overflow: hidden; }
    #contentxl #askexpert figure span {
      position: absolute;
      top: 0;
      left: 0;
      width: 115px;
      height: 151px;
      background: url("../hammerite/img/globals/sprite.png") -375px -569px no-repeat;
      text-indent: -9999em;
      font-size: 0; }

#faq {
  width: 410px;
  padding: 0 20px 20px 0;
  float: left; }
  #faq dl {
    padding-bottom: 15px; }
    #faq dl dt {
      padding: 10px 0 11px 0;
      background: url("../hammerite/img/bg/sep-dotted-x.png") 0 100% repeat-x; }
      #faq dl dt a {
        display: block;
        background: url("../hammerite/img/globals/sprite.png") -646px -543px no-repeat;
        padding: 0 10px 0 15px; }
        #faq dl dt a.fold {
          background-position: -653px -517px; }
    #faq dl dd {
      background: #f6f4ee;
      padding: 10px 15px;
      color: #373a3b;
      line-height: 1.5em;
      display: none; }
    #faq dl a {
      text-decoration: none; }
      #faq dl a:hover, #faq dl a:visited:hover {
        text-decoration: underline; }

#faqlist {
  padding: 20px 0; }
  #faqlist dl {
    padding-bottom: 20px; }
  #faqlist dt {
    padding-bottom: 5px; }
  #faqlist a.more-faq {
    display: inline-block; }
  #faqlist dd {
    border-bottom: 1px solid #e5ebf0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: none; }

.more-faq {
  background: url("../hammerite/img/globals/sprite.png") left -461px no-repeat;
  display: block;
  margin-top: 10px;
  padding-left: 15px;
  width: 400px; }

ol {
  padding: 5px 0 1em 0;
  border-top: 1px solid #ece8de;
  border-bottom: 1px solid #ece8de;
  margin-bottom: 20px; }
  ol li {
    padding-left: 20px;
    padding: 15px 0 0 20px;
    margin-left: 40px; }
    ol li p {
      font-size: 0.75em;
      font-family: Arial;
      color: #373a3b; }

address.contact-list {
  font-style: normal;
  padding-bottom: 20px;
  line-height: 18px; }

address .telephone {
  display: block; }

.frm-contact label,
.frm-contact form input {
  float: left; }

.frm-finder input,
.frm-finder button {
  float: left; }

.frm-contact p {
  padding: 0 0 20px 150px; }

.frm-contact label {
  width: 150px;
  padding-top: 5px;
  color: #373a3b; }

.frm-contact input, .frm-contact textarea {
  border: 1px solid #c2c2c2;
  width: 220px;
  padding: 5px;
  font-family: Arial;
  font-size: 1em;
  color: #373a3b; }

.frm-finder input {
  border: 1px solid #c2c2c2;
  width: 220px;
  padding: 5px;
  font-family: Arial;
  font-size: 1em;
  color: #373a3b; }

.frm-contact input.long {
  width: 400px; }

.frm-contact textarea {
  width: 400px;
  height: 100px;
  overflow: auto; }

.frm-contact em {
  display: block;
  padding: 5px 0 0 150px;
  color: #959595;
  font-style: normal; }

.frm-contact div {
  padding-bottom: 12px; }

.frm-contact button {
  margin-left: 150px;
  border: 1px solid #02395e;
  background: url("../hammerite/img/globals/sprite.png") 100% -230px no-repeat;
  height: 30px;
  padding: 0 26px 0 16px;
  line-height: 30px;
  font-size: 1em;
  color: #c1dbe3;
  border-radius: 5px;
  text-decoration: none; }

.frm-finder button {
  margin-left: 150px;
  border: 1px solid #02395e;
  background: url("../hammerite/img/globals/sprite.png") 100% -230px no-repeat;
  height: 30px;
  padding: 0 26px 0 16px;
  line-height: 30px;
  font-size: 1em;
  color: #c1dbe3;
  border-radius: 5px;
  text-decoration: none; }

.frm-contact button:hover,
.frm-finder button:hover {
  background: url("../hammerite/img/globals/sprite.png") 100% -260px no-repeat;
  color: #fff;
  box-shadow: 0 1px 2px #8c9ba2;
  cursor: pointer; }

#content .gform_wrapper ul li {
  padding-top: 10px; }

#content .gform_wrapper input {
  width: 218px;
  border: 1px solid #C2C2C2;
  padding: 5px;
  margin: 5px 0; }

#content .gform_wrapper textarea {
  border: 1px solid #C2C2C2;
  padding: 5px;
  margin: 5px 0; }

#contentwrapper #content #contentxl .gform_wrapper .button {
  width: 230px;
  margin-bottom: 15px;
  border: 1px solid #02395e;
  background: url("../hammerite/img/globals/sprite.png") 100% -230px no-repeat;
  height: 30px;
  padding: 0 26px 0 16px;
  line-height: 30px;
  font-size: 1em;
  color: #c1dbe3;
  border-radius: 5px;
  text-decoration: none; }
  #contentwrapper #content #contentxl .gform_wrapper .button:hover {
    background: url("../hammerite/img/globals/sprite.png") 100% -260px no-repeat;
    color: #fff;
    box-shadow: 0 1px 2px #8c9ba2;
    cursor: pointer; }

.error404 #contentwrapper .wrapper {
  width: 260px;
  margin-bottom: 60px; }

.error404 #contentwrapper hgroup {
  margin-top: 30px; }

.error404 #contentwrapper #search input {
  width: 240px;
  border: 1px solid #C2C2C2;
  padding: 5px;
  font-family: Arial;
  font-size: 1em;
  color: #373A3B; }

.error404 #contentwrapper #search .btn,
.error404 #contentwrapper #search .btnShow {
  border-radius: 5px;
  margin-top: 15px;
  border: 1px solid #02395e;
  background: url("../hammerite/img/globals/sprite.png") 100% -230px no-repeat;
  height: 30px;
  padding: 0 26px 0 16px;
  line-height: 30px;
  font-size: 1em;
  color: #c1dbe3;
  text-decoration: none; }

.error404 #contentwrapper #search .btn:hover,
.error404 #contentwrapper #search .btnShow:hover {
  background: url("../hammerite/img/globals/sprite.png") 100% -260px no-repeat;
  color: #fff;
  box-shadow: 0 1px 2px #8c9ba2;
  cursor: pointer; }

.result {
  border-bottom: 1px solid #ece8de;
  margin-bottom: 15px; }
  .result dl {
    padding-bottom: 10px; }
    .result dl dd {
      padding-top: 5px; }
      .result dl dd a {
        color: #959595; }

.last-result {
  margin-bottom: 30px; }

.wishmail-form {
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 20px;
  display: none; }
  .wishmail-form fieldset {
    display: block;
    overflow: hidden;
    height: 1%;
    padding-bottom: 10px; }
  .wishmail-form label {
    display: block;
    clear: both;
    font-weight: 700;
    color: #f6821a; }
  .wishmail-form input {
    border-radius: 5px;
    padding: 4px 5px 5px;
    width: 200px;
    border: 1px solid #dad9d9;
    height: 21px;
    color: #999; }
  .wishmail-form button {
    border-radius: 5px;
    float: none;
    margin: 10px 0 0 0;
    border: 1px solid #02395e;
    background: url("../hammerite/img/globals/sprite.png") -490px -230px no-repeat;
    height: 30px;
    padding: 0 16px;
    font-size: 1em;
    color: #c1dbe3;
    clear: both;
    font-family: Arial,sans-serif; }

.slide-dualtech {
  display: block !important; }
  .slide-dualtech .impr-formula {
    height: 69px;
    background: url("../hammerite/img/globals/sprite-dualtech.png") left -681px no-repeat;
    padding: 0 0 0 17px;
    position: absolute;
    bottom: 0;
    left: 120px; }
    .slide-dualtech .impr-formula span {
      font-family: "helvetica", Arial, sans-serif;
      color: #fff;
      font-style: italic;
      font-weight: normal;
      font-size: 1em;
      display: block;
      height: 54px;
      background: url("../hammerite/img/globals/sprite-dualtech.png") right -752px no-repeat;
      padding: 11px 24px 0 4px;
      text-transform: uppercase;
      text-align: right;
      margin-top: 13px;
      text-shadow: 0 0 1px #d37012; }
      .lt-ie9 .slide-dualtech .impr-formula span {
        font-family: Arial, sans-serif !important; }
  .slide-dualtech figure {
    position: absolute;
    right: 40px;
    top: 20px; }
    .slide-dualtech figure .badge {
      position: absolute;
      top: 0;
      right: -20px; }
    .slide-dualtech figure div {
      width: auto; }
  .slide-dualtech h1 {
    margin-bottom: 0;
    text-transform: uppercase;
    float: left; }
  .slide-dualtech em {
    display: block;
    float: left;
    height: 28px;
    background: url("../hammerite/img/globals/sprite-dualtech.png") 0 -810px no-repeat;
    padding-left: 12px;
    margin-top: 11px; }
    .slide-dualtech em span {
      display: block;
      height: 28px;
      background: url("../hammerite/img/globals/sprite-dualtech.png") 100% -840px no-repeat;
      padding: 0 18px 0 2px;
      font-family: "helvetica", Arial, sans-serif;
      font-size: 1em;
      text-transform: uppercase;
      color: #fff;
      font-style: italic;
      text-shadow: 0 0 1px #d37012;
      line-height: 24px; }
      .lt-ie9 .slide-dualtech em span {
        font-family: Arial, sans-serif !important; }
  .slide-dualtech p {
    width: 360px;
    clear: both; }
  .slide-dualtech .bull-link {
    background: url("../hammerite/img/globals/sprite.png") -974px -348px no-repeat;
    color: #1186C3;
    display: block;
    padding: 10px 0 6px 14px;
    text-decoration: none;
    clear: both;
    float: left; }
    .slide-dualtech .bull-link:hover {
      color: #21cbf5;
      text-decoration: underline;
      cursor: pointer; }

#dualtech-banner {
  width: 240px;
  height: 148px;
  padding: 10px 0 0 0;
  background-position: left -875px;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 30px; }
  #dualtech-banner .packshot {
    position: absolute;
    left: 10px;
    top: 0px; }
  #dualtech-banner a {
    border-radius: 4px;
    display: block;
    position: absolute;
    left: 10px;
    bottom: 10px;
    background: url("../hammerite/img/globals/sprite-dualtech.png") left -1033px;
    height: 32px;
    padding: 0 12px;
    line-height: 32px;
    border: 1px solid #1867a5;
    color: #fff;
    font-weight: bold;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2); }
    #dualtech-banner a:hover {
      background: url("../hammerite/img/globals/sprite-dualtech.png") left -1065px;
      cursor: pointer;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.5); }
  #dualtech-banner em {
    color: #003E67;
    font-family: "helvetica", Arial, sans-serif !important;
    font-size: 1.6em;
    font-weight: normal;
    margin: 0 0 0 110px;
    text-transform: uppercase; }
    .lt-ie9 #dualtech-banner em {
      font-family: Arial, sans-serif !important; }
  #dualtech-banner .impr-formula {
    height: 69px;
    background: url("../hammerite/img/globals/sprite-dualtech.png") left -681px no-repeat;
    padding-left: 17px;
    position: absolute;
    bottom: 60px;
    left: 70px; }
    #dualtech-banner .impr-formula span {
      font-family: "helvetica", Arial, sans-serif;
      color: #fff;
      font-style: italic;
      font-weight: normal;
      font-size: 1.2em;
      display: block;
      height: 54px;
      background: url("../hammerite/img/globals/sprite-dualtech.png") right -752px no-repeat;
      padding: 8px 24px 0 8px;
      text-transform: uppercase;
      text-align: right;
      margin-top: 13px;
      text-shadow: 0 0 1px #d37012; }
      .lt-ie9 #dualtech-banner .impr-formula span {
        font-family: Arial, sans-serif !important; }

li.item-dualtech span,
li.gewinnspiel span {
  display: block;
  height: 22px;
  font-size: 11px;
  font-weight: 400;
  font-style: italic;
  position: absolute;
  top: -5px;
  font-family: arial;
  text-shadow: none;
  color: white;
  line-height: 21px;
  text-align: center; }

li.item-dualtech span {
  background-image: url("../hammerite/img/globals/sprite-dualtech.png");
  background-position: right top;
  background-repeat: no-repeat;
  right: -7px;
  width: 49px; }

li.gewinnspiel span {
  background-image: url("../hammerite/img/gewinnspiel-menu.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: auto;
  right: 0;
  padding: 0 10px; }

.page-template-page-dualtech-php #content,
.page-template-page-dualtech-gewinnspiel-php #content {
  padding: 20px 30px;
  width: 920px; }
  .page-template-page-dualtech-php #content h1,
  .page-template-page-dualtech-gewinnspiel-php #content h1 {
    text-transform: uppercase; }

.page-template-page-dualtech-php #graph,
.page-template-page-dualtech-gewinnspiel-php #graph {
  float: left;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: 1; }
  .page-template-page-dualtech-php #graph #graph-wrapper,
  .page-template-page-dualtech-gewinnspiel-php #graph #graph-wrapper {
    position: relative;
    padding-right: 30px; }
    .page-template-page-dualtech-php #graph #graph-wrapper.no-padding,
    .page-template-page-dualtech-gewinnspiel-php #graph #graph-wrapper.no-padding {
      padding: 0; }
    .page-template-page-dualtech-php #graph #graph-wrapper .badge,
    .page-template-page-dualtech-gewinnspiel-php #graph #graph-wrapper .badge {
      position: absolute;
      top: 0;
      right: 40px; }
    .page-template-page-dualtech-php #graph #graph-wrapper .conv-paint,
    .page-template-page-dualtech-gewinnspiel-php #graph #graph-wrapper .conv-paint {
      height: 107px;
      background: url("../hammerite/img/globals/sprite-dualtech.png") 100% -23px no-repeat;
      padding-right: 41px;
      position: absolute;
      top: -4px;
      left: 20px; }
      .page-template-page-dualtech-php #graph #graph-wrapper .conv-paint span,
      .page-template-page-dualtech-gewinnspiel-php #graph #graph-wrapper .conv-paint span {
        font-family: "helvetica", Arial, sans-serif;
        color: #fff;
        font-style: italic;
        font-weight: normal;
        font-size: 1.2em;
        display: block;
        height: 54px;
        background: url("../hammerite/img/globals/sprite-dualtech.png") 0 -130px no-repeat;
        padding: 15px 8px 0 28px;
        text-transform: uppercase;
        text-align: right;
        text-shadow: 0 0 1px #d37012; }
        .lt-ie9 .page-template-page-dualtech-php #graph #graph-wrapper .conv-paint span, .lt-ie9
        .page-template-page-dualtech-gewinnspiel-php #graph #graph-wrapper .conv-paint span {
          font-family: Arial, sans-serif !important; }
    .page-template-page-dualtech-php #graph #graph-wrapper .impr-formula,
    .page-template-page-dualtech-gewinnspiel-php #graph #graph-wrapper .impr-formula {
      height: 107px;
      background: url("../hammerite/img/globals/sprite-dualtech.png") left -199px no-repeat;
      padding-left: 42px;
      position: absolute;
      bottom: 80px;
      left: 247px; }
      .page-template-page-dualtech-php #graph #graph-wrapper .impr-formula span,
      .page-template-page-dualtech-gewinnspiel-php #graph #graph-wrapper .impr-formula span {
        font-family: "helvetica", Arial, sans-serif;
        color: #fff;
        font-style: italic;
        font-weight: normal;
        font-size: 1.2em;
        display: block;
        height: 54px;
        background: url("../hammerite/img/globals/sprite-dualtech.png") right -308px no-repeat;
        padding: 15px 28px 0 8px;
        text-transform: uppercase;
        text-align: right;
        margin-top: 39px;
        text-shadow: 0 0 1px #d37012; }
        .lt-ie9 .page-template-page-dualtech-php #graph #graph-wrapper .impr-formula span, .lt-ie9
        .page-template-page-dualtech-gewinnspiel-php #graph #graph-wrapper .impr-formula span {
          font-family: Arial, sans-serif !important; }

.page-template-page-dualtech-php .dualtech-text-intro,
.page-template-page-dualtech-gewinnspiel-php .dualtech-text-intro {
  width: 510px;
  font-size: 1.1em;
  padding: 0 0 30px 0; }

.page-template-page-dualtech-php #packs,
.page-template-page-dualtech-gewinnspiel-php #packs {
  border-radius: 2px;
  background: url("../hammerite/img/bg/noise-gold-light.png");
  margin-top: 40px;
  font-size: 1.1em;
  border: 0;
  position: relative;
  padding: 60px 40px 45px 340px;
  width: 540px; }
  .page-template-page-dualtech-php #packs figure,
  .page-template-page-dualtech-gewinnspiel-php #packs figure {
    position: absolute;
    left: 20px;
    top: -24px;
    border: 0; }

.page-template-page-dualtech-php #usps,
.page-template-page-dualtech-gewinnspiel-php #usps {
  padding: 30px 0 31px 20px;
  background: url("../hammerite/img/bg/dotted-blue.png") left bottom repeat-x; }
  .page-template-page-dualtech-php #usps li,
  .page-template-page-dualtech-gewinnspiel-php #usps li {
    width: 300px;
    float: left; }
    .page-template-page-dualtech-php #usps li figure,
    .page-template-page-dualtech-gewinnspiel-php #usps li figure {
      float: left;
      margin-right: 10px; }
    .page-template-page-dualtech-php #usps li h3,
    .page-template-page-dualtech-gewinnspiel-php #usps li h3 {
      text-transform: uppercase;
      font-size: 1.6em;
      margin: 10px 0 0; }
    .page-template-page-dualtech-php #usps li p,
    .page-template-page-dualtech-gewinnspiel-php #usps li p {
      padding: 0;
      font-size: 1.1em; }

.page-template-page-dualtech-php #cols,
.page-template-page-dualtech-gewinnspiel-php #cols {
  padding: 20px 0; }
  .page-template-page-dualtech-php #cols h2,
  .page-template-page-dualtech-gewinnspiel-php #cols h2 {
    text-transform: uppercase;
    margin-bottom: 15px; }
  .page-template-page-dualtech-php #cols .col,
  .page-template-page-dualtech-gewinnspiel-php #cols .col {
    float: left;
    width: 282px;
    margin-right: 37px; }
    .page-template-page-dualtech-php #cols .col.last,
    .page-template-page-dualtech-gewinnspiel-php #cols .col.last {
      margin-right: 0; }
    .page-template-page-dualtech-php #cols .col figure,
    .page-template-page-dualtech-gewinnspiel-php #cols .col figure {
      position: relative;
      width: 272px;
      height: 162px;
      overflow: hidden;
      padding: 4px;
      border: 1px solid #eee; }
    .page-template-page-dualtech-php #cols .col #improved-formula .logo,
    .page-template-page-dualtech-gewinnspiel-php #cols .col #improved-formula .logo {
      position: absolute;
      top: 50px;
      right: 10px; }
    .page-template-page-dualtech-php #cols .col #improved-formula em,
    .page-template-page-dualtech-gewinnspiel-php #cols .col #improved-formula em {
      display: block;
      position: absolute;
      top: 4px;
      padding: 10px 0 0 44px;
      right: 35px;
      background: url("../hammerite/img/globals/sprite-dualtech.png") 0 -1164px no-repeat;
      height: 50px;
      color: #fff;
      font-family: "helvetica", Arial, sans-serif;
      text-transform: uppercase;
      font-size: 1.1em; }
      .lt-ie9 .page-template-page-dualtech-php #cols .col #improved-formula em, .lt-ie9
      .page-template-page-dualtech-gewinnspiel-php #cols .col #improved-formula em {
        font-family: Arial, sans-serif !important; }

.page-template-page-dualtech-php #cols .col figure span,
.page-template-page-dualtech-gewinnspiel-php #cols .col figure span {
  display: block;
  width: 280px;
  text-indent: -9999em;
  height: 170px;
  background: url("../hammerite/img/globals/sprite-dualtech.png") 0 -380px no-repeat;
  position: absolute;
  top: 0;
  left: 0; }

.page-template-page-dualtech-php #cols .col div,
.page-template-page-dualtech-gewinnspiel-php #cols .col div {
  background: url("../hammerite/img/bg/noise-lightblue.jpg");
  padding: 10px 20px 0 20px;
  font-size: 1.1em; }

.page-template-page-dualtech-php #closure,
.page-template-page-dualtech-gewinnspiel-php #closure {
  padding: 20px 100px 20px 140px;
  font-size: 1.1em; }
  .page-template-page-dualtech-php #closure figure,
  .page-template-page-dualtech-gewinnspiel-php #closure figure {
    float: left;
    margin-right: 30px; }
  .page-template-page-dualtech-php #closure p,
  .page-template-page-dualtech-gewinnspiel-php #closure p {
    padding: 18px 0 0; }

#footer .slide-dualtech {
  position: relative; }

#footer .dualtech-label {
  position: absolute;
  top: 12px;
  left: 100px; }

#footer .bull-link {
  display: block;
  margin-bottom: 2em; }

#footer p {
  width: 100%;
  clear: both; }

#footer figure {
  clear: both;
  position: relative;
  right: auto;
  top: auto; }
  #footer figure .badge {
    left: 120px; }

.gewinnspiel-link img {
  width: 100%; }

.enter-raffle {
  padding: 60px 0;
  font-family: "helvetica", Arial, sans-serif !important;
  color: #194382; }
  .enter-raffle img {
    max-width: 100%; }
  .enter-raffle p {
    color: #194382; }
  .enter-raffle__title {
    font-size: 60px;
    font-weight: 900;
    font-family: "helvetica", Arial, sans-serif !important;
    text-transform: uppercase; }
  .enter-raffle__description {
    font-size: 24px;
    max-width: 60%; }
  .enter-raffle span.orange {
    color: #d07327 !important; }
  .enter-raffle span.lightblue {
    color: #3f80ad !important; }
  .enter-raffle__form {
    background: #efeee5;
    margin-top: 100px;
    padding: 40px;
    text-align: center; }
    .enter-raffle__form__title {
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      background: none !important; }
    .enter-raffle__form form {
      text-align: left; }
    .enter-raffle__form .gform_fields > li:not(:first-of-type) {
      margin-left: 60px; }
    .enter-raffle__form .gform_fields > li:not(:first-of-type):not(.gfield_html) {
      font-size: 16px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .enter-raffle__form .gform_fields > li:not(:first-of-type):not(.gfield_html) .gfield_label {
        width: 200px; }
      .enter-raffle__form .gform_fields > li:not(:first-of-type):not(.gfield_html) select {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none; }
      .enter-raffle__form .gform_fields > li:not(:first-of-type):not(.gfield_html) input,
      .enter-raffle__form .gform_fields > li:not(:first-of-type):not(.gfield_html) select {
        border-color: #0e1c45 !important;
        border-width: 1px;
        border-style: solid;
        border-radius: 8px;
        background: none;
        padding: 6px;
        font-size: 16px; }
    .enter-raffle__form input[type="image"] {
      border: none !important;
      width: 150px !important;
      margin-left: 60px; }
    .enter-raffle__form .gfield_html {
      font-size: 20px; }
    .enter-raffle__form .terms {
      margin-top: 60px; }
      .enter-raffle__form .terms .gfield_label {
        display: none; }
      .enter-raffle__form .terms input label {
        color: #194382; }
    .enter-raffle__form select {
      width: 228px; }
  .enter-raffle .main-question {
    text-align: center;
    width: 60%;
    margin: 0 auto; }
    .enter-raffle .main-question .gfield_label {
      font-size: 40px;
      font-weight: 400 !important;
      margin-top: 40px;
      display: block;
      text-transform: uppercase; }
    .enter-raffle .main-question .gfield_radio {
      text-align: left;
      margin: 20px 0 0 60px; }
      .enter-raffle .main-question .gfield_radio li {
        padding: 0 !important;
        margin-bottom: 20px; }
      .enter-raffle .main-question .gfield_radio label {
        font-size: 20px !important;
        color: #0e1c45 !important; }
    .enter-raffle .main-question input {
      width: auto !important; }
  .enter-raffle .hauptgewinn {
    width: 100%;
    text-align: center; }
  .enter-raffle .gfield_checkbox li {
    padding: 0 !important; }
  .enter-raffle .gform_footer {
    margin-top: 60px; }
  .enter-raffle .mandatory {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px; }
  .enter-raffle .validation_message {
    display: block;
    width: 100%;
    color: #ff0000; }

.page-template-page-dualtech-gewinnspiel-php .dualtech-text-intro {
  width: 440px; }

.page-template-page-dualtech-gewinnspiel-php #packs {
  margin-top: 100px; }

.page-template-page-dualtech-gewinnspiel-php .validation_error {
  text-align: center;
  color: #ff0000; }

.page-template-page-dualtech-gewinnspiel-php .gfield_checkbox li, .page-template-page-dualtech-gewinnspiel-php .gfield_radio li {
  position: relative; }

.page-template-page-dualtech-gewinnspiel-php .gform_wrapper form {
  position: relative; }

.page-template-page-dualtech-gewinnspiel-php .gform_wrapper .gfield_radio li label,
.page-template-page-dualtech-gewinnspiel-php .gform_wrapper .gfield_checkbox li label {
  margin-left: 40px; }

.page-template-page-dualtech-gewinnspiel-php .gform_wrapper ul li.gfield.optional .gfield_checkbox label::after,
.page-template-page-dualtech-gewinnspiel-php .gform_wrapper ul li.gfield.optional .gfield_radio label::after {
  content: "";
  opacity: 1; }

.page-template-page-dualtech-gewinnspiel-php .gform_wrapper .gfield_radio li input[type="radio"],
.page-template-page-dualtech-gewinnspiel-php .gform_wrapper .gfield_checkbox li input[type="checkbox"] {
  display: none; }

.page-template-page-dualtech-gewinnspiel-php ul.gfield_checkbox li[class^="gchoice"] label:before,
.page-template-page-dualtech-gewinnspiel-php ul.gfield_checkbox li[class^="gchoice"] label:after,
.page-template-page-dualtech-gewinnspiel-php ul.gfield_radio li[class^="gchoice"] label:before,
.page-template-page-dualtech-gewinnspiel-php ul.gfield_radio li[class^="gchoice"] label:after {
  bottom: 0;
  content: '';
  margin: auto;
  position: absolute;
  top: 0; }

.page-template-page-dualtech-gewinnspiel-php ul.gfield_checkbox li[class^="gchoice"] label:after,
.page-template-page-dualtech-gewinnspiel-php ul.gfield_radio li[class^="gchoice"] label:after {
  content: "";
  border: solid 1px #0e1c45;
  border-radius: 8px;
  display: inline-block;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  transition: left .25s, background-color .13s;
  width: 24px; }

.page-template-page-dualtech-gewinnspiel-php ul.gfield_checkbox input[type="checkbox"]:checked + label:before,
.page-template-page-dualtech-gewinnspiel-php ul.gfield_radio input[type="radio"]:checked + label:before {
  content: 'X';
  color: #0e1c45;
  left: 7px;
  /*.75px*/
  top: -5px;
  z-index: 9;
  border-radius: 8px;
  height: 24px;
  width: 24px;
  font-size: 24px; }

/* Adjustments for responsive */
#summary {
  clear: both; }

#summary p {
  padding-top: 10px; }

body.video #contentwrapper #content,
body.browse #contentwrapper #content {
  display: none; }

body.video #contentwrapper #redirect,
body.browse #contentwrapper #redirect {
  display: block;
  padding: 10px 20px;
  width: 940px; }

/*	Media queries - Mobile  ------------------------------------------	*/
@media only screen and (max-width: 767px) {
  /* Aanpassingen voor het menu */
  header #topbar,
  nav .wrapper .lang,
  nav .wrapper .shoppinglist {
    display: none; }
  body > nav, body > .outer-scroll > nav {
    background: url("../hammerite/img/bg/mobile-bg-menu.png");
    left: -240px;
    top: 0;
    bottom: 0;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 9999;
    width: 240px;
    padding: 0; }
  /* Aanpassingen voor het menu */
  html {
    -webkit-text-size-adjust: none;
    /* Prevent font scaling in landscape */
    height: 100%; }
  body {
    background: none;
    overflow-x: hidden;
    height: 100%; }
  body.home {
    background: none; }
  body.home #contentwrapper {
    position: relative;
    top: -62px; }
  /* Hide for this viewport */
  #prodfinder a#tooltip, #articlewrapper, #columns .middle, .blocks .middle, #singleprod, #share, #downloads, #content iframe, #content #ctablock,
  #content #askexpert, #compare, .review .meta, #user-reviews-element > p, #content #sidebar, #contentwrapper #content .intro p, #contentwrapper #content .intro a {
    display: none; }
  .wrapper {
    width: auto;
    margin: 0;
    padding: 0; }
  #content form {
    padding: 0 15px;
    width: 260px;
    float: none; }
  #content input {
    margin-bottom: 10px;
    float: none; }
  #content button {
    float: none;
    line-height: 12px; }
  .list {
    margin: 0 15px;
    width: 260px; }
  .list.border {
    border-bottom: 1px dotted #d7d8d8;
    margin-bottom: 20px; }
  /* Typography */
  h1, #prodfinder h2 {
    font: normal 28px/28px 'helvetica', arial; }
  #contentxl > h2 {
    padding: 0 15px; }
  .tabs h2 {
    font: bold 12px arial; }
  #howto-videos h2, #faq h2, #contentxl #related h3 {
    font: normal 18px 'helvetica', arial;
    margin: 0 0 10px 15px;
    color: #373a3b; }
  #contentwrapper #content #contentxl hgroup h2 {
    font-size: 16px; }
  /* Header */
  header {
    background: url("../hammerite/img/bg/mobile-bg-header.png") repeat-x;
    height: 92px;
    margin: 0 0 20px 0;
    padding-top: 10px;
    width: 100%;
    position: relative; }
  header .wrapper {
    width: 260px;
    margin: 0 auto; }
  header .wrapper a.logo {
    background: url("../hammerite/img/globals/hammerite-mobile.png") no-repeat;
    width: 58px;
    height: 59px;
    right: 0;
    top: inherit;
    left: inherit;
    text-indent: -9999em; }
  header .wrapper .menu-trigger {
    float: left;
    background: #00b7f3 url("../hammerite/img/globals/mobile-sprite.png") 11px -157px no-repeat;
    border: 1px solid #013f65;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    height: 16px;
    line-height: 16px;
    padding: 7px 0 7px 36px;
    text-decoration: none;
    text-shadow: 1px 1px #0090c0;
    width: 42px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    box-shadow: inset 0 1px #80defd; }
  header .wrapper .menu-trigger.close {
    background: #00b7f3 url("../hammerite/img/globals/mobile-sprite.png") 11px -182px no-repeat; }
  header .wrapper .menu-trigger:active,
  header .wrapper .menu-trigger.close:active {
    background-color: #ff8610;
    box-shadow: inset 0 1px #ffc287;
    text-shadow: 1px 1px #cc6b0b; }
  .home header .wrapper a.logo {
    background: url("../hammerite/img/globals/hammerite-home-mobile.png") no-repeat;
    width: 98px;
    height: 101px; }
  .home.ru_ru header .wrapper a.logo,
  .home.pl_pl header .wrapper a.logo {
    background: url("../hammerite/img/globals/hammerite-mobile.png") no-repeat;
    width: 58px;
    height: 59px; }
  /* Breadcrumbs */
  #breadcrumbs {
    background: url("../hammerite/img/bg/mobile-bg-noise.png");
    height: 42px;
    width: 100%;
    margin-top: 50px;
    padding: 0;
    border: 0; }
  /* HTML Concept7 */
  #breadcrumbs li {
    width: 260px;
    padding: 0;
    margin: 0 auto;
    display: none;
    height: 42px;
    float: none;
    font-size: 12px; }
  #breadcrumbs li a:before {
    content: '<';
    display: block;
    width: 12px;
    float: left; }
  #breadcrumbs li a {
    background: none;
    color: #ccd6dd;
    display: block;
    float: left;
    line-height: 42px;
    text-decoration: none; }
  /* DEV */
  #breadcrumbs > span > span {
    width: 260px;
    padding: 0;
    margin: 0 auto;
    height: 42px;
    float: none;
    font-size: 12px;
    display: none; }
  #breadcrumbs > span > span a:before {
    content: '<';
    display: block;
    width: 12px;
    float: left; }
  #breadcrumbs > span > span a {
    background: none;
    color: #ccd6dd;
    display: block;
    float: left;
    line-height: 42px;
    text-decoration: none; }
  /* Mobile nav */
  .scroll-nav {
    width: 260px; }
  .scroll-nav ul li {
    width: 240px;
    display: block;
    clear: both; }
  .scroll-nav ul li a {
    font: normal 14px 'helvetica', arial;
    padding: 0 15px 0 47px !important;
    background: none !important;
    text-shadow: 0 1px 1px #000;
    border-top: 1px solid #033758;
    border-bottom: 1px solid #011b2d;
    display: block;
    line-height: 44px;
    height: 44px;
    color: #fff;
    text-decoration: none; }
  .scroll-nav ul li a:hover {
    color: #fff; }
  .scroll-nav .sub-menu {
    display: none !important; }
  /* Mobile nav icons */
  .scroll-nav ul li a {
    background: url("../hammerite/img/globals/mobile-sprite.png") -1000px -1000px no-repeat;
    position: relative; }
  .scroll-nav ul li.home a {
    background-position: 15px -206px; }
  .scroll-nav ul li.products a {
    background-position: 14px -259px; }
  .scroll-nav ul li.applications a {
    background-position: 16px -308px; }
  .scroll-nav ul li.how-to a {
    background-position: -164px -407px; }
  .scroll-nav ul li.why-hammerite a {
    background-position: -225px -206px; }
  .scroll-nav ul li.contact a {
    background-position: -227px -256px; }
  .scroll-nav ul li.faq a {
    background-position: -224px -307px; }
  .scroll-nav ul li.about-us a {
    background-position: -166px -447px; }
  .scroll-nav ul li.news a {
    background-position: -224px -357px; }
  .scroll-nav ul li.storefinder a {
    background-position: -165px -491px; }
  nav #search {
    height: 30px;
    display: block;
    float: none;
    z-index: 10;
    position: relative;
    padding: 13px 15px 15px 15px;
    border-bottom: 1px solid #00182a;
    background: #073957;
    background: linear-gradient(to bottom, rgba(7, 57, 87, 0.5) 1%, rgba(5, 51, 83, 0.5) 100%); }
  nav #search input[type=search] {
    box-sizing: border-box;
    -webkit-appearance: textfield;
    color: #4e616d;
    display: block;
    position: absolute;
    top: 13px;
    left: 15px;
    padding-left: 5px;
    padding-right: 27px;
    width: 205px;
    height: 33px;
    line-height: 33px !important;
    border-radius: 12px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    background: #021c2e;
    border: 0;
    box-shadow: 0 1px #1d435d;
    -moz-box-shadow: 0 1px #1d435d;
    -webkit-box-shadow: 0 1px #1d435d; }
  ::-webkit-search-cancel-button {
    display: none; }
  nav #search input[type=search]:focus {
    color: #acc2d0; }
  nav #search .btn,
  nav #search .btnShow {
    background: url("../hammerite/img/globals/mobile-sprite.png") right 0 no-repeat;
    display: block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    position: absolute;
    top: 20px;
    right: 25px; }
  nav #search .btnShow {
    background-position: right -33px; }
  /* Current item - current = HTML, current_page_item = DEV */
  .scroll-nav ul li.current,
  .scroll-nav ul li.current_page_item {
    background: #ff8d1d;
    background: linear-gradient(to bottom, #ff8d1d 0%, #ff7f02 100%);
    position: relative; }
  .scroll-nav ul li.current > a,
  .scroll-nav ul li.current_page_item > a {
    position: relative;
    z-index: 10;
    border-top: 1px solid #000;
    box-shadow: inset 0 1px #eeb985;
    -moz-box-shadow: inset 0 1px #eeb985;
    -webkit-box-shadow: inset 0 1px #eeb985;
    text-shadow: 0 1px 1px #c36508; }
  .scroll-nav ul li.current > a:before,
  .scroll-nav ul li.current_page_item > a:before {
    content: '';
    z-index: 100;
    position: absolute;
    width: 28px;
    height: 28px;
    right: 10px;
    top: 15px; }
  /* Content */
  #contentwrapper {
    background: none;
    width: 320px;
    margin: 0 auto; }
  #contentwrapper .wrapper {
    background: none;
    border: 0;
    padding: 0;
    width: 290px;
    margin: 0 auto;
    position: inherit; }
  #contentwrapper #content {
    margin-top: 20px;
    width: 290px;
    padding: 0;
    position: inherit; }
  #contentwrapper #content h1 {
    margin-bottom: 10px;
    padding: 0 15px; }
  #contentwrapper #content p {
    padding: 0 15px 15px; }
  #contentwrapper #content #summary p {
    padding: 0; }
  #contentwrapper #content #contentxl {
    width: 290px;
    float: none;
    padding: 0;
    margin: 0;
    position: inherit; }
  #contentwrapper #content #contentxl hgroup h2 {
    padding: 0 15px; }
  #contentwrapper #content #contentxl ul li,
  #contentwrapper #content #contentxl ol li {
    padding-left: 10px;
    padding-top: 10px; }
  #contentwrapper #content #contentxl ul.list li {
    padding: 4px 0 0 15px; }
  /* DEV */
  #contentwrapper #content #contentxl ul.usplist li {
    padding: 4px 0 8px 22px; }
  /* HTML Concept7 */
  #contentwrapper #content #contentxl ol li p {
    padding-left: 0; }
  #contentwrapper #content #contentxl > div {
    margin: 0 15px; }
  #contentxl h3 {
    font: 700 12px arial;
    margin: 20px 15px 5px; }
  .blog #contentxl h3 {
    margin-top: 0; }
  .blog #contentxl article {
    margin-bottom: 20px; }
  #contentxl article h2 {
    margin: 20px 15px 5px; }
  /* DEV */
  #contentxl article h3 {
    font: normal 2em 'Helvetica', arial;
    color: #003E67; }
  #contentwrapper #content .split {
    width: 290px;
    float: none;
    padding: 0;
    margin: 0;
    position: inherit; }
  #contentwrapper #content .split h2,
  #contentwrapper #content .split p {
    padding: 0 15px; }
  #contentwrapper #content .split h2 {
    padding-top: 5px; }
  #contentwrapper #content .split p {
    padding-bottom: 15px; }
  #contentwrapper #content article figure,
  #contentwrapper #content .split figure {
    width: 290px; }
  #contentwrapper #content .img-confirm {
    margin: 0 auto 10px auto;
    width: 100%; }
  #contentwrapper #content article figure span,
  #contentwrapper #content .split figure span,
  #contentwrapper #content .img-confirm span {
    height: auto;
    width: auto; }
  #contentwrapper #content article img,
  #contentwrapper #content .split img,
  #contentwrapper #content .img-confirm img {
    width: 100%; }
  #contentwrapper #content .contact-list {
    padding: 0 15px 20px; }
  #contentwrapper #content article .cta,
  #contentwrapper #content article .btnOrange {
    margin-bottom: 35px; }
  #contentwrapper #content article .btnOrange,
  #contentwrapper #content article .bull {
    margin-left: 15px; }
  /* HTML Concept7 */
  #contentwrapper #content #contact {
    padding: 0; }
  #contentwrapper #content #contact h2,
  #contentwrapper #content #contact div {
    padding: 0 15px;
    margin: 10px 0; }
  #contentwrapper #content #contact .error {
    padding: 10px 14px; }
  #contentwrapper #content #contact label {
    padding-bottom: 5px; }
  #contentwrapper #content #contact em {
    padding: 0; }
  #contentwrapper #content #contact input,
  #contentwrapper #content #contact textarea {
    width: 218px; }
  #contentwrapper #content #contact button {
    margin: 0 0 20px 15px; }
  /* DEV */
  #contentwrapper #content #gform_wrapper_1 h3,
  #contentwrapper #content #gform_wrapper_1 div {
    margin: 10px 0; }
  #contentwrapper #content #gform_wrapper_1 form {
    padding: 0; }
  #contentwrapper #content #gform_wrapper_1 h3 {
    font: normal 22px/24px 'helvetica', arial;
    margin-top: 20px; }
  #contentwrapper #content #gform_wrapper_1 label {
    padding-bottom: 5px; }
  #contentwrapper #content #gform_wrapper_1 input,
  #contentwrapper #content #gform_wrapper_1 textarea {
    width: 218px; }
  #contentwrapper #content #gform_wrapper_1 button {
    margin: 0 0 20px 15px; }
  #contentwrapper #content #sidebarxl {
    margin: 0 15px 25px 15px;
    width: 260px; }
  #contentwrapper #content .intro a {
    padding-left: 15px; }
  #prodfinder select {
    width: 260px;
    font-size: 16px;
    margin-bottom: 12px; }
  /* Homepage intro */
  #carousel {
    display: block;
    float: none;
    height: auto;
    width: 100%;
    margin: 0 0 25px 0; }
    #carousel #carouselwrapper {
      display: block;
      float: none;
      height: auto;
      width: 100%; }
    #carousel section {
      float: none;
      height: auto;
      width: 100%;
      margin: 0;
      padding: 0;
      position: static;
      top: auto;
      left: auto; }
      #carousel section > div {
        height: auto;
        width: 100%;
        padding: 0; }
        #carousel section > div figure {
          float: none;
          margin: 0; }
          #carousel section > div figure img {
            max-width: 100%;
            height: auto; }
        #carousel section > div .textwidget img {
          max-width: 100%;
          height: auto;
          margin-bottom: 10px; }
    #carousel .slide-dualtech figure {
      float: none;
      margin: 0;
      position: static;
      right: auto;
      top: auto; }
  /* Products */
  #refine,
  #prodwrapper #refined {
    display: none; }
  #content #prodwrapper {
    padding-left: 0px;
    padding-right: 0px;
    width: 290px; }
  #content #prodwrapper .product {
    padding: 0 15px 20px; }
  #content #prodwrapper .product div {
    width: 160px;
    float: right; }
  #content #prodwrapper .product ul.list {
    width: auto; }
  #content #prodwrapper .product .rating-wrap {
    float: none;
    margin-bottom: 15px;
    width: 260px; }
  #content #prodwrapper .product .rating {
    margin: 0 15px 0 0; }
  /* HTML Concept7 */
  #prodwrapper .product figure {
    width: 80px;
    height: 80px;
    margin-right: 0; }
  #prodwrapper .product figure img {
    width: 100%;
    height: 100%; }
  /* DEV */
  .single-product #contentxl figure {
    width: 270px;
    margin: 15px 0 0 15px; }
  .single-product figure img {
    width: 100%;
    height: 100%; }
  /* Search */
  #contentwrapper .result dl {
    padding: 0 15px 10px; }
  /* Pagination */
  .paging {
    padding-left: 15px; }
  .paging li {
    padding-bottom: 30px; }
  /* FAQ */
  #faq {
    width: 260px;
    margin: 0 !important; }
  #contentwrapper #faqlist {
    padding: 20px 15px 0 20px; }
  /* Buttons */
  .cta {
    margin: 0 0 20px 15px; }
  /* Usp list*/
  .usplist.float {
    float: none;
    margin: 0 auto;
    width: 260px; }
  /* Storefinder */
  #frm-storefinder {
    margin-bottom: 35px; }
  /* Productfinder */
  #prodfinder {
    float: none;
    height: auto;
    margin-bottom: 20px;
    padding: 27px 15px 5px 15px; }
  #prodfinder h2 {
    margin: 2px 0 3px 0; }
  #prodfinder input[type=submit] {
    background: url("../hammerite/img/globals/mobile-sprite.png") right -100px;
    margin-top: 5px;
    border: 1px solid #f15c03;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #fff;
    display: block;
    float: left;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 35px 0 0;
    width: 140px;
    text-decoration: none;
    text-shadow: 0 1px 0 #f15c03; }
  #contentwrapper #prodfinder input[type=submit]:active {
    background: url("../hammerite/img/globals/mobile-sprite.png") right -138px;
    cursor: pointer;
    box-shadow: 0 1px 2px #ccc;
    color: #fff; }
  #prodfinder p#surface {
    clear: both;
    padding-top: 15px; }
  #prodfinder select.chosen {
    width: 260px;
    margin-bottom: 10px; }
  /* Columns */
  #columns {
    clear: both;
    width: 290px;
    padding: 0; }
  #columns > div {
    background: none;
    float: none;
    width: 260px;
    padding: 0 15px; }
  #columns > div.first ul {
    margin-bottom: 20px; }
  #columns > div.last {
    padding: 0 15px;
    width: 260px; }
  #columns > div.widget {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0; }
  #columns a.bull {
    padding-right: 3px; }
  #columns #watch-vids {
    margin-top: 2px; }
  /* Video */
  #columns #vidwrapper {
    border-bottom: 0;
    height: 739px;
    padding: 20px 0;
    width: 290px; }
  #columns #vidwrapper #vidcarousel,
  #columns #vidwrapper #vidcarousel div {
    background: none;
    float: left;
    padding: 0;
    width: 260px; }
  #columns #vidwrapper #vidcarousel div {
    clear: both;
    display: none;
    float: none;
    margin-bottom: 20px; }
  #columns #vidwrapper #vidcarousel div:first-child,
  #columns #vidwrapper #vidcarousel div:first-child + div,
  #columns #vidwrapper #vidcarousel div:first-child + div + div {
    display: block; }
  #columns #vidwrapper ul {
    display: none; }
  /* Tabs */
  .tabs {
    width: 290px;
    margin-bottom: 20px; }
  .tabs > div {
    padding: 0; }
  .tabs p, .tabs span {
    padding: 0 15px;
    display: block; }
  .tabs .mobile-tab-nav {
    width: 290px; }
  .tabs .mobile-tab-nav > li {
    margin-bottom: 5px;
    clear: both; }
  .tabs .mobile-tab-nav > li > a {
    background: #f6f4ee url("../hammerite/img/globals/mobile-sprite.png") right -447px;
    padding-left: 14px;
    font: normal 16px 'helvetica', arial;
    color: #153964;
    display: block;
    height: 38px;
    border: 1px solid #ece8de;
    line-height: 38px;
    text-decoration: none; }
  .tabs .mobile-tab-nav > li > a.selected {
    background: #fff url("../hammerite/img/globals/mobile-sprite.png") right -478px no-repeat;
    height: 37px;
    border-top: 2px solid #00b3ed; }
  .tabs .mobile-tab-nav > li > a > span {
    display: none; }
  .tabs .tab-li > div > h2 {
    padding: 0 15px; }
  .tabs .note {
    background: #fcebb5 url("../hammerite/img/globals/mobile-sprite.png") 15px -377px no-repeat;
    padding: 15px 0 15px 53px; }
  .tabs .step .note {
    padding: 15px 0 15px 53px;
    width: 140px;
    margin: 10px 0 10px 15px;
    padding: 15px 15px 15px 50px;
    width: 155px; }
  /* Containers tabs */
  #product-features-element,
  #usage-guide-element,
  #user-reviews-element,
  #where-to-buy-element {
    width: 290px;
    border-bottom: 1px dotted #ece8de;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0; }
  .single-product #where-to-buy-element {
    margin: 0;
    padding: 0; }
  /* DEV */
  #where-to-buy-element h2 {
    margin-top: 15px; }
  #where-to-buy-element p {
    padding: 0 15px 15px 15px; }
  /* Slider */
  #slider {
    position: relative;
    width: 290px;
    height: 221px;
    margin-bottom: 20px;
    padding: 0; }
  #slider #sliderwrapper {
    margin-top: 18px;
    height: 221px;
    padding: 0; }
  #slider #sliderwrapper section {
    width: 290px; }
  #slider #sliderwrapper section img {
    width: 290px; }
  #slider #sliderwrapper section figure {
    float: none;
    width: 290px; }
  #slider #sliderwrapper section div {
    width: 290px;
    height: 40px;
    background: none;
    clear: both;
    padding: 0;
    float: none;
    position: absolute;
    top: 163px; }
  #slider #sliderwrapper section div h3,
  #slider #sliderwrapper section div p {
    display: none; }
  #slider #sliderwrapper section div .cta-mobile {
    display: block;
    text-align: center;
    line-height: 40px;
    background: #ece8de;
    width: 290px;
    height: 40px; }
  #slider #controls {
    position: absolute;
    top: 0;
    bottom: auto;
    left: auto;
    margin-left: 129px; }
  #slider #controls .play, #slider #controls .pause {
    display: none; }
  #slider #controls li {
    margin-right: 4px; }
  #slider #controls li a,
  #slider #controls li a:hover {
    background: url("../hammerite/img/globals/mobile-sprite.png") -9px -110px;
    width: 8px;
    height: 8px;
    display: block;
    text-indent: -9999px; }
  #slider #controls li.current a,
  #slider #controls li.current a:hover {
    background: url("../hammerite/img/globals/mobile-sprite.png") left -110px;
    width: 8px;
    height: 8px;
    display: block;
    text-indent: -9999px; }
  #slider #prev {
    background: url("../hammerite/img/globals/mobile-sprite.png") no-repeat left 0;
    display: block;
    font-size: 0;
    height: 50px;
    left: 0;
    position: absolute;
    text-indent: -9999px;
    top: 74px;
    width: 50px;
    z-index: 100; }
  #slider #prev:active,
  #slider #prev:hover {
    background: url("../hammerite/img/globals/mobile-sprite.png") no-repeat -50px top;
    display: block;
    font-size: 0;
    height: 50px;
    left: 0;
    position: absolute;
    top: 74px;
    width: 50px;
    z-index: 100; }
  #slider #next {
    background: url("../hammerite/img/globals/mobile-sprite.png") no-repeat left -50px;
    display: block;
    font-size: 0;
    height: 50px;
    position: absolute;
    text-indent: -9999px;
    right: 0;
    top: 74px;
    width: 50px; }
  #slider #next:active,
  #slider #next:hover {
    background: url("../hammerite/img/globals/mobile-sprite.png") no-repeat -50px -50px;
    display: block;
    font-size: 0;
    height: 50px;
    right: 0;
    position: absolute;
    top: 74px;
    width: 50px; }
  /* Product summary */
  #summary {
    position: relative;
    width: 260px;
    margin-top: 45px;
    padding: 0 15px; }
  #summary p {
    width: 160px;
    float: right;
    padding: 0; }
  #summary figure.productimg {
    border: 1px solid #ece8de;
    width: 80px;
    height: 80px;
    float: left;
    margin-top: 3px;
    position: relative; }
  #summary figure.productimg img {
    width: 100%;
    height: 100%; }
  /* Product features */
  #procs {
    border: 0;
    padding: 0;
    width: 290px;
    margin-top: 10px; }
  #procs li {
    background: none;
    float: none;
    text-align: right;
    width: 260px;
    line-height: 37px;
    height: 37px;
    border: 1px dotted #d7d8d8;
    border-width: 0 0 1px 0;
    margin: 0;
    padding: 0 15px;
    color: #373a3b;
    font-weight: bold; }
  #procs li em {
    color: inherit;
    display: inline-block;
    float: left;
    font-weight: normal;
    padding: 0; }
  /* Features */
  dl.features {
    width: 260px;
    padding: 0 15px; }
  dl.features dd {
    width: 135px;
    float: left;
    clear: none; }
  dl.features dd span {
    padding: 0; }
  dl.features dt {
    width: 125px;
    float: left; }
  /* Review */
  .review {
    padding: 20px 15px !important;
    width: 260px; }
  .review h2 {
    float: none;
    clear: both;
    margin-bottom: 2px; }
  .review .rating {
    float: none;
    padding: 0;
    margin: 0 0 10px 0; }
  .review em {
    padding-bottom: 10px; }
  .review p {
    padding: 0; }
  .review .pros, .review .cons {
    width: 260px;
    float: none; }
  .response {
    width: 258px;
    padding: 15px !important; }
  .response p {
    padding: 0; }
  aside.inzet {
    background: none;
    margin: 0;
    padding: 0;
    width: auto; }
  aside.inzet h3 {
    padding: 0 15px; }
  aside.inzet em {
    padding: 0 15px; }
  #colors {
    border-bottom: 1px solid #ece8de;
    width: 260px;
    margin-bottom: 14px;
    padding: 0 15px; }
  .rating {
    margin: 0 15px; }
  #faq {
    padding: 0; }
  #faq dl {
    width: 260px;
    padding: 0 15px; }
  #faq a.bull {
    margin: 15px; }
  /* Related products */
  #related {
    width: 275px;
    margin-bottom: 20px;
    padding: 15px 15px 15px 0; }
  #related figure {
    width: 120px;
    height: 146px;
    float: left;
    margin: 10px 15px 0 15px;
    padding: 0; }
  #related figure + figure {
    margin: 10px 0 0 0; }
  #related img {
    width: 75%; }
  #related figcaption {
    padding: 0 15px; }
  /* How to video's */
  #columns #howto-videos {
    padding: 0;
    width: 290px;
    margin-bottom: 10px; }
  #howto-videos {
    margin-bottom: 20px; }
  #howto-videos h3 {
    padding: 0 15px;
    color: #373a3b; }
  #howto-videos .video {
    width: 260px;
    padding: 15px;
    background: url("../hammerite/img/bg/noise-blue.png") repeat;
    height: 90px;
    margin-bottom: 10px; }
  #howto-videos .video figure {
    position: relative;
    float: left;
    width: 110px;
    height: 90px; }
  #howto-videos .video figure img {
    z-index: 1;
    position: absolute; }
  #howto-videos .video figure span.play {
    position: absolute;
    top: 25px;
    left: 35px;
    display: block;
    width: 40px;
    height: 40px;
    z-index: 10;
    text-indent: -9999px;
    background: url(img/globals/mobile-sprite.png) -100px 0; }
  #howto-videos .video div {
    float: right;
    width: 135px;
    padding: 0;
    background: none; }
  #howto-videos .video div a {
    line-height: 1.6em;
    text-decoration: none; }
  #howto-videos .video div span.duration {
    margin-top: 5px;
    line-height: 1.8em;
    color: #7698ad;
    display: block;
    height: 18px;
    padding-left: 20px;
    background: url(img/globals/mobile-sprite.png) -20px -120px; }
  #howto-videos a.bull {
    margin: 0 20px; }
  /* Video detail */
  #videowrapper {
    padding-top: 30px;
    margin-bottom: 20px;
    border-bottom: 1px dotted #d7d8d8; }
  #videowrapper #currentvideo {
    background: #ececec;
    clear: both;
    width: 290px;
    height: 160px; }
  #videowrapper h2 {
    color: #373a3b;
    font: bold 12px/12px arial;
    margin: 20px 15px 0 15px; }
  #videowrapper .duration {
    margin: 5px 15px 10px 15px;
    line-height: 1.8em;
    color: #7698ad;
    display: block;
    height: 18px;
    width: 120px;
    padding-left: 20px;
    background: url(img/globals/mobile-sprite.png) -20px -120px; }
  #videowrapper p {
    margin: 0 15px;
    display: block; }
  /* Maps */
  #map_search {
    overflow: auto;
    padding: 15px !important; }
  #map_search td {
    display: block;
    padding-bottom: 10px; }
  #map_search .submit,
  #map_search .select {
    width: 228px; }
  #map_search div.result {
    padding: 15px; }
  #map_search div.result_address {
    padding: 0; }
  #map_search div.result_link a {
    text-align: left; }
  #map_search div.result h3 {
    padding-left: 0;
    line-height: 20px; }
  #singlemap {
    overflow: hidden; }
  /* Contact form */
  #contentwrapper #content #contentxl .gform_wrapper ul li {
    padding: 0; }
  #content .gform_wrapper input,
  #content .gform_wrapper textarea {
    margin-top: 0; }
  /* Footer */
  footer {
    margin: 0;
    width: 100%;
    background: none;
    padding: 0;
    z-index: 200; }
  footer div.footer,
  footer div#claim {
    display: none; }
  footer #mobile-footer {
    background: url("../hammerite/img/bg/mobile-bg-footer.png") repeat-x;
    height: 50px; }
  footer #mobile-footer .wrapper {
    margin: 0 auto;
    width: 400px;
    height: 50px;
    text-align: center; }
  footer #mobile-footer ul {
    display: inline-block;
    line-height: 58px; }
  footer #mobile-footer li {
    float: left;
    display: block; }
  footer #mobile-footer li:first-child a {
    padding-left: 0; }
  footer #mobile-footer li a {
    color: #b4cbd7;
    text-decoration: none;
    padding: 0 10px;
    border-right: 1px dotted #80a5b9; }
  footer #mobile-footer li.last a {
    border: 0;
    padding-right: 0; }
  footer #mobile-footer li a:active {
    color: #fff; }
  footer #to-top {
    background: url("../hammerite/img/bg/mobile-bg-noise.png");
    bottom: 0;
    color: #678599;
    left: 0;
    right: 0;
    text-align: center;
    height: 42px;
    line-height: 42px; }
  footer #to-top a {
    color: #678599;
    text-decoration: none; }
  /* Products blocks */
  #content #prodwrapper .product div .btnCompare {
    display: none; }
  #content #prodwrapper .product div p {
    padding: 0; }
  #content #prodwrapper .product ul.list {
    margin: 0; }
  /* On mobile only shown */
  body.video #contentwrapper #content,
  body.browse #contentwrapper #content {
    display: block; }
  body.video #contentwrapper #redirect,
  body.browse #contentwrapper #redirect {
    display: none; }
  /* Promos */
  #thepromos {
    position: static; }
  #promos {
    background: #e7eff3;
    width: auto;
    padding: 0;
    -o-box-shadow: inset 0 -6px 6px -3px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 -6px 6px -3px rgba(0, 0, 0, 0.2); }
  .promo {
    background: transparent !important;
    width: 270px;
    padding: 20px 15px 25px; }
  .promo a.cta {
    padding: 0 0 0 26px;
    width: 0;
    text-indent: -1234em;
    right: 0;
    top: 41px; }
  a#close-promos, a#close-promos:visited {
    margin: 3px 13px 0 0; }
  /* Dualtech Landingpage */
  .item-dualtech span {
    display: block;
    width: 49px;
    height: 22px;
    background: url("../hammerite/img/globals/sprite-dualtech.png") right top no-repeat;
    text-indent: -9999em;
    font-size: 0em;
    position: absolute;
    top: -2px;
    right: 4px;
    z-index: 10; }
  ccc #content {
    padding: 20px 30px;
    width: 920px; }
  .page-template-page-dualtech-php #content h1 {
    text-transform: uppercase;
    font-size: 2.2em; }
  .page-template-page-dualtech-php #graph {
    float: left;
    position: relative;
    z-index: 1;
    top: 8px;
    height: 300px; }
  .page-template-page-dualtech-php #graph #graph-wrapper {
    position: relative;
    width: 290px; }
  .page-template-page-dualtech-php #graph #graph-wrapper img {
    width: 290px; }
  .page-template-page-dualtech-php #graph #graph-wrapper .conv-paint {
    height: 72px;
    background: url("../hammerite/img/globals/sprite-dualtech.png") right -550px no-repeat;
    padding-right: 19px;
    position: absolute;
    top: -4px;
    left: 10px; }
  .page-template-page-dualtech-php #graph #graph-wrapper .conv-paint span {
    font-family: 'helvetica';
    color: #fff;
    font-style: italic;
    font-weight: normal;
    font-size: 1em;
    display: block;
    height: 47px;
    background: url("../hammerite/img/globals/sprite-dualtech.png") left -622px no-repeat;
    padding: 11px 8px 0 24px;
    text-transform: uppercase;
    text-align: right;
    text-shadow: 0 0 1px #d37012; }
  .page-template-page-dualtech-php #graph #graph-wrapper .impr-formula {
    height: 69px;
    background: url("../hammerite/img/globals/sprite-dualtech.png") left -681px no-repeat;
    padding-left: 17px;
    position: absolute;
    bottom: 80px;
    left: 170px; }
  .page-template-page-dualtech-php #graph #graph-wrapper .impr-formula span {
    font-family: 'helvetica';
    color: #fff;
    font-style: italic;
    font-weight: normal;
    font-size: 1em;
    display: block;
    height: 54px;
    background: url("../hammerite/img/globals/sprite-dualtech.png") right -752px no-repeat;
    padding: 11px 24px 0 8px;
    text-transform: uppercase;
    text-align: right;
    margin-top: 13px;
    text-shadow: 0 0 1px #d37012; }
  .page-template-page-dualtech-php #graph #graph-wrapper .badge {
    width: 98px;
    height: 98px; }
  .page-template-page-dualtech-php .dualtech-text-intro {
    width: 260px;
    font-size: 1.1em;
    padding: 0 0 30px 0;
    display: none; }
  .page-template-page-dualtech-php #packs {
    background: url("../hammerite/img/bg/noise-gold-light.png");
    margin-top: 40px;
    font-size: 1.1em;
    border-radius: 2px;
    border: 0;
    position: relative;
    padding: 20px 0;
    width: 290px;
    clear: both; }
  .page-template-page-dualtech-php #packs h2 {
    text-transform: uppercase;
    margin-left: 20px;
    font-size: 1.8em; }
  .page-template-page-dualtech-php #packs p {
    padding: 0 20px !important; }
  .page-template-page-dualtech-php #packs figure {
    position: absolute;
    left: 20px;
    top: -24px;
    border: 0;
    display: none; }
  .page-template-page-dualtech-php #usps {
    padding: 30px 0 0 0;
    background: url("../hammerite/img/bg/dotted-blue.png") left bottom repeat-x; }
  .page-template-page-dualtech-php #usps li {
    width: 300px;
    float: none;
    padding-bottom: 20px;
    min-height: 70px; }
  .page-template-page-dualtech-php #usps li figure {
    float: left;
    margin-right: 10px; }
  .page-template-page-dualtech-php #usps li h3 {
    text-transform: uppercase;
    font-size: 1.6em;
    margin: 10px 0 0 0;
    float: left; }
  .page-template-page-dualtech-php #usps li p {
    padding: 0 !important;
    font-size: 1.1em;
    float: left; }
  .page-template-page-dualtech-php #cols {
    padding: 20px 4px; }
  .page-template-page-dualtech-php #cols h2 {
    text-transform: uppercase;
    margin-bottom: 15px; }
  .page-template-page-dualtech-php #cols .col {
    float: left;
    width: 282px;
    margin-right: 37px;
    margin-bottom: 20px; }
  .page-template-page-dualtech-php #cols .col.last {
    margin-right: 0; }
  .page-template-page-dualtech-php #cols .col figure {
    position: relative;
    width: 272px;
    height: 162px;
    overflow: hidden;
    padding: 4px;
    border: 1px solid #eee; }
  .page-template-page-dualtech-php #cols .col figure span {
    display: block;
    width: 280px;
    text-indent: -9999em;
    height: 170px;
    background: url("../hammerite/img/globals/sprite-dualtech.png") left -380px no-repeat;
    position: absolute;
    top: 0;
    left: 0; }
  .page-template-page-dualtech-php #cols .col div {
    background: url("../hammerite/img/bg/noise-lightblue.jpg");
    padding: 10px 20px 0 20px;
    font-size: 1.1em; }
  .page-template-page-dualtech-php #cols .col div p {
    padding: 0 0 15px 0 !important; }
  .page-template-page-dualtech-php #closure {
    padding: 0 0 20px 0;
    font-size: 1.1em; }
  .page-template-page-dualtech-php #closure figure {
    float: left;
    margin-right: 30px;
    width: 290px;
    text-align: center;
    padding-bottom: 10px; }
  .page-template-page-dualtech-php #closure p {
    padding: 18px 0 0 0;
    clear: both; }
  .page-template-page-dualtech-php .enter-raffle__form__title {
    height: auto; }
  .page-template-page-dualtech-php .enter-raffle__form .gform_wrapper form {
    padding: 0 !important; }
  .page-template-page-dualtech-php .enter-raffle__form .main-question {
    margin: 0; }
  .page-template-page-dualtech-php .enter-raffle__form .gfield {
    margin: 0 !important; }
  .page-template-page-dualtech-php .enter-raffle__form .gfield_radio {
    margin: 0; }
  .page-template-page-dualtech-php .enter-raffle__form .gfield_radio li > label {
    display: block; }
  .page-template-page-dualtech-php .enter-raffle__form .gfield_checkbox li > label {
    display: block; }
  .page-template-page-dualtech-php .enter-raffle__form .gfield:not(.gfield_radio) {
    display: block !important; }
  .knight {
    display: none; } }

@media only screen and (max-width: 767px), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .home header .wrapper a.logo {
    background: url("../hammerite/img/globals/hammerite-home-mobile@2x.png") no-repeat;
    background-size: 100%; }
  header .wrapper a.logo {
    background: url("../hammerite/img/globals/hammerite-mobile@2x.png") no-repeat;
    background-size: 100%; } }

@media print {
  body {
    background: #fff; }
  header,
  footer,
  #compare,
  #share {
    display: none; }
  .rating {
    display: block;
    width: 88px;
    height: 15px;
    float: left;
    margin-right: 10px;
    text-indent: -9999em;
    font-size: 0em;
    background: url("../hammerite/img/globals/sprite.png") no-repeat; }
  .ratinginfo {
    color: #929ca1 !important;
    display: block;
    float: left;
    padding-top: 2px; }
  .rating.stars5 {
    background-position: -250px -230px; }
  .rating.stars4-5 {
    background-position: -250px -250px; }
  .rating.stars4 {
    background-position: -250px -270px; }
  .rating.stars3-5 {
    background-position: -250px -290px; }
  .rating.stars3 {
    background-position: -250px -310px; }
  .rating.stars2-5 {
    background-position: -250px -330px; }
  .rating.stars2 {
    background-position: -250px -350px; }
  .rating.stars1-5 {
    background-position: -250px -370px; }
  .rating.stars1 {
    background-position: -250px -390px; }
  .rating.stars0-5 {
    background-position: -250px -410px; }
  .rating.stars0 {
    background-position: -250px -430px; } }

/* The clearfix mixin is necessary for the floats */
.clear {
  *zoom: 1; }
  .clear:before, .clear:after {
    display: block;
    height: 0 !important;
    line-height: 0;
    overflow: hidden;
    visibility: hidden;
    content: "."; }
  .clear:after {
    clear: both; }

ol li {
  padding-left: 0;
  padding-right: 20px;
  margin-left: 0;
  margin-right: 40px; }

a.cta {
  float: right;
  background: url("img/globals/buttons_r-l.png") 0 -64px no-repeat;
  padding: 0 16px 0 26px; }
  a.cta:hover {
    background: url("img/globals/buttons_r-l.png") 0 -94px no-repeat; }

header #topbar .wrapper .akzonobel {
  float: left;
  margin-right: 10px; }

header #topbar .wrapper ul {
  float: left; }
  header #topbar .wrapper ul li {
    background: url("img/globals/sprite_r-l.png") 0 -208px no-repeat;
    float: right; }
  header #topbar .wrapper ul .lang a {
    background: url("img/globals/sprite_r-l.png") 10px -140px no-repeat;
    padding-left: 35px;
    padding-right: 14px; }
  header #topbar .wrapper ul .lang .selected:hover,
  header #topbar .wrapper ul .lang .selected:visited:hover,
  header #topbar .wrapper ul .lang .selected {
    background: #ffffff url("img/globals/sprite_r-l.png") 10px -403px no-repeat; }
  header #topbar .wrapper ul .shoppinglist a {
    padding-left: 34px;
    padding-right: 14px; }
    header #topbar .wrapper ul .shoppinglist a span {
      left: 5px;
      right: auto; }
  header #topbar .wrapper ul .sub-menu {
    right: 1px; }
    header #topbar .wrapper ul .sub-menu a {
      background: none;
      padding-left: 14px; }
    header #topbar .wrapper ul .sub-menu li.ee a, header #topbar .wrapper ul .sub-menu li.lt a, header #topbar .wrapper ul .sub-menu li.lv a, header #topbar .wrapper ul .sub-menu li.ru a, header #topbar .wrapper ul .sub-menu li.uk a, header #topbar .wrapper ul .sub-menu li.nl a, header #topbar .wrapper ul .sub-menu li.fr a {
      background-position: 170px 11px;
      padding-left: 14px;
      padding-right: 40px;
      width: 146px; }

@media only screen and (max-width: 767px) {
  header .wrapper .menu-trigger {
    float: right;
    padding: 7px 36px 7px 0;
    background-position: 51px -157px; } }

header #search {
  float: left; }
  header #search input {
    padding-left: 38px;
    padding-right: 0;
    text-align: right; }
  header #search .btn {
    left: 1px;
    top: 0px;
    right: auto; }
  header #search .btnShow {
    top: 2px;
    left: 2px;
    right: auto;
    padding: 0 10px 2px 26px;
    background: url("img/globals/buttons_r-l.png") 0 -180px no-repeat; }
    header #search .btnShow:hover {
      background: url("img/globals/buttons_r-l.png") 0 -208px no-repeat; }

header nav ul {
  padding-left: 0;
  padding-right: 180px; }
  header nav ul li {
    float: right; }
  header nav ul .parent > a,
  header nav ul .parent > a:hover {
    background: url("img/globals/sprite_r-l.png") 15px -130px no-repeat;
    padding-left: 36px;
    padding-right: 24px; }
  header nav ul .parent .sub-menu a {
    padding-left: 14px;
    padding-right: 14px; }

header nav .sub-menu {
  left: auto;
  right: 0; }

header .wrapper {
  position: relative; }
  header .wrapper a.logo {
    left: auto;
    right: 10px; }
    @media only screen and (max-width: 767px) {
      header .wrapper a.logo {
        right: auto;
        left: 0; } }

@media only screen and (max-width: 767px) {
  .home header .wrapper a.logo {
    right: auto;
    left: 0; } }

#carousel {
  float: right;
  margin: 8px 10px 10px 0; }
  #carousel #carouselwrapper {
    left: auto;
    right: 0; }
  #carousel section {
    float: right;
    left: auto;
    right: 0; }
  #carousel .slide-dualtech .bull-link {
    float: right;
    background: none;
    padding-left: 0; }

#prodfinder {
  float: left; }
  #prodfinder input {
    float: right; }
  @media only screen and (max-width: 767px) {
    #prodfinder input[type=submit] {
      float: right;
      padding: 0 0 0 35px;
      background: url("img/globals/buttons_r-l.png") 0 -236px; }
      #prodfinder input[type=submit]:hover {
        background: url("img/globals/buttons_r-l.png") 0 -274px; } }

#slider section figure {
  float: right; }

#slider section div {
  float: left;
  padding: 30px 30px 30px 100px; }

#breadcrumbs {
  *zoom: 1;
  padding: 10px 20px 10px 10px; }
  #breadcrumbs:before, #breadcrumbs:after {
    display: block;
    height: 0 !important;
    line-height: 0;
    overflow: hidden;
    visibility: hidden;
    content: "."; }
  #breadcrumbs:after {
    clear: both; }
  @media only screen and (max-width: 767px) {
    #breadcrumbs {
      padding: 0; } }
  #breadcrumbs span {
    float: right; }
    @media only screen and (max-width: 767px) {
      #breadcrumbs span {
        float: none; } }
  #breadcrumbs li,
  #breadcrumbs span span,
  #breadcrumbs > span > span {
    margin-right: 0; }
    @media only screen and (max-width: 767px) {
      #breadcrumbs li,
      #breadcrumbs span span,
      #breadcrumbs > span > span {
        margin: 0 auto; } }
    #breadcrumbs li a,
    #breadcrumbs span span a,
    #breadcrumbs > span > span a {
      background: url("img/globals/sprite_r-l.png") 0 -258px no-repeat;
      margin-left: 14px;
      margin-right: 0;
      padding-left: 14px;
      padding-right: 0; }
      @media only screen and (max-width: 767px) {
        #breadcrumbs li a,
        #breadcrumbs span span a,
        #breadcrumbs > span > span a {
          background: none;
          float: right; } }

#sidebarxl {
  float: right;
  margin: 10px 0 0 20px; }
  #sidebarxl #submenu li a {
    padding: 10px 10px 10px 30px; }

#contentxl {
  float: right; }
  #contentxl figure.right {
    float: left;
    margin: 0 20px 20px 0; }

#content #refine {
  float: right; }
  #content #refine section a {
    padding: 10px 30px 10px 10px;
    background: url("img/globals/sprite_r-l.png") 94% -289px no-repeat; }
    #content #refine section a:hover {
      background: url("img/globals/sprite_r-l.png") 94% -328px no-repeat; }
    #content #refine section a.fold, #content #refine section a.fold:hover {
      background: url("img/globals/sprite_r-l.png") 96% -485px no-repeat; }
  #content #refine section ul li input {
    float: right;
    margin: 2px 0 0 8px; }
  #content #refine section ul li label {
    float: right; }
  #content #refine section ul li em {
    float: left; }

#colors ul li {
  float: right; }

a.close {
  float: left; }

#content #prodwrapper {
  float: right; }
  #content #prodwrapper .btnOrange,
  #content #prodwrapper .btnCompare {
    float: right; }
  #content #prodwrapper .btnCompare {
    margin-left: 0;
    margin-right: 10px; }
  #content #prodwrapper dl.features dd span {
    float: right; }

.product .btnOrange {
  padding: 0 10px 0 20px; }

.product .amount a.plus,
.product .amount input,
.product .amount a.minus,
.product .amount .delete {
  float: right; }

.product figure {
  float: right;
  margin-left: 20px;
  margin-right: 0; }

.product div {
  float: right; }

#frm-shoppinglist p .delete {
  float: right; }

#share .email a,
#share .print a {
  padding-left: 10px;
  padding-right: 24px; }

#share .email a {
  background: url("img/globals/sprite_r-l.png") 100% -27px no-repeat; }

#share .print a {
  background: url("img/globals/sprite_r-l.png") 100% 8px no-repeat; }

.wp-pagenavi {
  float: left; }
  .wp-pagenavi a,
  .wp-pagenavi span {
    float: right; }

.tab-nav li {
  float: right; }
  .tab-nav li a span {
    right: auto;
    left: 15px; }

.tab-nav .amount a {
  padding-left: 42px;
  padding-right: 20px; }

#procs li {
  background-position: 0 0;
  float: right;
  padding: 0 0 0 20px;
  margin-left: 20px;
  margin-right: 0; }
  @media only screen and (max-width: 767px) {
    #procs li {
      padding-right: 15px;
      padding-left: 0 !important;
      margin-left: 0 !important; } }

#content #ctablock em,
#content #ctablock a {
  float: right; }

#content #ctablock span {
  float: left; }

.btnOrange {
  background: url("img/globals/buttons_r-l.png") 0 0 no-repeat;
  padding: 0 16px 0 26px; }
  .btnOrange:hover {
    background: url("img/globals/buttons_r-l.png") 0 -32px no-repeat; }

#share .email form input {
  float: right;
  margin-left: 5px;
  margin-right: 0; }

#share .email form .btnOrange {
  float: right; }

#share .email form div a {
  right: auto;
  left: -20px; }

#contentwrapper #content #contentxl .gform_wrapper .button {
  padding: 0 16px 0 26px;
  background: url("img/globals/buttons_r-l.png") 0 -64px no-repeat; }
  #contentwrapper #content #contentxl .gform_wrapper .button:hover {
    background: url("img/globals/buttons_r-l.png") 0 -94px no-repeat; }

ul.list li {
  background: url("img/globals/sprite_r-l.png") 100% -94px no-repeat;
  padding: 4px 16px 4px 0; }

#contentwrapper #content #contentxl ul.list li {
  padding: 2px 16px 4px 0; }

@media only screen and (max-width: 767px) {
  .tabs .mobile-tab-nav > li > a {
    padding-left: 0;
    padding-right: 14px; } }

#frm-storefinder {
  *zoom: 1;
  margin-bottom: 25px; }
  #frm-storefinder:before, #frm-storefinder:after {
    display: block;
    height: 0 !important;
    line-height: 0;
    overflow: hidden;
    visibility: hidden;
    content: "."; }
  #frm-storefinder:after {
    clear: both; }
  #frm-storefinder input {
    float: none; }
    @media only screen and (max-width: 767px) {
      #frm-storefinder input {
        margin-right: 0; } }
  #frm-storefinder button {
    background: url("img/globals/buttons_r-l.png") 0 -64px no-repeat;
    padding: 0 16px 0 26px;
    margin: 0;
    float: right; }

dl.features dd,
dl.features dt {
  float: right; }

a.bull {
  background: url("img/globals/sprite_r-l.png") 100% -64px no-repeat;
  float: right;
  padding: 4px 14px 4px 0; }

footer #mobile-footer ul li {
  float: right; }

footer #mobile-footer li a {
  border: 0; }

footer #mobile-footer li.first,
footer #mobile-footer li.last {
  padding-left: 0;
  padding-right: 0; }

#compare {
  z-index: 9999; }
  #compare .wrapper div .delete {
    float: right; }

.promo a.cta {
  right: auto;
  left: 15px; }
  @media only screen and (max-width: 767px) {
    .promo a.cta {
      left: 0;
      right: auto; } }

a#close-promos,
a#close-promos:visited {
  right: auto;
  left: 10px;
  margin: 20px 0 0 20px; }
  @media only screen and (max-width: 767px) {
    a#close-promos,
    a#close-promos:visited {
      margin: 3px 0 0 13px; } }

.tabs .mobile-tab-nav > li > a {
  background: white url("img/globals/sprite_r-l.png") 8px -405px no-repeat; }

.tabs .mobile-tab-nav > li > a.selected {
  background: white url("img/globals/sprite_r-l.png") 8px -368px no-repeat; }

#related {
  margin-bottom: 20px !important; }

@media only screen and (max-width: 767px) {
  #breadcrumbs > span > span a:before {
    content: '<';
    float: right; } }

@media only screen and (max-width: 767px) {
  nav #search input[type=search] {
    padding-right: 10px;
    padding-left: 27px;
    left: 18px; } }

nav #search .btn {
  right: auto;
  left: 25px; }

@media only screen and (max-width: 767px) {
  body > .outer-scroll > nav {
    background-image: url("img/bg/mobile-bg-menu.png"); } }

#carousel .slide-dualtech figure .badge,
.page-template-page-dualtech-php #graph #graph-wrapper .badge {
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

.page-template-page-dualtech-php #graph,
#carousel .slide-dualtech h1,
#carousel .slide-dualtech em {
  float: right; }

#carousel .slide-dualtech em {
  margin-right: 10px; }

#carousel .slide-dualtech figure {
  float: left;
  right: auto;
  left: 40px; }
  #carousel .slide-dualtech figure .badge {
    right: auto;
    left: -20px; }

.page-template-page-dualtech-php #graph {
  right: auto;
  left: 30px; }

.page-template-page-dualtech-php #packs {
  padding: 60px 340px 45px 40px; }
  .page-template-page-dualtech-php #packs figure {
    left: 0;
    right: 20px; }

.page-template-page-dualtech-php #closure figure {
  float: right;
  margin-left: 30px;
  margin-right: 0; }

.page-template-page-dualtech-php #usps li figure {
  float: right;
  margin-right: 0;
  margin-left: 10px; }

.page-template-page-dualtech-php #cols .col #improved-formula em {
  padding-right: 5px; }

.error404 #contentwrapper #search .btn,
.error404 #contentwrapper #search .btnShow {
  background: url("img/globals/buttons_r-l.png") 0 -64px no-repeat; }

#claim .logo {
  float: left; }
