/* Adjustments for responsive */
#summary { clear: both; }
#summary p  { padding-top: 10px; }
body.video #contentwrapper #content,
body.browse #contentwrapper #content{ display: none; }
body.video #contentwrapper #redirect,
body.browse #contentwrapper #redirect  { display: block; padding: 10px 20px; width: 940px; }

/*	Media queries - Mobile  ------------------------------------------	*/
@media only screen and (max-width: 767px) {
		
		/* Aanpassingen voor het menu */
		header #topbar,
		nav .wrapper .lang,
		nav .wrapper .shoppinglist { display: none }
		body > nav, body > .outer-scroll > nav { background: url('../hammerite/img/bg/mobile-bg-menu.png'); left: -240px; top: 0; bottom: 0; height: 100%; min-height: 100%; overflow: hidden; position: absolute; z-index: 9999; width: 240px; padding: 0; }
		/* Aanpassingen voor het menu */
		
		html { -webkit-text-size-adjust: none; /* Prevent font scaling in landscape */ height: 100%; }
		body { background: none; overflow-x: hidden; height: 100%; }
		body.home { background: none; }
		body.home #contentwrapper { position: relative; top: -62px; }
		
		/* Hide for this viewport */
		#prodfinder a#tooltip, #articlewrapper, #columns .middle, .blocks .middle, #singleprod, #share, #downloads, #content iframe, #content #ctablock,
		#content #askexpert, #compare, .review .meta, #user-reviews-element > p, #content #sidebar, #contentwrapper #content .intro p, #contentwrapper #content .intro a { display: none; }
		
		.wrapper { width: auto; margin: 0; padding: 0; } 
		
		#content form { padding: 0 15px; width: 260px; float: none; }
		#content input { margin-bottom: 10px; float: none; }
		#content button { float: none; line-height: 12px; }
		
		.list { margin: 0 15px; width: 260px; }
		.list.border { border-bottom: 1px dotted #d7d8d8; margin-bottom: 20px; }
		
		/* Typography */
		h1, #prodfinder h2 { font: normal 28px/28px 'helvetica', arial; }
		#contentxl > h2 { padding: 0 15px; }
		.tabs h2 { font: bold 12px arial; }
		#howto-videos h2, #faq h2, #contentxl #related h3 { font: normal 18px 'helvetica', arial; margin: 0 0 10px 15px; color: #373a3b; }
		#contentwrapper #content #contentxl hgroup h2 { font-size: 16px; }
		
		/* Header */
		header { background: url('../hammerite/img/bg/mobile-bg-header.png') repeat-x; height: 92px; margin: 0 0 20px 0; padding-top: 10px; width: 100%; position: relative; }
		header .wrapper { width: 260px; margin: 0 auto; }
		header .wrapper a.logo { background: url('../hammerite/img/globals/hammerite-mobile.png') no-repeat; width: 58px; height: 59px; right: 0; top: inherit; left: inherit; text-indent: -9999em }	
		header .wrapper .menu-trigger { float: left; background: #00b7f3 url('../hammerite/img/globals/mobile-sprite.png') 11px -157px no-repeat; border: 1px solid #013f65; color: #fff; cursor: pointer; font-weight: bold; height: 16px; line-height: 16px; padding: 7px 0 7px 36px; text-decoration: none; text-shadow: 1px 1px #0090c0; width: 42px; border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; -moz-box-shadow: inset 0 1px #80defd; -webkit-box-shadow: inset 0 1px #80defd; box-shadow: inset 0 1px #80defd; }
		header .wrapper .menu-trigger.close { background: #00b7f3 url('../hammerite/img/globals/mobile-sprite.png') 11px -182px no-repeat; }
		header .wrapper .menu-trigger:active,
		header .wrapper .menu-trigger.close:active { background-color: #ff8610; -moz-box-shadow: inset 0 1px #80defd; -webkit-box-shadow: inset 0 1px #80defd; box-shadow: inset 0 1px #ffc287; text-shadow: 1px 1px #cc6b0b; }
		.home header .wrapper a.logo { background: url('../hammerite/img/globals/hammerite-home-mobile.png') no-repeat; width: 98px; height: 101px; }
		.home.ru_ru header .wrapper a.logo,
		.home.pl_pl header .wrapper a.logo { background: url('../hammerite/img/globals/hammerite-mobile.png') no-repeat; width: 58px; height: 59px; }
			
		/* Breadcrumbs */
		#breadcrumbs { background: url('../hammerite/img/bg/mobile-bg-noise.png'); height: 42px; width: 100%; margin-top: 50px; padding: 0; border: 0; }
		/* HTML Concept7 */
		#breadcrumbs li { width: 260px; padding: 0; margin: 0 auto; display: none; height: 42px; float: none; font-size: 12px; }
		#breadcrumbs li a:before { content: '<'; display: block; width: 12px; float: left; }
		#breadcrumbs li a { background: none; color: #ccd6dd; display: block; float: left; line-height: 42px; text-decoration: none; }
		/* DEV */
		#breadcrumbs > span > span { width: 260px; padding: 0; margin: 0 auto; height: 42px; float: none; font-size: 12px; display: none; }
		#breadcrumbs > span > span a:before { content: '<'; display: block; width: 12px; float: left; }
		#breadcrumbs > span > span a { background: none; color: #ccd6dd; display: block; float: left; line-height: 42px; text-decoration: none; }
			
		/* Mobile nav */
		.scroll-nav { width: 260px; }
		.scroll-nav ul li { width: 240px; display: block; clear: both; }
		.scroll-nav ul li a { font: normal 14px 'helvetica', arial; padding: 0 15px 0 47px !important; background: none !important; text-shadow: 0 1px 1px #000; border-top: 1px solid #033758; border-bottom: 1px solid #011b2d; display: block; line-height: 44px; height: 44px; color: #fff; text-decoration: none; }
		.scroll-nav ul li a:hover { color: #fff; }
		.scroll-nav .sub-menu { display: none !important; }
		
		/* Mobile nav icons */
		.scroll-nav ul li a { background: url('../hammerite/img/globals/mobile-sprite.png') -1000px -1000px no-repeat; position: relative; }
		.scroll-nav ul li.home a { background-position: 15px -206px; }
		.scroll-nav ul li.products a { background-position: 14px -259px; }
		.scroll-nav ul li.applications a { background-position: 16px -308px; }
		.scroll-nav ul li.how-to a { background-position: -164px -407px; }
		.scroll-nav ul li.why-hammerite a { background-position: -225px -206px; }
		.scroll-nav ul li.contact a { background-position: -227px -256px; }
		.scroll-nav ul li.faq a { background-position: -224px -307px; }
		.scroll-nav ul li.about-us a { background-position: -166px -447px; }
		.scroll-nav ul li.news a { background-position: -224px -357px; }
		.scroll-nav ul li.storefinder a { background-position: -165px -491px; }
		
		nav #search { height: 30px; display: block; float: none; z-index: 10; position: relative; padding: 13px 15px 15px 15px; border-bottom: 1px solid #00182a; background: #073957; background: -moz-linear-gradient(top, rgba(7,57,87,1) 1%, rgba(5,51,83,0.5) 100%); background: -webkit-gradient(linear, left top, left bottom, color-stop(1%,rgba(7,57,87,0.5)), color-stop(100%,rgba(5,51,83,0.5))); background: -webkit-linear-gradient(top, rgba(7,57,87,0.5) 1%, rgba(5,51,83,0.5) 100%); background: -o-linear-gradient(top, rgba(7,57,87,0.5) 1%,rgba(5,51,83,0.5) 100%); background: -ms-linear-gradient(top, rgba(7,57,87,0.5) 1%,rgba(5,51,83,0.5) 100%); background: linear-gradient(to bottom, rgba(7,57,87,0.5) 1%,rgba(5,51,83,0.5) 100%); }
		nav #search input[type=search] { box-sizing: border-box; -webkit-appearance: textfield; color: #4e616d; display: block; position: absolute; top: 13px; left: 15px; padding-left: 5px; padding-right: 27px; width: 205px; height: 33px; line-height: 33px !important; border-radius: 12px; -moz-border-radius: 12px; -webkit-border-radius: 12px; background: #021c2e; border: 0; box-shadow: 0 1px #1d435d; -moz-box-shadow: 0 1px #1d435d; -webkit-box-shadow: 0 1px #1d435d; }
		::-webkit-search-cancel-button { display: none; }
		nav #search input[type=search]:focus { color: #acc2d0; }
		nav #search .btn,
		nav #search .btnShow { background: url('../hammerite/img/globals/mobile-sprite.png') right 0 no-repeat; display: block; text-indent: -9999px; width: 20px; height: 20px; cursor: pointer; border: none; position: absolute; top: 20px; right: 25px; }
		nav #search .btnShow { background-position: right -33px; }
		
		/* Current item - current = HTML, current_page_item = DEV */
		.scroll-nav ul li.current,
		.scroll-nav ul li.current_page_item { background: #ff8d1d; background: -moz-linear-gradient(top, #ff8d1d 0%, #ff7f02 100%); background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ff8d1d), color-stop(100%,#ff7f02));background: -webkit-linear-gradient(top, #ff8d1d 0%,#ff7f02 100%); background: -o-linear-gradient(top, #ff8d1d 0%,#ff7f02 100%); background: -ms-linear-gradient(top, #ff8d1d 0%,#ff7f02 100%); background: linear-gradient(to bottom, #ff8d1d 0%,#ff7f02 100%); position: relative }
		.scroll-nav ul li.current > a,
		.scroll-nav ul li.current_page_item > a { position: relative; z-index: 10; border-top: 1px solid #000; box-shadow: inset 0 1px #eeb985; -moz-box-shadow: inset 0 1px #eeb985; -webkit-box-shadow: inset 0 1px #eeb985; text-shadow: 0 1px 1px #c36508; }
		.scroll-nav ul li.current > a:before,
		.scroll-nav ul li.current_page_item > a:before { content: ''; z-index: 100; position: absolute; width: 28px; height: 28px; right: 10px; top: 15px; }
			
		/* Content */
		#contentwrapper { background: none; width: 320px; margin: 0 auto; }
		#contentwrapper .wrapper { background: none; border: 0; padding: 0; width: 290px; margin: 0 auto; position: inherit; }
		#contentwrapper #content { margin-top: 20px; width: 290px; padding: 0; position: inherit; }
		#contentwrapper #content h1 { margin-bottom: 10px; padding: 0 15px; }
		#contentwrapper #content p { padding: 0 15px 15px; }
		#contentwrapper #content #summary p { padding: 0; }
		#contentwrapper #content #contentxl { width: 290px; float: none; padding: 0; margin: 0; position: inherit; }
		#contentwrapper #content #contentxl hgroup h2 { padding: 0 15px; }
		#contentwrapper #content #contentxl ul li,
		#contentwrapper #content #contentxl ol li { padding-left: 10px; padding-top: 10px; }
		#contentwrapper #content #contentxl ul.list li { padding: 4px 0 0 15px; } /* DEV */
		#contentwrapper #content #contentxl ul.usplist li { padding: 4px 0 8px 22px; } /* HTML Concept7 */
		#contentwrapper #content #contentxl ol li p { padding-left: 0; }
		#contentwrapper #content #contentxl > div { margin: 0 15px; }
		#contentxl h3 { font: 700 12px arial; margin: 20px 15px 5px; }
		.blog #contentxl h3 { margin-top: 0; }
		.blog #contentxl article { margin-bottom: 20px; }
		#contentxl article h2 {  margin: 20px 15px 5px; } /* DEV */
		#contentxl article h3 { font: normal 2em 'Helvetica', arial; color: #003E67; }
		#contentwrapper #content .split { width: 290px; float: none; padding: 0; margin: 0; position: inherit; }
		#contentwrapper #content .split h2,
		#contentwrapper #content .split p { padding: 0 15px; }
		#contentwrapper #content .split h2 { padding-top: 5px; }
		#contentwrapper #content .split p { padding-bottom: 15px; }
		#contentwrapper #content article figure,
		#contentwrapper #content .split figure { width: 290px; }
		#contentwrapper #content .img-confirm { margin: 0 auto 10px auto; width: 100%; }
		#contentwrapper #content article figure span,
		#contentwrapper #content .split figure span,
		#contentwrapper #content .img-confirm span { height: auto; width: auto; }
		#contentwrapper #content article img,
		#contentwrapper #content .split img,
		#contentwrapper #content .img-confirm img { width: 100%; }
		#contentwrapper #content .contact-list { padding: 0 15px 20px; }
		#contentwrapper #content article .cta,
		#contentwrapper #content article .btnOrange { margin-bottom: 35px; }
		#contentwrapper #content article .btnOrange,
		#contentwrapper #content article .bull { margin-left: 15px; }
		/* HTML Concept7 */
		#contentwrapper #content #contact { padding: 0; }
		#contentwrapper #content #contact h2,
		#contentwrapper #content #contact div { padding: 0 15px; margin: 10px 0; }
		#contentwrapper #content #contact .error { padding: 10px 14px; }
		#contentwrapper #content #contact label { padding-bottom: 5px; }
		#contentwrapper #content #contact em { padding: 0; }
		#contentwrapper #content #contact input,
		#contentwrapper #content #contact textarea { width: 218px; }
		#contentwrapper #content #contact button { margin: 0 0 20px 15px; }
		/* DEV */
		#contentwrapper #content #gform_wrapper_1 h3,
		#contentwrapper #content #gform_wrapper_1 div { margin: 10px 0; }
		#contentwrapper #content #gform_wrapper_1 form { padding: 0; }
		#contentwrapper #content #gform_wrapper_1 h3 { font: normal 22px/24px 'helvetica', arial; margin-top: 20px; }
		#contentwrapper #content #gform_wrapper_1 label { padding-bottom: 5px; }
		#contentwrapper #content #gform_wrapper_1 input,
		#contentwrapper #content #gform_wrapper_1 textarea { width: 218px; }
		#contentwrapper #content #gform_wrapper_1 button { margin: 0 0 20px 15px; }
		#contentwrapper #content #sidebarxl { margin: 0 15px 25px 15px; width: 260px; }
		#contentwrapper #content .intro a { padding-left: 15px; }

		#prodfinder select { width: 260px; font-size: 16px; margin-bottom: 12px; }

		/* Homepage intro */
		#carousel {
			display: block;
			float: none;
			height: auto;
			width: 100%;
			margin: 0 0 25px 0;

			#carouselwrapper {
				display: block;
				float: none;
				height: auto;
				width: 100%;
			}

			section {
				float: none;
				height: auto;
				width: 100%;
				margin: 0;
				padding: 0;
				position: static;
				top: auto;
				left: auto;

				> div {
					height: auto;
					width: 100%;
					padding: 0;

					figure {
						float: none;
						margin: 0;

						img {
							max-width: 100%;
							height: auto;
						}
					}

					.textwidget {
						img {
							max-width: 100%;
							height: auto;
							margin-bottom: 10px;
						}
					}
				}
			}

			.slide-dualtech {
				figure {
					float: none;
					margin: 0;
					position: static;
					right: auto;
					top: auto;
				}
			}
		}


		/* Products */
		#refine,
		#prodwrapper #refined { display: none; }
		#content #prodwrapper { padding-left: 0px; padding-right: 0px; width: 290px; }
		#content #prodwrapper .product { padding: 0 15px 20px; }
		#content #prodwrapper .product div { width: 160px; float: right; }
		#content #prodwrapper .product ul.list { width: auto; }
		#content #prodwrapper .product .rating-wrap { float:none; margin-bottom: 15px; width: 260px; }
		#content #prodwrapper .product .rating { margin: 0 15px 0 0; }
		/* HTML Concept7 */
		#prodwrapper .product figure { width: 80px; height: 80px; margin-right: 0; }
		#prodwrapper .product figure img { width: 100%; height: 100%; }
		/* DEV */
		.single-product #contentxl figure { width: 270px; margin: 15px 0 0 15px; }
		.single-product figure img { width: 100%; height: 100%; }
		
		/* Search */
		#contentwrapper .result dl { padding: 0 15px 10px; }
		
		/* Pagination */
		.paging { padding-left: 15px; }
		.paging li { padding-bottom: 30px; }
		
		/* FAQ */
		#faq { width: 260px; margin: 0 !important; }
		#contentwrapper #faqlist { padding: 20px 15px 0 20px; }
		
		/* Buttons */
		.cta { margin: 0 0 20px 15px; }
		
		/* Usp list*/
		.usplist.float { float: none; margin: 0 auto; width: 260px; }
		
		/* Storefinder */
		#frm-storefinder { margin-bottom: 35px; }
	
		/* Productfinder */
		#prodfinder { float: none; height: auto; margin-bottom: 20px; padding: 27px 15px 5px 15px; }
		#prodfinder h2 { margin: 2px 0 3px 0; }
		#prodfinder input[type=submit] { background: url('../hammerite/img/globals/mobile-sprite.png') right -100px; margin-top: 5px; border: 1px solid #f15c03; border-radius: 4px; -moz-border-radius: 4px; -webkit-border-radius: 4px; color: #fff; display: block; float: left; font-weight: bold; height: 40px; line-height: 40px; padding: 0 35px 0 0; width: 140px; text-decoration: none; text-shadow: 0 1px 0 #f15c03; }
		#contentwrapper #prodfinder input[type=submit]:active { background: url('../hammerite/img/globals/mobile-sprite.png') right -138px; cursor: pointer; box-shadow: 0 1px 2px #ccc; color: #fff; }
		#prodfinder p#surface{ clear: both; padding-top: 15px; }
		#prodfinder select.chosen { width: 260px; margin-bottom: 10px; } 
		
		/* Columns */
		#columns { clear: both; width: 290px; padding: 0; }
		#columns > div { background: none; float: none; width: 260px; padding: 0 15px; }
		#columns > div.first ul { margin-bottom: 20px; }
		#columns > div.last { padding: 0 15px; width: 260px; }
		#columns > div.widget { visibility: hidden; display: block; font-size: 0; content: " "; clear: both; height: 0; }
		#columns a.bull { padding-right: 3px; }
		#columns #watch-vids { margin-top: 2px; }
			
		/* Video */
		#columns #vidwrapper { border-bottom: 0; height: 739px; padding: 20px 0; width: 290px; }
		#columns #vidwrapper #vidcarousel,
		#columns #vidwrapper #vidcarousel div { background: none; float: left; padding: 0; width: 260px; }
		#columns #vidwrapper #vidcarousel div{ clear: both; display: none; float: none; margin-bottom: 20px; }
		#columns #vidwrapper #vidcarousel div:first-child,
		#columns #vidwrapper #vidcarousel div:first-child + div,
		#columns #vidwrapper #vidcarousel div:first-child + div + div { display: block; }
		#columns #vidwrapper ul{ display: none; }
				
		/* Tabs */
		.tabs { width: 290px; margin-bottom: 20px; }
		.tabs > div { padding: 0; }
		.tabs p, .tabs span { padding: 0 15px; display: block; }
		.tabs .mobile-tab-nav { width: 290px; }
		.tabs .mobile-tab-nav > li { margin-bottom: 5px; clear: both; }
		.tabs .mobile-tab-nav > li > a { background: #f6f4ee url('../hammerite/img/globals/mobile-sprite.png') right -447px; padding-left: 14px; font: normal 16px 'helvetica', arial; color: #153964; display: block; height: 38px; border: 1px solid #ece8de; line-height: 38px; text-decoration: none; }
		.tabs .mobile-tab-nav > li > a.selected { background: #fff url('../hammerite/img/globals/mobile-sprite.png') right -478px no-repeat; height: 37px; border-top: 2px solid #00b3ed; }
		.tabs .mobile-tab-nav > li > a > span { display: none; }
		.tabs .tab-li > div > h2 { padding: 0 15px; }
		.tabs .note { background: #fcebb5 url('../hammerite/img/globals/mobile-sprite.png') 15px -377px no-repeat; padding: 15px 0 15px 53px; }
		.tabs .step .note { padding: 15px 0 15px 53px; width: 140px; margin: 10px 0 10px 15px; padding: 15px 15px 15px 50px; width: 155px; }
			
		/* Containers tabs */
		#product-features-element,
		#usage-guide-element,
		#user-reviews-element,
		#where-to-buy-element { width: 290px; border-bottom: 1px dotted #ece8de; margin: 0 0 20px 0; padding: 0 0 20px 0; }
		.single-product #where-to-buy-element { margin: 0; padding: 0; } /* DEV */
		#where-to-buy-element h2 { margin-top: 15px; }
		#where-to-buy-element p { padding: 0 15px 15px 15px; }
		
		/* Slider */
		#slider { position: relative; width: 290px; height: 221px; margin-bottom: 20px; padding: 0; }
		#slider #sliderwrapper { margin-top: 18px; height: 221px; padding: 0; }
		#slider #sliderwrapper section { width: 290px; }
		#slider #sliderwrapper section img { width: 290px; }
		#slider #sliderwrapper section figure { float: none; width: 290px; }
		#slider #sliderwrapper section div { width: 290px; height: 40px; background: none; clear: both; padding: 0; float: none; position: absolute; top: 163px; }
		#slider #sliderwrapper section div h3,
		#slider #sliderwrapper section div p { display: none; }
		#slider #sliderwrapper section div .cta-mobile { display: block; text-align: center; line-height: 40px; background: #ece8de; width: 290px; height: 40px; }
		
		#slider #controls { position: absolute; top: 0; bottom: auto; left: auto; margin-left: 129px; }
		#slider #controls .play, #slider #controls .pause { display: none; }
		#slider #controls li { margin-right: 4px; }
		#slider #controls li a,
		#slider #controls li a:hover { background: url('../hammerite/img/globals/mobile-sprite.png') -9px -110px; width: 8px; height: 8px; display: block; text-indent: -9999px; }
		#slider #controls li.current a,
		#slider #controls li.current a:hover { background: url('../hammerite/img/globals/mobile-sprite.png') left -110px; width: 8px; height: 8px; display: block; text-indent: -9999px; }
		
		#slider #prev { background: url('../hammerite/img/globals/mobile-sprite.png') no-repeat left 0; display: block; font-size: 0; height: 50px; left: 0; position: absolute; text-indent: -9999px; top: 74px; width: 50px; z-index: 100; }
		#slider #prev:active,
		#slider #prev:hover { background: url('../hammerite/img/globals/mobile-sprite.png') no-repeat -50px top; display: block; font-size: 0; height: 50px; left: 0; position: absolute; top: 74px; width: 50px; z-index: 100; }
		#slider #next { background: url('../hammerite/img/globals/mobile-sprite.png') no-repeat left -50px; display: block; font-size: 0; height: 50px; position: absolute; text-indent: -9999px; right: 0; top: 74px; width: 50px; }
		#slider #next:active,
		#slider #next:hover { background: url('../hammerite/img/globals/mobile-sprite.png') no-repeat -50px -50px; display: block; font-size: 0; height: 50px; right: 0; position: absolute; top: 74px; width: 50px; }
		
		/* Product summary */
		#summary { position: relative; width: 260px; margin-top: 45px; padding: 0 15px; }
		#summary p { width: 160px; float: right; padding: 0; }
		#summary figure.productimg { border: 1px solid #ece8de; width: 80px; height: 80px; float: left; margin-top: 3px; position: relative; }
		#summary figure.productimg img { width: 100%; height: 100%; }
		
		/* Product features */
		#procs { border: 0; padding: 0; width: 290px; margin-top: 10px; }
		#procs li { background: none; float: none; text-align: right; width: 260px; line-height: 37px; height: 37px; border: 1px dotted #d7d8d8; border-width: 0 0 1px 0; margin: 0; padding: 0 15px; color: #373a3b; font-weight: bold; }
		#procs li em { color: inherit; display: inline-block; float: left; font-weight: normal; padding: 0; }
		
		/* Features */
		dl.features { width: 260px; padding: 0 15px; }
		dl.features dd { width: 135px; float: left; clear: none; }
		dl.features dd span { padding: 0; }
		dl.features dt { width: 125px; float: left; }
		
		/* Review */
		.review { padding: 20px 15px !important; width: 260px; }
		.review h2 { float: none; clear: both; margin-bottom: 2px; }
		.review .rating { float: none; padding: 0; margin: 0 0 10px 0; }
		.review em { padding-bottom: 10px; }
		.review p { padding: 0; }
		.review .pros, .review .cons { width: 260px; float: none; }
		
		.response { width: 258px; padding: 15px !important; }
		.response p { padding: 0; }
		
		aside.inzet { background: none; margin: 0; padding: 0; width: auto; }
		aside.inzet h3 { padding: 0 15px; } 
		aside.inzet em { padding: 0 15px; }
		
		#colors { border-bottom: 1px solid #ece8de; width: 260px; margin-bottom: 14px; padding: 0 15px; }	

		.rating { margin: 0 15px; }
		
		#faq { padding: 0; }
		#faq dl { width: 260px; padding: 0 15px; }
		#faq a.bull { margin: 15px; }
		
		/* Related products */
		#related { width: 275px; margin-bottom: 20px; padding: 15px 15px 15px 0; }
		#related figure { width: 120px; height: 146px; float: left; margin: 10px 15px 0 15px; padding: 0; }
		#related figure + figure { margin: 10px 0 0 0; }
		#related img { width: 75%; }
		#related figcaption { padding: 0 15px;}
		
		/* How to video's */
		#columns #howto-videos { padding: 0; width: 290px; margin-bottom: 10px; }
		#howto-videos { margin-bottom: 20px; }
		#howto-videos h3 { padding: 0 15px; color: #373a3b; }
		#howto-videos .video { width: 260px; padding: 15px; background: url('../hammerite/img/bg/noise-blue.png') repeat; height: 90px; margin-bottom: 10px;  }
		#howto-videos .video figure { position: relative; float: left; width: 110px; height: 90px; }
		#howto-videos .video figure img { z-index: 1; position: absolute; }
		#howto-videos .video figure span.play { position: absolute; top: 25px; left: 35px; display: block; width: 40px; height: 40px; z-index: 10; text-indent: -9999px; background: url(img/globals/mobile-sprite.png) -100px 0; }
		#howto-videos .video div { float: right; width: 135px; padding: 0; background: none; }
		#howto-videos .video div a { line-height: 1.6em; text-decoration: none; }
		#howto-videos .video div span.duration { margin-top: 5px; line-height: 1.8em; color: #7698ad; display: block; height: 18px; padding-left: 20px; background: url(img/globals/mobile-sprite.png) -20px -120px; }
		#howto-videos a.bull { margin: 0 20px; }
		
		/* Video detail */
		#videowrapper { padding-top: 30px; margin-bottom: 20px; border-bottom: 1px dotted #d7d8d8; }
		#videowrapper #currentvideo { background: #ececec; clear: both; width: 290px; height: 160px; }
		#videowrapper h2 { color: #373a3b; font: bold 12px/12px arial; margin: 20px 15px 0 15px; }
		#videowrapper .duration { margin: 5px 15px 10px 15px; line-height: 1.8em; color: #7698ad; display: block; height: 18px; width: 120px; padding-left: 20px; background: url(img/globals/mobile-sprite.png) -20px -120px; }
		#videowrapper p { margin: 0 15px; display: block; }
		
		/* Maps */
		#map_search { overflow: auto; padding: 15px !important; }
		#map_search td { display: block; padding-bottom: 10px; }
		#map_search .submit,
		#map_search .select { width: 228px; }
		#map_search div.result { padding: 15px; }
		#map_search div.result_address { padding: 0; }
		#map_search div.result_link a { text-align: left; }
		#map_search div.result h3 { padding-left: 0; line-height: 20px; }	
		#singlemap { overflow: hidden; }
		
		/* Contact form */
		#contentwrapper #content #contentxl .gform_wrapper ul li { padding: 0; }
		#content .gform_wrapper input,
		#content .gform_wrapper textarea { margin-top:0; }
		
		/* Footer */
		footer { margin: 0; width: 100%; background: none; padding: 0; z-index: 200; }
		footer div.footer,
		footer div#claim { display: none; }
		footer #mobile-footer { background: url('../hammerite/img/bg/mobile-bg-footer.png') repeat-x; height: 50px; }
		footer #mobile-footer .wrapper { margin: 0 auto; width: 400px; height: 50px; text-align: center; }
		footer #mobile-footer ul { display: inline-block; line-height: 58px; }
		footer #mobile-footer li { float: left; display: block; }
		footer #mobile-footer li:first-child a{ padding-left: 0; }
		footer #mobile-footer li a { color: #b4cbd7; text-decoration: none; padding: 0 10px; border-right: 1px dotted #80a5b9; }
		footer #mobile-footer li.last a { border: 0; padding-right: 0; }
		footer #mobile-footer li a:active { color: #fff; }
		footer #to-top { background: url('../hammerite/img/bg/mobile-bg-noise.png'); bottom: 0; color: #678599; left: 0; right: 0; text-align: center; height: 42px; line-height: 42px; }
		footer #to-top a { color: #678599; text-decoration: none; }
		
		/* Products blocks */
		#content #prodwrapper .product div .btnCompare { display: none; }
		#content #prodwrapper .product div p { padding: 0 }
		#content #prodwrapper .product ul.list { margin: 0 }
		
		/* On mobile only shown */
		body.video #contentwrapper #content,
		body.browse #contentwrapper #content { display: block; }
		body.video #contentwrapper #redirect,
		body.browse #contentwrapper #redirect { display: none; }
		
		/* Promos */
		#thepromos { position: static; }
		#promos { background:#e7eff3; width: auto; padding: 0; @include box-shadow(inset 0 -6px 6px -3px rgba(#000, 0.2)); }
		.promo { background:transparent !important; width: 270px; padding: 20px 15px 25px; }
		.promo a.cta { padding: 0 0 0 26px; width: 0; text-indent: -1234em; right: 0; top: 41px; }
		a#close-promos, a#close-promos:visited { margin: 3px 13px 0 0; }
		
		/* Dualtech Landingpage */
		.item-dualtech span { display: block; width: 49px; height: 22px; background: url('../hammerite/img/globals/sprite-dualtech.png') right top no-repeat; text-indent: -9999em; font-size: 0em; position: absolute; top: -2px; right: 4px; z-index: 10 }
		ccc #content { padding: 20px 30px; width: 920px }
		.page-template-page-dualtech-php #content h1 { text-transform: uppercase; font-size: 2.2em }
		.page-template-page-dualtech-php #graph { float: left; position: relative; z-index: 1; top: 8px; height: 300px }
		.page-template-page-dualtech-php #graph #graph-wrapper { position: relative; width: 290px }
		.page-template-page-dualtech-php #graph #graph-wrapper img { width: 290px }
		.page-template-page-dualtech-php #graph #graph-wrapper .conv-paint { height: 72px; background: url('../hammerite/img/globals/sprite-dualtech.png') right -550px no-repeat; padding-right: 19px; position: absolute; top: -4px; left: 10px }
		.page-template-page-dualtech-php #graph #graph-wrapper .conv-paint span { font-family: 'helvetica'; color: #fff; font-style: italic; font-weight: normal; font-size: 1em; display: block; height: 47px; background: url('../hammerite/img/globals/sprite-dualtech.png') left -622px no-repeat; padding: 11px 8px 0 24px; text-transform: uppercase; text-align: right; text-shadow: 0 0 1px #d37012 }
		.page-template-page-dualtech-php #graph #graph-wrapper .impr-formula { height: 69px; background: url('../hammerite/img/globals/sprite-dualtech.png') left -681px no-repeat; padding-left: 17px; position: absolute; bottom: 80px; left: 170px }
		.page-template-page-dualtech-php #graph #graph-wrapper .impr-formula span { font-family: 'helvetica'; color: #fff; font-style: italic; font-weight: normal; font-size: 1em; display: block; height: 54px; background: url('../hammerite/img/globals/sprite-dualtech.png') right -752px no-repeat; padding: 11px 24px 0 8px; text-transform: uppercase; text-align: right; margin-top: 13px; text-shadow: 0 0 1px #d37012 }
		.page-template-page-dualtech-php #graph #graph-wrapper .badge { width: 98px; height: 98px }
		.page-template-page-dualtech-php .dualtech-text-intro { width: 260px; font-size: 1.1em; padding: 0 0 30px 0; display: none }
		.page-template-page-dualtech-php #packs { background: url('../hammerite/img/bg/noise-gold-light.png'); margin-top: 40px; font-size: 1.1em; border-radius: 2px; border: 0; position: relative; padding: 20px 0; width: 290px; clear: both }
		.page-template-page-dualtech-php #packs h2 { text-transform: uppercase; margin-left: 20px; font-size: 1.8em }
		.page-template-page-dualtech-php #packs p { padding: 0 20px !important }
		.page-template-page-dualtech-php #packs figure { position: absolute; left: 20px; top: -24px; border: 0; display: none }
		.page-template-page-dualtech-php #usps { padding: 30px 0 0 0; background: url('../hammerite/img/bg/dotted-blue.png') left bottom repeat-x }
		.page-template-page-dualtech-php #usps li { width: 300px; float: none; padding-bottom: 20px; min-height: 70px }
		.page-template-page-dualtech-php #usps li figure { float: left; margin-right: 10px }
		.page-template-page-dualtech-php #usps li h3 { text-transform: uppercase; font-size: 1.6em; margin: 10px 0 0 0; float: left }
		.page-template-page-dualtech-php #usps li p { padding: 0 !important; font-size: 1.1em; float: left }
		.page-template-page-dualtech-php #cols { padding: 20px 4px }
		.page-template-page-dualtech-php #cols h2 { text-transform: uppercase; margin-bottom: 15px }
		.page-template-page-dualtech-php #cols .col { float: left; width: 282px; margin-right: 37px; margin-bottom: 20px }
		.page-template-page-dualtech-php #cols .col.last { margin-right: 0 }
		.page-template-page-dualtech-php #cols .col figure { position: relative; width: 272px; height: 162px; overflow: hidden; padding: 4px; border: 1px solid #eee }
		.page-template-page-dualtech-php #cols .col figure span { display: block; width: 280px; text-indent: -9999em; height: 170px; background: url('../hammerite/img/globals/sprite-dualtech.png') left -380px no-repeat; position: absolute; top: 0; left: 0}
		.page-template-page-dualtech-php #cols .col div { background: url('../hammerite/img/bg/noise-lightblue.jpg'); padding: 10px 20px 0 20px; font-size: 1.1em }
		.page-template-page-dualtech-php #cols .col div p { padding: 0 0 15px 0 !important }
		.page-template-page-dualtech-php #closure { padding: 0 0 20px 0; font-size:1.1em }
		.page-template-page-dualtech-php #closure figure { float: left; margin-right: 30px; width: 290px; text-align: center; padding-bottom: 10px }
		.page-template-page-dualtech-php #closure p { padding: 18px 0 0 0; clear: both }
		.page-template-page-dualtech-php .enter-raffle__form__title { height: auto }
		.page-template-page-dualtech-php .enter-raffle__form .gform_wrapper form { padding: 0 !important }
		.page-template-page-dualtech-php .enter-raffle__form .main-question{ margin: 0;}
		.page-template-page-dualtech-php .enter-raffle__form .gfield { margin: 0 !important;}
		.page-template-page-dualtech-php .enter-raffle__form .gfield_radio{ margin: 0;}
		.page-template-page-dualtech-php .enter-raffle__form .gfield_radio li > label{ display: block;}
		.page-template-page-dualtech-php .enter-raffle__form .gfield_checkbox li > label {display: block;}
		.page-template-page-dualtech-php .enter-raffle__form .gfield:not(.gfield_radio) { display: block !important; }

		.knight {
			display: none;
		}

}

@media                  only screen and (max-width: 767px),
       only screen and (-webkit-min-device-pixel-ratio: 2),
		   	  only screen and (min--moz-device-pixel-ratio: 2),
		      only screen and (-o-min-device-pixel-ratio: 2/1),
               only screen and (min-device-pixel-ratio: 2),
                  only screen and (min-resolution: 192dpi),
                  only screen and (min-resolution: 2dppx) { 
  .home header .wrapper a.logo { background: url('../hammerite/img/globals/hammerite-home-mobile@2x.png') no-repeat; background-size: 100%; }
	header .wrapper a.logo { background: url('../hammerite/img/globals/hammerite-mobile@2x.png') no-repeat; background-size: 100%; }
}
