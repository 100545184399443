.radish-cookie-bar {
    background-color: rgba(0, 0, 0, 0.59) !important;
    height: 100vh;
    width: 100vw;
    font-family: 'Open sans', sans-serif;

    .wrap {
        background: #fff;
        color: #555;
        margin: 50px auto 0;
        width: auto;
        max-width: 400px;
        display: block;
        max-height: 100vh;
        height: auto;
        overflow-y: auto;
        padding: 24px 36px !important;
        box-sizing: border-box;
    }

    .rcc-before-title-logo {
        text-align: center;
        margin-bottom: 1.6rem;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .cookie-bar-header {
        text-align: center;
        margin-bottom: 24px;

        .cookie-bar-title {
            padding: 0;
            font-size: 20px;
            color: #005192;
        }
    }

    .cookie-bar-content {
        p {
            font-size: 18px;
        }
    }

    .cookie-bar-buttons {
        flex-wrap: wrap;

        li {
            margin: 0 !important;
            flex: 0 1 100%;
        }
    }

    .button {
        display: block;
        text-align: center;
        padding: 14px 20px !important;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;

        &:hover {
            opacity: 1 !important;
        }
    }

    .button-primary {
        background-color: #005192 !important;
        color: #fff;
        border: none !important;
        margin-bottom: 10px !important;

        &:hover {
            background-color: #004379 !important;
        }
    }

    .button-secondary {
        border: 1px solid #005192 !important;
        background-color: transparent !important;
        color: #005192;

        &:hover {
            background-color: #005192 !important;
            color: #fff;
        }
    }

    .cookie-bar-options {
        li {
            position: relative;
            display: block;
            padding-left: 46px;
            cursor: pointer;

            label {
                margin-left: 0 !important;
                margin-bottom: 4px;
                color: #002642;
                font-size: 18px;
                line-height: 25px;
                font-weight: 500;

                &:before {
                    display: block;
                    color: #000;
                    background-color: #fff;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-clip: padding-box;
                    border: 1px solid #000;
                    border-radius: 0;
                    height: 30px;
                    width: 30px;
                }
            }

            .cookie-description {
                p {
                    color: #000;
                    font-size: 14px;
                    display: block;
                }
            }
        }
    }

    .rcc-allow-cookie-type {
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        & ~ label:before {
            content: '';
            background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27%23ffffff%27%20viewBox%3D%270%200%2032%2032%27%3E%3Cpath%20class%3D%27cls-1%27%20d%3D%27M21.64%2C10%2C13.54%2C20l-3.11-3.82A.9.9%2C0%2C0%2C0%2C9%2C17.29L12.84%2C22a.9.9%2C0%2C0%2C0%2C1.4%2C0L23%2C11.17A.9.9%2C0%2C1%2C0%2C21.64%2C10Z%27%2F%3E%3C%2Fsvg%3E');
            position: absolute;
            left: 0;
            top: 0;
        }

        &:disabled ~ label:before {
            background-color: #ccc;
            border: 1px solid #ccc;
        }

        &:checked {
            &:not(:disabled) {
                &:not([readonly]) {
                    ~ label:before {
                        background-size: 28px;
                        background-color: #005192;
                        border-color: #005192;
                    }
                }
            }
        }
    }

    .cookie-bar-settings {
        .cookie-bar-buttons {
            li:first-of-type {
                order: 1;
            }

            li:last-of-type {
                order: 0;
            }
        }
    }
}

.radish-cookie-settings-toggle {
    display: none;
}