.error404 #contentwrapper {
  .wrapper {
    width: 260px;
    margin-bottom: 60px;
  }
  hgroup { margin-top: 30px; }
  #search {
    input {
      width: 240px;
      border: 1px solid #C2C2C2;
      padding: 5px;
      font-family: Arial;
      font-size: 1em;
      color: #373A3B;
    }
    .btn,
    .btnShow {
      @include border-radius(5px);
      margin-top: 15px;
      border: 1px solid #02395e;
      background: url('../hammerite/img/globals/sprite.png') 100% -230px no-repeat;
      height: 30px;
      padding: 0 26px 0 16px;
      line-height: 30px;
      font-size: 1em;
      color: #c1dbe3;
      text-decoration: none;
    }
    .btn:hover,
    .btnShow:hover {
      background: url('../hammerite/img/globals/sprite.png') 100% -260px no-repeat;
      color: #fff;
      box-shadow: 0 1px 2px #8c9ba2;
      cursor: pointer;
    }
  }
}