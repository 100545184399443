.wishmail-form {
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 20px;
    display: none;
    
    fieldset {
        display: block;
        overflow: hidden;
        height: 1%;
        padding-bottom: 10px;
    }
    label {
        display: block;
        clear: both;
        font-weight: 700;
        color: #f6821a;
    }
    input {
        @include border-radius(5px);
        padding: 4px 5px 5px;
        width: 200px;
        border: 1px solid #dad9d9;
        height: 21px;
        color: #999;
    }
    button {
        @include border-radius(5px);
        float: none;
        margin: 10px 0 0 0;
        border: 1px solid #02395e;
        background: url("../hammerite/img/globals/sprite.png") -490px -230px no-repeat;
        height: 30px;
        padding: 0 16px;
        font-size: 1em;
        color: #c1dbe3;
        clear: both;
        font-family: Arial,sans-serif;
    }
}