.result {
  border-bottom: 1px solid #ece8de;
  margin-bottom: 15px;
  dl {
    padding-bottom: 10px;
    dd {
      padding-top: 5px;
      a { color: #959595; }
    }
  }
}

.last-result { margin-bottom: 30px; }