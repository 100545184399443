header nav {
  clear: both;
  z-index: 2;
  ul {
    width: 800px;
    padding-left: 180px;
    height: 60px;
    background: url('../hammerite/img/globals/sprite.png') 0 -100px no-repeat;
    clear: both;
    li {
      float: left;
      height: 60px;
      padding-right: 1px;
      background: url('../hammerite/img/globals/sprite.png') 100% -162px no-repeat;
      position: relative;
      z-index: 99;
      a {
        color: #c1dbe3;
        float: left;
        display: block;
        padding: 22px 24px 0 24px;
        height: 38px;
        font-size: 1.1em;
        letter-spacing: 0.02em;
        text-decoration: none;
        text-transform: uppercase;
        text-shadow: 0 1px 1px #000;
        font-family: $font-web;
        font-weight: normal;
        &:hover,
        &:visited:hover {
          color: #fff;
          text-decoration: none;
          border-bottom: 3px solid #ece8de;
          height: 35px;
        }
        .pl_pl & {
          font-size: 1em;
          padding-left: 20px;
          padding-right: 20px;
        }
        .de_de & {
          padding-left: 21px;
          padding-right: 21px;
        }
        .lt-ie9 & {
          font-family: $font-family !important;
          font-weight: bold;
        }
      }
    }
    .first a {
      padding-right: 25px;
      background: url('../hammerite/img/globals/sprite.png') -979px -162px no-repeat;
    }
    .current-menu-item a {
      color: #fff;
      text-shadow: 0 1px 10px #fff;
    }
    .parent a {
      background: url('../hammerite/img/globals/sprite.png') 100% -26px no-repeat;
      padding-right: 36px;
    }
    li a {
      &:hover,
      &:visited:hover {
        background: #01203e;
      }
    }
    .parent a {
      &:hover,
      &:visited:hover {
        background: #01203e url('../hammerite/img/globals/sprite.png') 100% -26px no-repeat;
      }
    }
    .first a {
      &:hover,
      &:visited:hover {
        background: #01203e url('../hammerite/img/globals/sprite.png') -979px -162px no-repeat;
      }
    }
    .hovered {
      background-color: #01203e !important;
      border-bottom: 3px solid #01203e !important;
    }
  }
  .sub-menu {
    display: none;
    width: 260px;
    position: absolute;
    top: 60px;
    left: 0;
    background: #01203e;
    z-index: 999;
    margin: 0;
    padding: 0;
    height: auto;
    li {
      margin: 0;
      padding: 0;
      height: auto;
      background-image: none;
      a {
        font-family: $font-family;
        font-size: 1em;
        font-weight: normal;
        text-transform: none;
        color: #c1dbe3;
        padding: 14px;
        border-bottom: 1px solid #002d4e;
        width: 232px;
        text-shadow: none;
        background-image: none;
        line-height: 1em;
        height: auto;
        &:hover {
          font-family: $font-family;
          font-size: 1em;
          font-weight: normal;
          text-transform: none;
          color: #c1dbe3;
          padding: 14px;
          border-bottom: 1px solid #002d4e;
          width: 232px;
          text-shadow: none;
          background-image: none;
          line-height: 1em;
          height: auto;
          color: #fff;
          background: #002d4e !important;
          border-bottom: 1px solid #07375a !important;
        }
      }
    }
  }
}

#submenu {
  background: url('../hammerite/img/bg/noise-lightblue.jpg');
  padding: 0;
  border-radius: 4px;
  border: 0;
  li a {
    line-height: 1.4em;
    padding: 10px 30px 10px 10px;
    display: block;
    clear: both;
    color: #373a3b;
    text-decoration: none;
    border-bottom: 1px solid #dce0e1;
    border-top: 1px solid #fff;
    &:hover, &:visited:hover {
      background: white url('../hammerite/img/globals/sprite.png') 100% -1044px no-repeat;
      color: #1186c3;
      text-decoration: underline;
    }
  }
  .first a {
    border-top: 0;
    border-radius: 4px;
  }
  .last a { border-bottom: 0; }
  .current a {
    font-weight: bold;
    background: url('../hammerite/img/globals/sprite.png') 100% -964px no-repeat;
    text-decoration: none;
    color: #373a3b;
    &:hover,
    &:visited:hover {
      font-weight: bold;
      background: url('../hammerite/img/globals/sprite.png') 100% -964px no-repeat;
      text-decoration: none;
      color: #373a3b;
    }
  }
}