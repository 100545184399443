#colors {
  ul li {
    float: left;
    width: 64px;
    height: 63px;
    position: relative;
    margin-right: -15px;
    span {
      &.holder {
        display: block;
        padding: 0;
        width: 64px;
        height: 63px;
        background: url('../hammerite/img/globals/sprite.png') -397px -161px no-repeat;
        font-size: 0;
        text-indent: -9999em;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
      &.colorxl {
        display: block;
        padding: 0;
        width: 52px;
        height: 53px;
        position: absolute;
        left: 6px;
        top: 4px;
        z-index: 1;
        font-size: 0em;
        text-indent: -9999em;
      }
    }
  }
  em {
    font-weight: bold;
    font-style: normal;
    color: #003e67;
    display: block;
    padding: 10px 0 4px 0;
  }
  span {
    color: #999;
    display: block;
    padding: 6px 0 20px 0;
  }
  &.gloss ul li span.holder { background: url('../hammerite/img/globals/sprite.png') -331px -161px no-repeat; }
  &.forged ul li span.holder { background: url('../hammerite/img/globals/sprite.png') -463px -161px no-repeat; }
  &.hammered ul li span.holder { background: url('../hammerite/img/globals/sprite.png') -529px -161px no-repeat; }
}

.black { background-color: #000 !important; }
.blue { background-color: #0c81c0 !important; }
.copper { background-color: #d1a353 !important; }
.dark-green { background-color: #055a0f !important; }
.yellow { background-color: #fab301 !important; }
.white { background-color: #fffff5 !important; }
.brown { background-color: #51392f !important; }
.silver { background-color: #b9b5b4 !important; }
.green { background-color: #007741 !important; }
.red { background-color: #d1030f !important; }
.gold { background-color: #cfb07a !important; }
.grey,
.gray { background-color: #6f6764 !important; }

.top-one { z-index: 9 }
.top-two { z-index: 8 }
.top-three { z-index: 7 }
.top-four { z-index: 6 }
.top-five { z-index: 5 }
.top-six { z-index: 4 }
.top-seven { z-index: 3 }
.top-eight { z-index: 2 }
.top-nine { z-index: 1 }

.srfc {
  display: block;
  width: 66px;
  height: 66px;
  text-indent: -9999em;
  font-size: 0em;
  &.rust { background: url('../hammerite/img/globals/sprite.png') 0 -160px no-repeat; }
  &.alu { background: url('../hammerite/img/globals/sprite.png') -66px -160px no-repeat; }
  &.copper { background: transparent url('../hammerite/img/globals/sprite.png') -132px -160px no-repeat !important; }
  &.metal { background: url('../hammerite/img/globals/sprite.png') -198px -160px no-repeat; }
  &.brass { background: url('../hammerite/img/globals/sprite.png') -264px -160px no-repeat; }
}